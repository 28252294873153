/**
 * Auth API.
 *
 * Provides the auth API to the authsign service.
 */

import globals from './globals';
import UnexpectedServerResponse from './UnexpectedServerResponse';
import ROUTES from '../constants/routes';
import { AUTHENTICATION_ALREADY_IN_PROGRESS } from '../constants/actions';

async function start(
  method,
  language,
  ssn,
  correlationId,
  purchaseRequestToken = null, // Only needed for logs
  fetch = window.fetch
) {
  const query = new URLSearchParams({ method });
  if (language !== undefined) {
    query.append('language', language);
  }
  if (ssn !== undefined) {
    query.append('nationalId', ssn);
  }
  if (method !== 'sbid') {
    query.append('redirectUrl', window.location.origin + ROUTES.AUTHENTICATED);
  }
  const response = await fetch(`${globals.authStart}?${query}`, {
    method: 'POST',
    headers: {
      'X-Correlation-ID': correlationId,
      'Purchase-Request-Token': purchaseRequestToken
    }
  });
  switch (response.status) {
    case 200: {
      const { redirectUrl, token: pollingToken } = await response.json();
      if (!pollingToken) {
        throw new UnexpectedServerResponse(response, 'Received no token');
      }
      return { redirectUrl, pollingToken };
    }
    case 409:
      return { status: AUTHENTICATION_ALREADY_IN_PROGRESS };
    default:
      throw new UnexpectedServerResponse(response);
  }
}

async function poll(
  pollingToken,
  correlationId,
  purchaseRequestToken = null, // Only needed for logs
  fetch = window.fetch
) {
  const query = new URLSearchParams({ token: pollingToken });
  const response = await fetch(`${globals.authPoll}?${query}`, {
    headers: {
      'X-Correlation-ID': correlationId,
      'Purchase-Request-Token': purchaseRequestToken
    }
  });
  switch (response.status) {
    case 200: {
      const { authJwt: authToken } = await response.json();
      if (!authToken) {
        throw new UnexpectedServerResponse(response, 'Received no auth JWT');
      }
      return { status: 'DONE', authToken };
    }
    case 202:
      return { status: 'ONGOING' };
    case 400:
    case 404:
    case 410:
      return { status: 'EXPIRED' };
    default:
      throw new UnexpectedServerResponse(response);
  }
}

async function cancel(
  pollingToken,
  correlationId,
  purchaseRequestToken = null, // Only needed for logs
  fetch = window.fetch
) {
  const query = new URLSearchParams({ token: pollingToken });
  const response = await fetch(`${globals.authCancel}?${query}`, {
    method: 'POST',
    headers: {
      'X-Correlation-ID': correlationId,
      'Purchase-Request-Token': purchaseRequestToken
    }
  });
  switch (response.status) {
    case 204:
      return;
    default:
      throw new UnexpectedServerResponse(response);
  }
}

export default { start, poll, cancel };
