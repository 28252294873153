import React from 'react';
import { number } from 'prop-types';

const TeliaIconCancelOutline = (props) => {
  const { width } = props;
  return (
    <div style={{ width: `${width}px` }}>
      <svg viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M24.0607 24.6492L23 23.5885L21.9394 24.6492L15.6278 30.9608C15.546 31.0426 15.4415 31.083 15.3334 31.083C15.2252 31.083 15.1208 31.0426 15.039 30.9608C14.8753 30.7971 14.8753 30.5355 15.039 30.3719L21.3505 24.0603L22.4112 22.9997L21.3505 21.939L15.039 15.6274C14.8753 15.4638 14.8753 15.2022 15.039 15.0386C15.2026 14.875 15.4642 14.875 15.6278 15.0386L21.9394 21.3502L23 22.4108L24.0607 21.3502L30.3723 15.0386C30.5359 14.875 30.7975 14.875 30.9611 15.0386C31.1248 15.2022 31.1248 15.4638 30.9611 15.6274L24.6495 21.939L23.5889 22.9997L24.6495 24.0603L30.9611 30.3719C31.1248 30.5355 31.1248 30.7971 30.9611 30.9608C30.8793 31.0426 30.7749 31.083 30.6667 31.083C30.5586 31.083 30.4541 31.0426 30.3723 30.9608L24.0607 24.6492ZM23 2.33301C11.5724 2.33301 2.33337 11.5721 2.33337 22.9997C2.33337 34.4273 11.5724 43.6663 23 43.6663C34.4276 43.6663 43.6667 34.4273 43.6667 22.9997C43.6667 11.5721 34.4276 2.33301 23 2.33301Z"
          stroke="currentColor"
          strokeWidth="3"
        />
      </svg>
    </div>
  );
};

TeliaIconCancelOutline.defaultProps = {
  width: 40
};

TeliaIconCancelOutline.propTypes = {
  width: number
};

export default TeliaIconCancelOutline;
