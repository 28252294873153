import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ExpressiveButton } from '../styled-components/styledcomponents';
import { clickedPayButtonEvent } from '../actions/eventActions';
import { PAYMENT_METHOD_PART_PAYMENT } from '../constants';
import { authorize } from '../actions/applicationActions';

const PurchaseButton = () => {
  const [payButtonEnabled, setPayButtonEnabled] = useState(true);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    customer,
    processPurchaseRequestLoading,
    selectedPaymentMethodOption: selectedOption
  } = useSelector((state) => state.app);

  const clickedPay = useCallback(() => {
    setPayButtonEnabled(false);
    dispatch(clickedPayButtonEvent(PAYMENT_METHOD_PART_PAYMENT));
    dispatch(authorize());
    if (!processPurchaseRequestLoading) {
      setPayButtonEnabled(true);
    }
  }, [selectedOption, customer, processPurchaseRequestLoading]);

  return (
    <ExpressiveButton
      disabled={!payButtonEnabled}
      onClick={clickedPay}
      data-cy="PayButton"
    >
      {t('pay_in_parts.pay_button_label')}
    </ExpressiveButton>
  );
};

export default PurchaseButton;
