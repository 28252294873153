import { Trans } from 'react-i18next';
import * as propTypes from 'prop-types';
import React from 'react';
import {
  BodyTextBlack,
  StrongBold
} from '../styled-components/styledcomponents';
import riskAssessmentStatusType from '../constants/riskAssessmentStatusType';

const PayTheDifferenceTextInformation = ({
  type,
  formattedFinanceableAmount
}) => {
  return (
    <BodyTextBlack>
      {type ===
      riskAssessmentStatusType.PAY_THE_DIFFERENCE_UPDATE_ACCOUNT_NEEDED ? (
        <Trans
          i18nKey="pay_in_parts.pay_the_difference_information_increase_limit"
          components={{
            bold: <StrongBold />,
            formattedFinanceableAmount
          }}
        >
          Your existing credit limit is increased so that you can pay
          <bold> {formattedFinanceableAmount} </bold>
          of the amount in your shopping cart in parts and the remaining amount
          in-store with a card.
        </Trans>
      ) : (
        <Trans
          i18nKey="pay_in_parts.pay_the_difference_information"
          components={{
            bold: <StrongBold />,
            formattedFinanceableAmount
          }}
        >
          You can pay <bold>{formattedFinanceableAmount}</bold> of the amount in
          your shopping cart in parts and the remaining amount in-store with a
          card.
        </Trans>
      )}
    </BodyTextBlack>
  );
};

PayTheDifferenceTextInformation.propTypes = {
  type: propTypes.string.isRequired,
  formattedFinanceableAmount: propTypes.string.isRequired
};

export default PayTheDifferenceTextInformation;
