import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';
import { borders, colors, spacing } from '@teliads/components/foundations';
import { setShowMoreInformation } from '../actions/partPaymentActions';
import { FinePrint, Title } from '../styled-components/styledcomponents';
import { moreInformationEvent } from '../actions/eventActions';
import TeliaIconCancelOutline from './graphics/TeliaIconCancelOutline';
import PoweredByTeliaFinance from './PoweredByTeliaFinance';
import useMerchantSpecificTranslationKey from '../hooks/useMerchantSpecificTranslationKey';

const MoreInformation = () => {
  const dispatch = useDispatch();
  const { showMoreInformation } = useSelector((state) => state.partPayment);
  const close = useCallback(() => {
    dispatch(setShowMoreInformation(false));
    dispatch(moreInformationEvent(false));
  }, [showMoreInformation]);

  const { merchantTranslationKey, merchantTranslation } =
    useMerchantSpecificTranslationKey();
  const { t } = useTranslation();

  return (
    <FlexCenterColumn>
      <Title data-cy="MoreInformationTitle">
        {merchantTranslation('more_information.title')}
      </Title>
      <UnorderedList>
        <ListItem>
          {merchantTranslation('more_information.paragraph_1.row_1')}
        </ListItem>
        <ListItem>
          {merchantTranslation('more_information.paragraph_1.row_2')}
        </ListItem>
        <ListItem>
          {merchantTranslation('more_information.paragraph_1.row_3')}
        </ListItem>
        <ListItem>
          {merchantTranslation('more_information.paragraph_1.row_4')}
        </ListItem>
        <ListItem>
          {merchantTranslation('more_information.paragraph_1.row_5')}
        </ListItem>
        <ListItem>
          {merchantTranslation('more_information.paragraph_1.row_6')}
        </ListItem>
        {merchantTranslation('more_information.paragraph_1.row_7' !== ' ') && (
          <ListItem>
            {merchantTranslation('more_information.paragraph_1.row_7')}
          </ListItem>
        )}
        {merchantTranslation('more_information.paragraph_1.row_8') !== '' &&
          merchantTranslation('more_information.paragraph_1.row_8') !== ' ' && (
            <ListItem>
              <Trans
                i18nKey={merchantTranslationKey(
                  'more_information.paragraph_1.row_8'
                )}
              >
                {'Frågor om Mobilt BankID. Klicka här: '}
                <Link
                  href={merchantTranslation(
                    'more_information.paragraph_1.row_8_link_1'
                  )}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Mobilt BankID.
                </Link>
              </Trans>
            </ListItem>
          )}
        {merchantTranslation('more_information.paragraph_1.row_9' !== ' ') && (
          <ListItem>
            {merchantTranslation('more_information.paragraph_1.row_9')}
          </ListItem>
        )}
        {merchantTranslation('more_information.paragraph_1.row_10') !== ' ' && (
          <ListItem>
            {merchantTranslation('more_information.paragraph_1.row_10')}
          </ListItem>
        )}
      </UnorderedList>

      {merchantTranslation('more_information.paragraph_2.charge_title') && (
        <MoreInformationText>
          <MoreInformationBoxTitle>
            {merchantTranslation('more_information.paragraph_2.charge_title')}
          </MoreInformationBoxTitle>
          {merchantTranslation(
            'more_information.paragraph_2.charge_description_1' !== ' '
          ) && (
            <ChargesRow>
              <div>
                {merchantTranslation(
                  'more_information.paragraph_2.charge_description_1'
                )}
              </div>
              <div>
                {merchantTranslation(
                  'more_information.paragraph_2.charge_cost_1'
                )}
              </div>
            </ChargesRow>
          )}
          <ChargesRow>
            <div>
              {merchantTranslation(
                'more_information.paragraph_2.charge_description_2'
              )}
            </div>
            <div>
              {merchantTranslation(
                'more_information.paragraph_2.charge_cost_2'
              )}
            </div>
          </ChargesRow>
          <ChargesRow>
            <div>
              {merchantTranslation(
                'more_information.paragraph_2.charge_description_3'
              )}
            </div>
            <div>
              {merchantTranslation(
                'more_information.paragraph_2.charge_cost_3'
              )}
            </div>
          </ChargesRow>
          <ChargesRow>
            <div>
              {merchantTranslation(
                'more_information.paragraph_2.charge_description_4'
              )}
            </div>
            <div>
              {merchantTranslation(
                'more_information.paragraph_2.charge_cost_4'
              )}
            </div>
          </ChargesRow>
          <ChargesRow>
            <div>
              {merchantTranslation(
                'more_information.paragraph_2.charge_description_5'
              )}
            </div>
            <div>
              {merchantTranslation(
                'more_information.paragraph_2.charge_cost_5'
              )}
            </div>
          </ChargesRow>
          {merchantTranslation(
            'more_information.paragraph_2.charge_description_6'
          ) !== ' ' && (
            <ChargesRow>
              <div>
                {merchantTranslation(
                  'more_information.paragraph_2.charge_description_6'
                )}
              </div>
              <div>
                {merchantTranslation(
                  'more_information.paragraph_2.charge_cost_6'
                )}
              </div>
            </ChargesRow>
          )}
          {t('more_information.paragraph_2.fine_text', {
            defaultValue: ' '
          }) !== ' ' && (
            <FinePrintBox>
              {t('more_information.paragraph_2.fine_text', {
                defaultValue:
                  '*Telia Finance receives DKK 15 except the cost of Betalingsservice, which can be seen at Betalingsservice website.'
              })}
            </FinePrintBox>
          )}
        </MoreInformationText>
      )}

      <RoundButtonContainer>
        <RoundButton type="button" onClick={close}>
          <TeliaIconCancelOutline />
        </RoundButton>
      </RoundButtonContainer>

      <PoweredByTeliaFinance />
    </FlexCenterColumn>
  );
};

export default MoreInformation;

export const FinePrintBox = styled(FinePrint).attrs({
  className: 'FinePrintBox'
})`
  margin-top: ${spacing.spacing16};
`;

const MoreInformationText = styled.div.attrs({
  className: 'MoreInformationText'
})`
  margin: ${spacing.spacing32} 0 0 0;
  padding: ${spacing.spacing16};
  font-size: ${(p) => p.theme.fontSize.small};
  color: ${colors.gray600};
  line-height: 1.5;
  white-space: pre-wrap;
  background: ${({ theme }) => theme.color.secondaryLayerBackground};
  border-radius: ${borders.radiusSm};
`;

const MoreInformationBoxTitle = styled.strong.attrs({
  className: 'MoreInformationTitle'
})`
  color: ${(p) => p.theme.color.core.black};
`;

const UnorderedList = styled.ul`
  font-size: ${(p) => p.theme.fontSize.small};
  line-height: 1.5;
  margin-block-start: 0;
  margin-block-end: 0;

  /* Fix for IE, edge and old safari- and firefox verions to not indent list */
  list-style-position: outside;
  padding: 0 ${spacing.spacing32};
`;

const ListItem = styled.li`
  ${(p) => p.children === '' && 'display: none'};
  margin-bottom: ${spacing.spacing4};
  color: ${colors.gray600};
`;

const Link = styled.a`
  color: ${({ theme }) => theme.color.primary};
  &:hover {
    color: ${({ theme }) => theme.color.primaryHover};
  }
`;

const FlexCenterColumn = styled.div.attrs({
  className: 'FlexCenterColumn'
})`
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const RoundButtonContainer = styled.div.attrs({
  className: 'RoundButtonContainer'
})`
  display: flex;
  justify-content: center;
  margin: ${spacing.spacing32} 0 0 0;
  padding: 0;
  flex: 1;
  height: 40px;
`;

const RoundButton = styled.button.attrs({
  className: 'RoundButtonContainer'
})`
  cursor: pointer;
  border: none;
  background-color: transparent;
  color: ${({ theme }) => theme.color.primary};
  &:hover {
    color: ${({ theme }) => theme.color.primaryHover};
  }
`;

const ChargesRow = styled.div.attrs({
  className: 'ChargesRow'
})`
  display: flex;
  justify-content: space-between;
`;
