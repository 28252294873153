import React from 'react';
import styled from 'styled-components';

const TeliaIconPaymentSuccess = () => {
  return (
    <IconWidthHeight>
      <svg fill="currentColor" viewBox="0 0 64 64" role="img">
        <title>payment-success icon</title>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M55.9997 30.8361V16C55.9947 11.5838 52.4159 8.00505 47.9997 8H12C7.5838 8.00505 4.00505 11.5838 4 16V35.9998C4.00505 40.416 7.5838 43.9947 12 43.9998H30.3022C31.5584 50.2247 36.5992 54.9835 42.886 55.8798C49.1728 56.7761 55.3427 53.6156 58.2885 47.9898C61.2344 42.364 60.3175 35.4927 55.9997 30.8361ZM12 12H47.9997C50.208 12.0022 51.9975 13.7917 51.9997 16V17.9999H7.99998V16C8.00218 13.7917 9.79174 12.0022 12 12ZM7.99998 35.9998V21.9999H51.9997V27.7433C47.5123 25.3645 42.1248 25.4233 37.6903 27.8995C33.2559 30.3757 30.3795 34.9315 30.0505 39.9998H12C9.79174 39.9976 8.00218 38.2081 7.99998 35.9998ZM44.9998 51.9997C38.9247 51.9997 33.9998 47.0749 33.9998 40.9998C33.9998 34.9247 38.9247 29.9999 44.9998 29.9999C51.0749 29.9999 55.9997 34.9247 55.9997 40.9998C55.9928 47.0721 51.072 51.9929 44.9998 51.9997ZM51.6637 38.1091L45.6637 47.109C45.3316 47.6078 44.7932 47.9311 44.1969 47.9899C44.1314 47.9965 44.0655 47.9998 43.9996 47.9996C43.4692 47.9995 42.9606 47.7887 42.5856 47.4137L38.5856 43.4137C37.8046 42.6328 37.8046 41.3666 38.5856 40.5856C39.3666 39.8047 40.6327 39.8047 41.4137 40.5856L43.6891 42.86L48.3355 35.8903C48.7297 35.2905 49.415 34.9478 50.1313 34.9924C50.8476 35.037 51.4852 35.462 51.8018 36.1061C52.1185 36.7502 52.0658 37.5146 51.6637 38.1091Z"
        />
      </svg>
    </IconWidthHeight>
  );
};

const IconWidthHeight = styled.div`
  width: 32px;
  height: 26px;
`;

export default TeliaIconPaymentSuccess;
