import React from 'react';
import * as PropTypes from 'prop-types';

const TeliaIconError = ({ width }) => (
  <div style={{ width: `${width}px` }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 64 64"
      role="img"
    >
      <title>error icon</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.8585 11.5469L58.2618 43.8838C59.7446 46.3552 59.7834 49.4331 58.3635 51.9411C56.9436 54.4492 54.2845 55.9997 51.4024 56H12.5958C9.71372 55.9997 7.05459 54.4492 5.6347 51.9411C4.21482 49.4331 4.25367 46.3552 5.73642 43.8838L25.1397 11.5459C26.585 9.13572 29.1891 7.66093 31.9994 7.66113C34.8097 7.66134 37.4136 9.13651 38.8585 11.5469ZM51.4024 52C52.846 52.0094 54.18 51.2316 54.8829 49.9707C55.6026 48.7193 55.5831 47.1752 54.8321 45.9424L35.4288 13.6045C34.7066 12.3992 33.4046 11.6614 31.9994 11.6612C30.5942 11.6609 29.292 12.3984 28.5694 13.6035L9.16612 45.9424C8.42483 47.178 8.40546 48.7169 9.1154 49.9708C9.82534 51.2247 11.1548 51.9999 12.5958 52H51.4024ZM29.9991 36.9772V25.1569C29.9983 24.6198 30.1056 24.0879 30.3148 23.5932C30.486 23.1496 30.753 22.7494 31.0968 22.4209C31.339 22.1704 31.6666 22.0202 32.0145 22C32.5161 22 33.0173 22.3805 33.5182 23.1416C33.7901 23.4771 33.9573 23.8852 33.9991 24.3151V36.1354C33.9991 36.5759 33.6932 36.9969 33.0814 37.3982C32.4994 37.7897 31.814 37.9988 31.1126 37.999C30.3712 37.999 30 37.6584 29.9991 36.9772ZM34.2408 43.9236C34.173 43.4297 33.9757 42.9626 33.669 42.5696C33.1076 41.7469 32.566 41.3355 32.0444 41.3355C31.6396 41.354 31.2573 41.5266 30.9757 41.8179C30.593 42.1852 30.2954 42.6319 30.1039 43.1266C29.874 43.6743 29.7562 44.2625 29.7574 44.8565C29.7574 45.6189 30.1786 46 31.0208 46C31.8022 46.0001 32.5662 45.7697 33.2173 45.3377C33.8996 44.8957 34.2408 44.4243 34.2408 43.9236Z"
      />
    </svg>
  </div>
);

TeliaIconError.propTypes = {
  width: PropTypes.number.isRequired
};

export default TeliaIconError;
