import { snakeCase } from 'lodash';
import { useTranslation } from 'react-i18next';

/**
 * Convert merchantId to merchantName, if no Phrase key translations is
 * available in Phrase language file it will instead print the Phrase key
 * as default.
 *
 * @param merchantId
 * @returns merchantName
 */
const useMerchantNameFromId = (merchantId) => {
  const { t } = useTranslation();
  return t(`merchant_name.${snakeCase(merchantId)}`, {
    defaultValue: `merchant_name.${snakeCase(merchantId)}`
  });
};

export default useMerchantNameFromId;
