module.exports = {
  "authenticating.cancel": "Avbryt",
  "authenticating.waiting_message": "Start BankID på din enhet",
  "authentication.error_bank_id_in_use": "BankID er startet for mer enn en side. Prøv igjen.",
  "authentication.general_error": "Ops, noe gikk galt. Prøv igjen. ",
  "authentication.identify_button_label": "Identifisere med BankID",
  "cancel_view.guidance_header": "Du har avbrutt bestillingen din",
  "cancel_view.guidance_text": "Vennligst kontakt kundeservice hvis du har spørsmål.",
  "circuit_breaker.body": "Siden kan ikke lastes!\nVennligst prøv igjen etter nedtellingen nedenfor:",
  "circuit_breaker.button_try_again": "Prøv igjen",
  "circuit_breaker.minutes_postfix": "min",
  "circuit_breaker.title": "Det har oppstått en feil",
  "credit_information.accept_credit_report_to_continue": "Vi trenger din tillatelse til å innhente kredittopplysninger for å kunne fortsette.",
  "credit_information.inform_about_credit_report": "Jeg er klar over at en ny kredittvurdering kan bli gjort.",
  "credit_information.sign_purchase_text": "Fullfør kjøpet",
  "credit_limit_information.could_not_update_credit_limit_title": "Vi kan dessverre ikke tilby DelBetaling.",
  "credit_limit_information.credit_limit_to_low": "Du kan ikke DelBetale dette kjøpet fordi din tilgjengelige kreditt på {{amount}} er for lav.",
  "credit_limit_information.declined_credit_redirect_button": "Avbryt",
  "credit_limit_information.increase_limit_info": "Godkjent! Du kan benytte DelBetale!",
  "credit_limit_information.kyc_block": "Du har ikke fyllt ut kundeinformasjonsskjemaet. For at vi skal kunne tilby deg av DelBetaling, må du svare på skjemaet vårt via teliafinance.com/no.",
  "credit_limit_information.maximum_amount_exceeded": "Du kan ikke benytte DelBetaling til dette kjøpet fordi beløpet overstiger den maksimale kredittgrensen på {{amount}} som Telia Finance tilbyr. ",
  "credit_limit_information.ready_to_pay_title": "Godkjent! Du kan benytte DelBetaling!",
  "credit_limit_information.title": "Din kredittvurdering",
  "credit_limit_information.unpaid_invoices": "Du har ubetalte fakturaer og kan derfor ikke benytte DelBetaling til kjøpet ditt.",
  "email_phone_collection.body": "Før du går videre, ønsker vi at du fyller ut dine kontaktopplysninger slik at vi kan kontakte deg med viktig informasjon om din delbetaling.",
  "email_phone_collection.continue": "Fortsett",
  "email_phone_collection.email_validation_failed": "Fyll inn med en gyldig e-postadresse.",
  "email_phone_collection.field_email_header": "E-post",
  "email_phone_collection.field_phone_header": "Mobilnummer",
  "email_phone_collection.header": "Kontaktopplysninger",
  "email_phone_collection.phone_validation_failed": "Feil mobilnummer.",
  "high_load.body": "Vi opplever for tiden stor trafikk, og ber deg vente mens vi laster inn siden. Hvis du oppdaterer siden, vil det føre til ytterligere forsinkelser.",
  "high_load.title_loading": "Laster…",
  "high_load.title_thank_you": "Takk for at du venter!",
  "month": "måned",
  "month_picker.number_of_months": "{{count}} måneder",
  "more_information.close_button_label": "Lukk",
  "more_information.paragraph_1.row_1": "Ingen rente.",
  "more_information.paragraph_1.row_10": "Full kontroll over kredittkjøpene dine på Mine sider.",
  "more_information.paragraph_1.row_2": "Ingen fakturagebyr.",
  "more_information.paragraph_1.row_3": "Du kan når som helst betale ned på det resterende beløpet.",
  "more_information.paragraph_1.row_4": "Betal alt på første faktura, eller benytt DelBetaling inntil 36 måneder.",
  "more_information.paragraph_1.row_5": "Søk om kreditt i dag og bruk den for kjøp på onecall.no.",
  "more_information.paragraph_1.row_6": "Du får en faktura for produktene du delbetaler fra Telia Finance. Faktura for mobilabonnement sendes separat fra OneCall.",
  "more_information.paragraph_1.row_7": " ",
  "more_information.paragraph_1.row_8": " ",
  "more_information.paragraph_1.row_8_link_1": " ",
  "more_information.paragraph_1.row_9": " ",
  "more_information.paragraph_2.charge_cost_1": "0 kr",
  "more_information.paragraph_2.charge_cost_2": "0 %",
  "more_information.paragraph_2.charge_cost_3": "0 kr",
  "more_information.paragraph_2.charge_cost_4": "0 kr",
  "more_information.paragraph_2.charge_cost_5": "0 kr",
  "more_information.paragraph_2.charge_cost_6": "0 kr",
  "more_information.paragraph_2.charge_description_1": " ",
  "more_information.paragraph_2.charge_description_2": "Nominell rente",
  "more_information.paragraph_2.charge_description_3": "Oppstartsgebyr",
  "more_information.paragraph_2.charge_description_4": "Gebyr for eFaktura",
  "more_information.paragraph_2.charge_description_5": "Gebyr for papirfaktura",
  "more_information.paragraph_2.charge_description_6": " ",
  "more_information.paragraph_2.charge_title": "Gebyrer",
  "more_information.title": "DelBetale med Telia Finance",
  "onboarding.terms_and_conditions_secci_checkbox_text_1": "https://www.teliafinance.com/globalassets/telia-finance/documents/kreditavgifter/documents/notfcc-no--2021-9.pdf",
  "onboarding.terms_and_conditions_secci_checkbox_text_3": "https://www.teliafinance.com/globalassets/telia-finance/documents/kreditavgifter/documents/secci-no-2021-9.pdf",
  "part_payment_not_permitted_view.not_enough_amount_for_part_payment_text": "DelBetaling kan ikke brukes på summer under {{amount}} {{currency}}",
  "part_payment_not_permitted_view.title": "Du kan dessverre ikke benytte DelBetaling til dette beløpet.",
  "pay_in_parts_summary.credit_interest": "Lånerente",
  "pay_in_parts_summary.effective_interest": "Effektiv rente",
  "pay_in_parts_summary.increase_limit_info": "Kredittgrensen din økes fra <bold>{{previousCredit}}</bold> <bold>{{currency}}</bold> til <bold>{{newCredit}}</bold> <bold>{{currency}}</bold> for å dekke kjøpet ditt med DelBetaling. ",
  "pay_in_parts_summary.invoice_fee": "Gebyr for faktura",
  "pay_in_parts_summary.invoice_fee_fine_print": " ",
  "pay_in_parts_summary.number_of_months": "måneder",
  "pay_in_parts_summary.pay_the_difference_credit_amount": "DelBetaling",
  "pay_in_parts_summary.pay_the_difference_direct_amount": "Direktebetaling",
  "pay_in_parts_summary.pay_the_difference_total_amount": "Beløp å betale",
  "pay_in_parts_summary.plan": "Betalingsplan",
  "pay_in_parts_summary.registration_fee": "Oppstartsgebyr",
  "pay_in_parts_summary.title": "Sammendrag",
  "pay_in_parts_summary.total_amount": "Totalbeløp å DelBetale",
  "pay_in_parts.cancel_button_label": "Avbryt",
  "pay_in_parts.continue_button_label": "Fortsett",
  "pay_in_parts.interest_text_part_1": "Totalkostnad for DelBetaling (effektiv rente) med et illustrert eksempel fra 02.01.2024:",
  "pay_in_parts.interest_text_part_2": "Brukt kontokreditt på <bold>15 000 kr</bold> til 0 % rente (fast) med en nedbetalingstid på 36 måneder, med 36 avdrag på <bold>416,67 kr/mnd</bold>, gir en effektiv rente på 0 %. Totalt å tilbakebetale for kontokreditt på <bold>15 000 kr</bold> er dermed <bold>15 000 kr</bold>. DelBetaling med Telia Finance er derfor alltid helt fri for renter og gebyrer.",
  "pay_in_parts.interest_title": "Hvor mye koster DelBetaling med Telia Finance?",
  "pay_in_parts.more_information": "Mer informasjon",
  "pay_in_parts.more_information_details": "*Ingen gebyrer og 0% effektiv rente.",
  "pay_in_parts.pay_button_label": "Fullfør kjøpet",
  "pay_in_parts.pay_strong_sign_button_label": "Til signering",
  "pay_in_parts.pay_the_difference_information": "Du kan DelBetale <bold>{{formattedFinanceableAmount}}</bold> av beløpet i handlekurven, og det resterende beløpet i butikken med kort. ",
  "pay_in_parts.pay_the_difference_information_increase_limit": "Du har nådd den maksimale kredittgrensen, du kan DelBetale <bold>{{formattedFinanceableAmount}}</bold> av beløpet i handlekurven, og det resterende beløpet i butikken med kort.",
  "pay_in_parts.terms_and_conditions_credit_check_info": "Ved å fortsette bekrefter du at du godtar at en kredittvurdering vil bli gjennomført om nødvendig for kjøpet, og at du har lest <0>SEF - Standardiserte europeiske opplysninger om forbrukerkreditt</0>.",
  "pay_in_parts.terms_and_conditions_credit_check_info_link_0": "https://files.teliafinance.com/sef-no.pdf",
  "pay_in_parts.terms_and_conditions_secci_link_2": "https://www.teliafinance.com/globalassets/telia-finance/documents/kreditavgifter/documents/secci-no-2021-9.pdf",
  "pay_in_parts.terms_and_conditions_secci_text": "I neste steg godtar du Telia Finances Kontokredittavtale, inkludert <link0>Generelle vilkår</link0>, <link1>SEF</link1> og <link2>Personvernregler</link2>.",
  "pay_in_parts.terms_and_conditions_secci_text_link_0": "https://files.teliafinance.com/allmenne-vilkar-no.pdf",
  "pay_in_parts.terms_and_conditions_secci_text_link_1": "https://files.teliafinance.com/sef-no.pdf",
  "pay_in_parts.terms_and_conditions_secci_text_link_2": "https://www.teliafinance.com/no/privat/om-oss/privacy",
  "pay_with_card.error_message_label": "Feil:",
  "pay_with_card.pay_button_label": " ",
  "pay_with_card.supported_cards": " ",
  "payment_method.pay_in_parts": "DelBetale – 0 % rente",
  "payment_method.pay_with_card": "Kort",
  "payment_method.pay_with_card.not_supported_browser": "Nettleseren støttes ikke. Vennligst oppdater til den siste versjon eller prøv en annen nettleser.",
  "processing_purchase.error_please_contact_staff": "Ops, noe gikk galt! Vi kunne ikke fullføre kjøpet ditt på grunn tekniske årsaker. Vi håper du vil prøve igjen senere. Du må gjerne kontakte kundeservice.",
  "processing_purchase.error_please_contact_support": "Ops, noe gikk galt! Forsøk igjen senere eller kontakt kundeservice.",
  "processing_purchase.error_please_try_again": "Ops, noe gikk galt. Forsøk igjen litt senere. ",
  "processing_purchase.error_support_telephone_number": "04300",
  "processing_purchase.text": "Fullfører kjøpet",
  "processing_purchase.try_again_button_label": "Prøv igjen",
  "signing.cancel": "Avbryt",
  "signing.general_error": "En feil oppstod ved signering. Vennligst prøv igjen.",
  "signing.waiting_message": "Venter på signatur",
  "try_another_payment_method": "Vi kan dessverre ikke tilby deg DelBetaling. Vennligst prøv en annen betalingsmetode.",
  "your_details.cancel": "Avbryt",
  "your_details.continue": "Fortsett",
  "your_details.save": "Lagre",
  "your_details.saving": "Lagrer",
  "your_details.ssn_helper_text": "Identifisere deg med BankID så vi kan finne ut om du har en konto hos Telia Finance.",
  "your_details.ssn_label": "Personnummer",
  "your_details.ssn_placeholder": "DDMMYYYYXXXX",
  "your_details.title": "Dine opplysninger"
};