export default {
  authStart: '/auth/start',
  authPoll: '/auth/poll',
  authCancel: '/auth/cancel',

  authSbidStart: '/auth/sbid/start',
  authSbidPoll: '/auth/sbid/poll',
  authSbidCancel: '/auth/sbid/cancel',

  signSbidStart: '/payment/sign/sbid/start',
  signSbidPoll: '/sign/sbid/poll',
  signSbidCancel: '/sign/sbid/cancel',

  signStart: '/payment/sign/start',
  signPoll: '/sign/poll',
  signCancel: '/sign/cancel',
  signVerify: '/sign/verify',

  kalpCheck: '/payment/credit-questions/check',
  kalpQuestions: '/payment/credit-questions',
  kalpCreate: '/payment/credit-questions/create',
  kyc: '/payment/kyc',
  kycQuestions: '/payment/kyc/questions',

  config: '/payment/config',
  prepare: '/payment/prepare',
  accountLookup: '/payment/account-lookup',
  riskAssessment: '/payment/risk-assessment',
  authorize: '/payment/authorize',
  abort: '/payment/abort',
  contact: '/payment/customer/contactinfo'
};
