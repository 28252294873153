import globals from '../globals';
import UnexpectedServerResponse from '../UnexpectedServerResponse';
import UnexpectedStatusCode from '../UnexpectedStatusCode';

const accountLookup = async (
  purchaseRequestToken,
  authToken,
  selectedMonths,
  correlationId,
  fetch = window.fetch
) => {
  const response = await fetch(globals.accountLookup, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Purchase-Request-Token': purchaseRequestToken,
      'X-Correlation-ID': correlationId
    },
    body: JSON.stringify(
      authToken
        ? {
            customer: { authJwt: authToken },
            order: {
              selectedMonths
            }
          }
        : {
            order: {
              selectedMonths
            }
          }
    )
  });

  try {
    switch (response.status) {
      case 200: {
        const {
          creditAccountStatus: {
            customer,
            customerType,
            partPaymentMinimumAmount,
            rejectedMessage,
            status
          }
        } = await response.json();
        return {
          customer,
          customerType,
          partPaymentMinimumAmount,
          rejectedMessage,
          status
        };
      }
      case 400:
      case 401:
      case 404:
      case 435:
        return { status: 'ERR', value: response };
      default:
        throw new UnexpectedStatusCode(response.status);
    }
  } catch (e) {
    throw new UnexpectedServerResponse(response, e);
  }
};

export default accountLookup;
