import { SET_SHOW_MORE_INFORMATION_PART_PAYMENT } from '../constants/actions';

export function setShowMoreInformation(value) {
  return {
    type: SET_SHOW_MORE_INFORMATION_PART_PAYMENT,
    value
  };
}

export { setShowMoreInformation as default };
