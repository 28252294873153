import React from 'react';
import { useTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';
import { SecciAndTermsConditionLink } from '../styled-components/styledcomponents';

const MerchantLink = ({ merchantLink, children }) => {
  const { t } = useTranslation();

  return !t(merchantLink) ? (
    <span>{children}</span>
  ) : (
    <SecciAndTermsConditionLink
      href={t(merchantLink)}
      target="_blank"
      rel="noopener noreferrer"
      data-cy="MerchantLink"
    >
      {children}
    </SecciAndTermsConditionLink>
  );
};

MerchantLink.propTypes = {
  merchantLink: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default MerchantLink;
