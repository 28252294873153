export function addCustomCssToDocument(url) {
  return new Promise((resolve) => {
    if (!url) {
      return resolve();
    }
    const customCssUrl = document.createElement('link');
    customCssUrl.setAttribute('rel', 'stylesheet');
    customCssUrl.setAttribute('type', 'text/css');
    customCssUrl.setAttribute('href', url);
    customCssUrl.onload = () => {
      resolve();
    };
    customCssUrl.id = 'external-css-link';
    document.querySelector('head').appendChild(customCssUrl);
  });
}
