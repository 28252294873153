import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'whatwg-fetch';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import './i18n/i18n';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import configureStore from './reducers/configureStore';
import App from './components/App';
import '@teliads/components/dist/telia-component-library/telia-component-library.css';
import gtmInit from './features/analytics/analytics';
/**
 * Get the env that the code is running on from url
 * Maybe use env variables for this instead in future
 *
 * returns env
 * */

const getEnv = () => {
  if (window.location.href.indexOf('dev') !== -1) {
    return 'dev';
  }
  if (window.location.href.indexOf('qa') !== -1) {
    return 'qa';
  }
  if (window.location.href.indexOf('stage') !== -1) {
    return 'stage';
  }
  if (window.location.href.indexOf('prod') !== -1) {
    return 'prod';
  }
  return 'local';
};

window.onmessage = (e) => {
  if (e.data?.allowStatisticalCookies === true) {
    datadogRum.init({
      applicationId: '2b88c1dd-b47c-4ff1-9b02-1cb9af1907f0',
      clientToken: 'pubc955aef79f1347dbe009014cc09902b6',
      site: 'datadoghq.eu',
      service: 'tf-webcheckout-sdk',
      env: getEnv(),
      version: 'v1.0.1',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 20,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
      trackSessionAcrossSubdomains: false,
      useSecureSessionCookie: true,
      useCrossSiteSessionCookie: true
    });
    datadogRum.startSessionReplayRecording();

    datadogLogs.init({
      clientToken: 'pubc955aef79f1347dbe009014cc09902b6',
      service: 'tf-webcheckout-sdk',
      env: getEnv(),
      site: 'datadoghq.eu',
      version: 'v1.0.1',
      forwardErrorsToLogs: true,
      forwardReports: 'all',
      sessionSampleRate: 100,
      forwardConsoleLogs: 'all',
      telemetrySampleRate: 0,
      trackSessionAcrossSubdomains: false,
      useSecureSessionCookie: true,
      useCrossSiteSessionCookie: true
    });
    gtmInit();
  }
};

const { store } = configureStore();

ReactDOM.createRoot(document.getElementById('root')).render(
  <Provider store={store}>
    <App />
  </Provider>
);
