import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as propTypes from 'prop-types';
import PaymentMethod from '../PaymentMethod';
import PaymentMethodIcon from '../PaymentMethodIcon';

import { PAYMENT_METHOD_CARD } from '../../constants';
import { selectCanChangePaymentMethod } from '../../selectors/selectors';

import AdyenDropIn from './AdyenDropIn';
import PoweredByAdyen from './PoweredByAdyen';

const CardPayment = ({ onClick, history }) => {
  const { selectedPaymentMethod } = useSelector((state) => state.app);

  const isSelected = selectedPaymentMethod === 'card';

  const canChangePaymentMethod = useSelector(selectCanChangePaymentMethod);

  // An overlay component can be rendered instead of the payment method content
  const [overlayComponent] = useState(null);

  const { t } = useTranslation();

  const partPaymentTitle = t('payment_method.pay_with_card', {
    defaultValue: 'Card'
  });

  const onClickCallback = () => {
    onClick(PAYMENT_METHOD_CARD);
  };

  return (
    <PaymentMethod
      name={partPaymentTitle}
      isSelected={isSelected}
      onClick={onClickCallback}
      canChangePaymentMethod={canChangePaymentMethod}
      icon={<PaymentMethodIcon paymentMethod={PAYMENT_METHOD_CARD} />}
      overlayComponent={overlayComponent}
      dataCy="CardPayment"
    >
      <AdyenDropIn
        {...{
          history
        }}
      />
      <PoweredByAdyen />
    </PaymentMethod>
  );
};

CardPayment.propTypes = {
  onClick: propTypes.func,
  history: propTypes.any
};

CardPayment.defaultProps = {
  onClick: undefined,
  history: undefined
};

export default CardPayment;
