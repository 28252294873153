import { v4 as uuidv4 } from 'uuid';
import {
  PREPARE_STARTED,
  PREPARE_FAILED,
  PREPARE_COMPLETE,
  SET_PURCHASE_REQUEST_TOKEN,
  SHOW_CANCEL_VIEW,
  SET_SELECTED_PAYMENT_METHOD,
  SET_PAYMENT_METHODS,
  SET_SELECTED_PAYMENT_METHOD_OPTION,
  SET_PARENT_WINDOW_ORIGIN,
  SET_AUTH_REQUIRED,
  CONFIG_COMPLETE,
  AUTHENTICATION_STARTED,
  AUTHENTICATION_CANCELED,
  AUTHENTICATION_ALREADY_IN_PROGRESS,
  AUTHENTICATION_FAILED,
  AUTHENTICATION_DONE,
  AUTHORIZE_STARTED,
  AUTHORIZE_FAILED,
  AUTHORIZE_COMPLETE,
  SET_REDIRECT_URL,
  SIGNING_DONE,
  SIGNING_FAILED,
  SIGNING_ALREADY_IN_PROGRESS,
  SIGNING_CANCELED,
  SIGNING_STARTED,
  SIGNING_TECHNICAL_ERROR,
  CARD_AUTHORIZE_AND_CAPTURE,
  USER_INFO_COLLECTION_STARTED,
  USER_INFO_COLLECTION_COMPLETED,
  USER_INFO_COLLECTION_EMAIL,
  USER_INFO_COLLECTION_PHONE,
  USER_INFO_COLLECTION_API_ERROR,
  USER_INFO_COLLECTION_CANCELLED_CLICKED,
  AUTHORIZE_UNAUTHORIZED,
  MONTHIO_CANCEL,
  MONTHIO_FLOW_COMPLETED_DONE,
  MONTHIO_FLOW_COMPLETED_ERROR,
  MONTHIO_POLL_ONCE,
  MONTHIO_POLL_ONCE_DONE,
  IS_MONTHIO_FLOW,
  MONTHIO_CREATE_DONE,
  MONTHIO_CREATE_ERROR,
  MONTHIO_POLL_ERROR,
  MONTHIO_OPEN_AGAIN,
  SBID_QR,
  SBID_AUTO_START_TOKEN,
  QUESTIONS_VIEW_TOGGLE,
  KYC_FLOW_DONE,
  SET_AUTH_TOKEN,
  SET_KYC_QUESTIONS,
  SET_KYC_VERSION,
  TECHNICAL_ERROR,
  SET_KALP_VERSION,
  SET_KALP_QUESTIONS,
  KALP_FLOW_DONE,
  SET_QUESTIONS_TO_DISPLAY,
  IS_LOADING,
  SET_LANGUAGE,
  CONFIG_HIGH_LOAD,
  CONFIG_CIRCUIT_BREAKER
} from '../constants/actions';
import theme from '../styled-components/theme/theme';
import { DEFAULT_COUNTRY } from '../constants';

const intialState = {
  country: DEFAULT_COUNTRY,
  language: null,
  purchaseRequestToken: null,
  paymentMethods: null,
  selectedPaymentMethodOption: 1,
  partPaymentMinimumAmount: 0,
  processPurchaseRequestLoading: false,
  processPurchaseRequestSuccess: null,
  hasClickedButtonToNextStep: false,
  errorContactSupport: false,
  merchantId: null,
  customerState: 'NEW',
  parentWindowOrigin: '',
  theme,
  authentication: { required: false },
  preSelectedMonth: null,
  correlationId: uuidv4(),
  storeName: '',
  strongSignRequired: false,
  emailPhoneCollectionEnabled: false,
  userInfoCollectionViewIsActive: false,
  userCreditCheckQuestionsViewIsActive: false,
  kycQuestionsViewIsActive: false,
  email: null,
  phone: null,
  redirectUrl: null,
  kalpEnabled: true,
  kycEnabled: true,
  kycQuestions: null,
  kycVersion: null,
  kalpQuestions: null,
  kalpVersion: null,
  kycFlowDone: false,
  kalpFlowDone: false,
  questionsToDisplay: null,
  uiConfig: {
    headerEnabled: true
  }
};

function appReducer(state = intialState, action) {
  switch (action.type) {
    case CONFIG_HIGH_LOAD:
      return {
        ...state,
        configHighLoad: true,
        configCircuitBreaker: false,
        trafficFlattenerWaitTimeInSeconds:
          action?.trafficFlattenerWaitTimeInSeconds
      };
    case CONFIG_CIRCUIT_BREAKER:
      return {
        ...state,
        configCircuitBreaker: true,
        configHighLoad: false,
        tryAgainTimeout: action?.timeout
      };
    case CONFIG_COMPLETE:
      return {
        ...state,
        configHighLoad: false,
        currency: action.locale.currency,
        country: action.locale.country,
        language: action.locale.language,
        flow: action.flow,
        selectedPaymentMethodOption: action.selectedPaymentMethodOption,
        paymentMethods: action.paymentMethods,
        selectedPaymentMethod: action.selectedPaymentMethod,
        isLockedMonth: action.isLockedMonth,
        partPaymentMinimumAmount: action.partPaymentMinimumAmount,
        storeName: action.storeName,
        strongSignRequired: action.strongSignRequired,
        emailPhoneCollectionEnabled: action.emailPhoneCollectionEnabled,
        userInfoCollectionViewIsActive: action.userInfoCollectionViewIsActive,
        email: action.email,
        mobile: action.mobile,
        merchantId: action.merchantId,
        kalpEnabled: action.kalpEnabled,
        kycEnabled: action.kycEnabled,
        uiConfig: action.uiConfig,
        authentication: { ...state.authentication, ssn: action.ssn }
      };
    case SET_LANGUAGE:
      return {
        ...state,
        language: action.language
      };
    case IS_MONTHIO_FLOW: {
      return {
        ...state,
        isMonthioFlow: true
      };
    }
    case MONTHIO_CREATE_DONE:
      return {
        ...state,
        isMonthioCreateDone: action.isMonthioCreateDone,
        monthioCanceled: false,
        monthioCompletedError: false,
        monthioPollFinished: false
      };
    case MONTHIO_CREATE_ERROR:
      return {
        ...state,
        isMonthioCreateDone: false
      };
    case MONTHIO_FLOW_COMPLETED_DONE:
      return {
        ...state,
        monthioPollFinished: true,
        isMonthioFlow: false,
        monthioCompletedError: false,
        monthioCompletedDone: true
      };
    case MONTHIO_FLOW_COMPLETED_ERROR:
      return {
        ...state,
        monthioCompletedError: true,
        isMonthioFlow: false,
        monthioPollFinished: true
      };
    case MONTHIO_POLL_ERROR: {
      return {
        ...state,
        monthioPollFinished: true
      };
    }
    case MONTHIO_CANCEL:
      return {
        ...state,
        isMonthioCreateDone: false,
        monthioCanceled: true,
        monthioPollFinished: true
      };
    case MONTHIO_POLL_ONCE:
      return {
        ...state,
        pollMonthioOnce: true
      };
    case MONTHIO_POLL_ONCE_DONE:
      return {
        ...state,
        pollMonthioOnce: false,
        pollMonthioOnceDone: true
      };
    case MONTHIO_OPEN_AGAIN: {
      return {
        ...state,
        monthioPollFinished: false,
        pollMonthioOnceDone: false,
        monthioCanceled: false,
        monthioCompletedError: false
      };
    }
    case PREPARE_STARTED:
      return {
        ...state,
        isPreparing: true
      };
    case PREPARE_FAILED:
      return {
        ...state,
        errorContactSupport: true,
        isPreparing: false
      };
    case USER_INFO_COLLECTION_STARTED:
      return {
        ...state,
        userInfoCollectionViewIsActive: true
      };
    case USER_INFO_COLLECTION_EMAIL:
      return { ...state, email: action.email };
    case USER_INFO_COLLECTION_CANCELLED_CLICKED:
      return {
        ...state,
        userInfoCollectionViewIsActive: false,
        showCancelView: true
      };
    case USER_INFO_COLLECTION_PHONE:
      return { ...state, mobile: action.mobile };
    case USER_INFO_COLLECTION_API_ERROR:
      return {
        ...state,
        userCollectionApiError: action.userCollectionApiError
      };
    case USER_INFO_COLLECTION_COMPLETED:
      return {
        ...state,
        userInfoCollectionViewIsActive: false
      };
    // case ACCOUNT_LOOKUP_COMPLETE:
    //   return {
    //     ...state,
    //     customerType: action?.customerType,
    //     accountLookupStatusType: action?.accountLookupStatusType,
    //     isPreparing: action.completeWithoutRiskAssessment,
    //     hasClickedButtonToNextStep: true
    //   };
    // case RISK_ASSESSMENT_COMPLETE:
    //   return {
    //     ...state,
    //     customerState: action?.customerState,
    //     isPreparing: false,
    //     hasClickedButtonToNextStep: true
    //   };
    case PREPARE_COMPLETE:
      return {
        ...state,
        customerState: action?.customerState,
        isPreparing: false,
        hasClickedButtonToNextStep: true
      };
    case CARD_AUTHORIZE_AND_CAPTURE:
      return {
        ...state,
        hasClickedButtonToNextStep: true
      };
    case SET_AUTH_REQUIRED:
      return {
        ...state,
        authentication: { required: true, ssn: action.ssn }
      };
    case AUTHENTICATION_STARTED:
      return {
        ...state,
        isAuthenticating: true,
        isAuthenticatingError: false,
        isAuthenticatingAlreadyInProgressError: false
      };
    case SBID_QR:
      return {
        ...state,
        qr: action.qr
      };
    case SBID_AUTO_START_TOKEN:
      return {
        ...state,
        autoStartToken: action.autoStartToken,
        isSignFlow: action.isSignFlow,
        isSwedishBankID: true
      };
    case AUTHENTICATION_CANCELED:
      return {
        ...state,
        isAuthenticating: false
      };
    case AUTHENTICATION_ALREADY_IN_PROGRESS:
      return {
        ...state,
        isAuthenticating: false,
        isAuthenticatingAlreadyInProgressError: true
      };
    case AUTHENTICATION_FAILED:
      return {
        ...state,
        isAuthenticating: false,
        isAuthenticatingError: true
      };
    case AUTHENTICATION_DONE:
      return {
        ...state,
        isAuthenticating: false,
        authentication: { ...state.authentication, done: true }
      };
    case SIGNING_STARTED:
      return {
        ...state,
        isSigning: true,
        isSigningError: false,
        isSigningAlreadyInProgressError: false
      };
    case SIGNING_CANCELED:
      return {
        ...state,
        isSigning: false
      };
    case SIGNING_ALREADY_IN_PROGRESS:
      return {
        ...state,
        isSigning: false,
        isSigningAlreadyInProgressError: true
      };
    case SIGNING_FAILED:
      return {
        ...state,
        isSigning: false,
        isSigningError: true,
        errorContactSupport: false
      };
    case SIGNING_TECHNICAL_ERROR:
      return {
        ...state,
        isSigning: false,
        errorContactSupport: true
      };
    case SIGNING_DONE:
      return {
        ...state,
        isSigning: false,
        authentication: { ...state.authentication, done: true }
      };
    case AUTHORIZE_STARTED:
      return {
        ...state,
        isUnauthorized: false,
        showProcessingPurchaseRequest: true,
        processPurchaseRequestLoading: true
      };
    case AUTHORIZE_UNAUTHORIZED:
      return {
        ...state,
        isUnauthorized: true,
        showProcessingPurchaseRequest: false,
        processPurchaseRequestLoading: false
      };
    case AUTHORIZE_FAILED:
      return {
        ...state,
        processPurchaseRequestErrorContactSupport: true,
        processPurchaseRequestLoading: false
      };
    case AUTHORIZE_COMPLETE:
      return {
        ...state,
        customerDetails: action?.customerDetails,
        showProcessingPurchaseRequest: false,
        processPurchaseRequestSuccess: true,
        processPurchaseRequestLoading: false
      };
    case TECHNICAL_ERROR:
      return {
        ...state,
        errorContactSupport: true
      };
    case SET_PURCHASE_REQUEST_TOKEN:
      return { ...state, purchaseRequestToken: action.purchaseRequestToken };
    case SET_PAYMENT_METHODS:
      return { ...state, paymentMethods: action.paymentMethods };
    case SET_SELECTED_PAYMENT_METHOD:
      return { ...state, selectedPaymentMethod: action.paymentMethod };
    case SET_SELECTED_PAYMENT_METHOD_OPTION:
      return { ...state, selectedPaymentMethodOption: action.option };
    case SHOW_CANCEL_VIEW:
      return { ...state, showCancelView: action.value };
    case SET_PARENT_WINDOW_ORIGIN:
      return {
        ...state,
        parentWindowOrigin: action.parentWindowOrigin,
        preSelectedMonth: action.preSelectedMonth
      };
    case SET_REDIRECT_URL: {
      return { ...state, redirectUrl: action.value };
    }
    case IS_LOADING: {
      return { ...state, isLoading: action.isLoading };
    }
    case QUESTIONS_VIEW_TOGGLE: {
      return { ...state, kycQuestionsViewIsActive: action.value };
    }
    case KYC_FLOW_DONE: {
      return { ...state, kycFlowDone: action.value };
    }
    case KALP_FLOW_DONE: {
      return { ...state, kalpFlowDone: action.value };
    }
    case SET_KYC_QUESTIONS: {
      return { ...state, kycQuestions: action.value };
    }
    case SET_KYC_VERSION: {
      return { ...state, kycVersion: action.value };
    }
    case SET_KALP_VERSION: {
      return { ...state, kalpVersion: action.value };
    }
    case SET_KALP_QUESTIONS: {
      return { ...state, kalpQuestions: action.value };
    }
    case SET_QUESTIONS_TO_DISPLAY: {
      return { ...state, questionsToDisplay: action.value };
    }
    case SET_AUTH_TOKEN: {
      return {
        ...state,
        authentication: { ...state.authentication, authToken: action.value }
      };
    }
    default:
      return state;
  }
}
export default appReducer;
