import globals from '../globals';
import UnexpectedServerResponse from '../UnexpectedServerResponse';

export const sbidPollStatus = {
  DONE: 'DONE',
  ERR: 'ERR',
  ONGOING: 'ONGOING',
  EXPIRED: 'EXPIRED'
};

const sbidSignPoll = async (
  token,
  correlationId,
  purchaseRequestToken = null, // Only needed for logs
  fetch = window.fetch
) => {
  const query = new URLSearchParams({ token });
  const response = await fetch(`${globals.signSbidPoll}?${query}`, {
    method: 'GET',
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Correlation-ID': correlationId,
      'Purchase-Request-Token': purchaseRequestToken
    }
  });

  switch (response.status) {
    case 200: {
      const { signJwt } = await response.json();
      if (!signJwt) {
        throw new UnexpectedServerResponse(response, 'Received no authJwt');
      }
      return { signJwt, status: sbidPollStatus.DONE };
    }
    case 202: {
      const { qrData } = await response.json();

      return { status: sbidPollStatus.ONGOING, qrData };
    }
    case 410:
      return { status: sbidPollStatus.EXPIRED };
    default:
      throw new UnexpectedServerResponse(response);
  }
};

export default sbidSignPoll;
