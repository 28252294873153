import { useTranslation } from 'react-i18next';
import React from 'react';
import { formatTimeInMinutesAndSeconds } from '../../helpers/amount';

const DecimalFormatAmountWithPostFix = ({ amount }) => {
  const { t } = useTranslation();
  const min = formatTimeInMinutesAndSeconds(amount);
  const sec = amount > 0 ? amount / 1000 : 0;

  return (
    <>
      {sec > 59 && (
        <span data-cy="MinutesPostfix">
          {`${min} `}
          {t('circuit_breaker.minutes_postfix', {
            defaultValue: 'min'
          })}
        </span>
      )}
      {sec < 60 && (
        <span data-cy="SecondsPostfix">
          {`${sec} `}
          {t('circuit_breaker.seconds_postfix', {
            defaultValue: 'sec'
          })}
        </span>
      )}
    </>
  );
};

export default DecimalFormatAmountWithPostFix;
