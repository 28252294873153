import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { MonthItem } from '../styled-components/styledcomponents';

const MonthViewer = ({ option }) => {
  const { t } = useTranslation();

  return (
    <StyledMonthItem>
      {t('month_picker.number_of_months', {
        count: option?.downPayTime
      })}
    </StyledMonthItem>
  );
};

MonthViewer.propTypes = {
  option: PropTypes.object.isRequired
};

const StyledMonthItem = styled(MonthItem)`
  cursor: default;
  background: none;
  border: none;
  padding: 0;
  font-size: ${({ theme }) => theme.fontSize.mediumSmall};
  font-weight: 400;
  line-height: 30px;
  color: ${({ theme }) => theme.color.monthItemTextColor};
  width: 100%;
  margin: ${({ theme }) =>
    `${theme.spacing.spacing16} 0 ${theme.spacing.spacing32} 0`};
`;
export default MonthViewer;
