import paymentApiHelpers from './paymentApiHelpers';

const finalize = async (
  purchaseRequestToken,
  data,
  correlationId,
  fetch = window.fetch
) => {
  try {
    const response = await fetch(`${paymentApiHelpers.getBaseUrl()}/finalize`, {
      method: 'POST',
      headers: paymentApiHelpers.setHeaders(
        purchaseRequestToken,
        correlationId
      ),
      body: JSON.stringify(data)
    });
    return { status: response.status, response };
  } catch (error) {
    return null;
  }
};

export default finalize;
