import paymentApiHelpers from './paymentApiHelpers';

function setBody(paymentMethod, returnUrl, origin, browserInfo) {
  return JSON.stringify({
    paymentMethod,
    returnUrl,
    origin,
    browserInfo
  });
}

const authorizeAndCapture = async (
  purchaseRequestToken,
  paymentMethod,
  returnUrl,
  origin,
  browserInfo,
  correlationId,
  fetch = window.fetch
) => {
  try {
    const response = await fetch(
      `${paymentApiHelpers.getBaseUrl()}/authorizeAndCapture`,
      {
        method: 'POST',
        headers: paymentApiHelpers.setHeaders(
          purchaseRequestToken,
          correlationId
        ),
        body: setBody(paymentMethod, returnUrl, origin, browserInfo)
      }
    );
    return { status: response.status, response };
  } catch (error) {
    return null;
  }
};

export default authorizeAndCapture;
