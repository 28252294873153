import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  partPaymentAmountPerMonthEvent,
  partPaymentSelectedMonthsEvent
} from '../actions/eventActions';
import { MonthItem } from '../styled-components/styledcomponents';

const dispatchEvents = (dispatch, monthlyCost, downPayTime) => {
  dispatch(partPaymentSelectedMonthsEvent(downPayTime.toString()));
  dispatch(partPaymentAmountPerMonthEvent(Math.round(monthlyCost)));
};

const useInitialEventDispatch = (options, selectedOption) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatchEvents(
      dispatch,
      options[selectedOption].monthlyCost,
      options[selectedOption].downPayTime.toString()
    );
  }, []);
};

const MonthPicker = ({ options, selectedOption, setSelectedOption }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useInitialEventDispatch(options, selectedOption);

  const onClickHandler = (index) => {
    document.activeElement.blur();
    setSelectedOption(index);
    dispatchEvents(
      dispatch,
      options[index].monthlyCost,
      options[index].downPayTime.toString()
    );
  };
  const isGridLayout = options.length > 3;
  return (
    <MonthPickerWrapper isGrid={isGridLayout}>
      {options.map((option, index) => {
        const dpt = option.downPayTime;

        if (index === selectedOption) {
          return (
            <MonthItem
              isSelected
              key={dpt}
              onClick={() => {
                document.activeElement.blur();
              }}
              data-cy="MonthItem"
            >
              {t('month_picker.number_of_months', { count: dpt })}
            </MonthItem>
          );
        }
        return (
          <MonthItem
            onMouseDown={() => {}}
            onClick={() => {
              onClickHandler(index);
            }}
            key={dpt}
            data-cy="MonthItem"
          >
            {t('month_picker.number_of_months', { count: dpt })}
          </MonthItem>
        );
      })}
    </MonthPickerWrapper>
  );
};

const MonthPickerWrapper = styled.div.attrs({
  className: 'MonthPickerWrapper'
})`
  width: 100%;
  height: ${({ isGrid }) => (isGrid ? '104px' : '46px')};
  display: ${({ isGrid }) => (isGrid ? 'grid' : 'flex')};
  grid-template-rows: ${({ isGrid }) => isGrid && 'auto()'};
  grid-template-columns: ${({ isGrid }) => isGrid && '1fr 1fr'};
  gap: 16px;
  justify-content: space-between;
  margin: ${({ theme }) =>
    `${theme.spacing.spacing16} 0 ${theme.spacing.spacing24}`};
`;

MonthPicker.propTypes = {
  options: PropTypes.array.isRequired,
  selectedOption: PropTypes.number.isRequired,
  setSelectedOption: PropTypes.func.isRequired
};

export default MonthPicker;
