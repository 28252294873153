import { useSelector } from 'react-redux';

const useAdyenLocale = () => {
  const { language } = useSelector((state) => state.app);
  switch (language) {
    case 'en':
      return 'en-US';
    default:
      return language;
  }
};

export default useAdyenLocale;
