/* eslint-disable import/prefer-default-export */
import * as EVENT from '../../events';
import {
  PAYMENT_METHOD_PART_PAYMENT,
  PAYMENT_METHOD_PART_PAYMENT_EXTERNAL
} from '../constants';

import { WEBCHECKOUT_EVENT } from '../constants/actions';

export const EVENT_VALUE = {
  OPENED: 'opened',
  CLOSED: 'closed',
  SUCCESSFUL: 'successful',
  UNAUTHORIZED: 'unauthorized',
  ALREADY_IN_PROGRESS: 'alreadyInProgress',
  FAILED: 'failed',
  SAVED: 'saved',
  CANCELED: 'canceled'
};

export function partPaymentSummaryEvent(value) {
  return {
    type: WEBCHECKOUT_EVENT,
    webcheckoutEvent: { type: EVENT.PART_PAYMENT_SUMMARY, value }
  };
}

export function partPaymentSelectedMonthsEvent(value) {
  return {
    type: WEBCHECKOUT_EVENT,
    webcheckoutEvent: { type: EVENT.PART_PAYMENT_SELECTED_MONTHS, value }
  };
}

export function partPaymentAmountPerMonthEvent(value) {
  return {
    type: WEBCHECKOUT_EVENT,
    webcheckoutEvent: {
      type: EVENT.PART_PAYMENT_AMOUNT_PER_MONTH,
      value
    }
  };
}

export function clickedIdentifyButtonEvent() {
  return {
    type: WEBCHECKOUT_EVENT,
    webcheckoutEvent: { type: EVENT.CLICKED_IDENTIFY_BUTTON }
  };
}

export function clickedSignButtonEvent() {
  return {
    type: WEBCHECKOUT_EVENT,
    webcheckoutEvent: { type: EVENT.CLICKED_SIGN_BUTTON }
  };
}

export function clickedContinueButtonEvent() {
  return {
    type: WEBCHECKOUT_EVENT,
    webcheckoutEvent: { type: EVENT.CLICKED_CONTINUE_BUTTON }
  };
}

export function clickedCompleteCardPaymentButtonEvent() {
  return {
    type: WEBCHECKOUT_EVENT,
    webcheckoutEvent: { type: EVENT.CLICKED_COMPLETE_CARD_PAYMENT_BUTTON }
  };
}

export function clickedPayButtonEvent(value) {
  // Translate 'account' to 'partPayment' for consistent wording
  // towards the merchants
  let mValue = value;
  if (mValue === PAYMENT_METHOD_PART_PAYMENT) {
    mValue = PAYMENT_METHOD_PART_PAYMENT_EXTERNAL;
  }
  return {
    type: WEBCHECKOUT_EVENT,
    webcheckoutEvent: { type: EVENT.CLICKED_PAY_BUTTON, value: mValue }
  };
}

export function chosenPaymentMethodEvent(value) {
  // Translate 'account' to 'partPayment' for consistent wording
  // towards the merchants
  let mValue = value;
  if (mValue === PAYMENT_METHOD_PART_PAYMENT) {
    mValue = PAYMENT_METHOD_PART_PAYMENT_EXTERNAL;
  }
  return {
    type: WEBCHECKOUT_EVENT,
    webcheckoutEvent: { type: EVENT.CHOSEN_PAYMENT_METHOD, value: mValue }
  };
}

export function moreInformationEvent(value) {
  return {
    type: WEBCHECKOUT_EVENT,
    webcheckoutEvent: {
      type: EVENT.MORE_INFORMATION,
      value: value ? EVENT_VALUE.OPENED : EVENT_VALUE.CLOSED
    }
  };
}

export function authenticationEvent(value) {
  return {
    type: WEBCHECKOUT_EVENT,
    webcheckoutEvent: { type: EVENT.AUTHENTICATED, value }
  };
}

export function signingEvent(value) {
  return {
    type: WEBCHECKOUT_EVENT,
    webcheckoutEvent: { type: EVENT.SIGNING, value }
  };
}

export function paymentProcessingEvent(value) {
  return {
    type: WEBCHECKOUT_EVENT,
    webcheckoutEvent: { type: EVENT.PAYMENT_PROCESSED, value }
  };
}

/**
 * Create the INITIATED event that will be sent to the embedding page.
 *
 * The storeName parameter is intended to be used for the phone flow.
 * It lets the embedding page know what store name it should put in
 * the title. If not applicable, storeName will be undefined.
 */
export function webcheckoutInitiatedEvent({
  order,
  currency,
  storeName,
  language
}) {
  return {
    type: WEBCHECKOUT_EVENT,
    webcheckoutEvent: {
      type: EVENT.WEBCHECKOUT_INITIATED,
      value: {
        storeName,
        language,
        orderId: order.orderId,
        orderItems: order.orderItems.map((item) => ({
          productId: item.productId,
          description: item.description,
          amount: item.amount,
          currency
        }))
      }
    }
  };
}

export function webcheckoutCompleteSuccessEvent(
  paymentDetails,
  amountToPay = null,
  financeableAmount = null,
  customerDetails = null,
  isCardPayment = false
) {
  const webcheckoutEvent = isCardPayment
    ? {
        type: EVENT.WEBCHECKOUT_COMPLETE.SUCCESS,
        value: {
          cardPayment: {
            amount: paymentDetails.amount,
            currency: paymentDetails.currency,
            paperInvoiceFee: paymentDetails.paperInvoiceFee
          }
        }
      }
    : {
        type: EVENT.WEBCHECKOUT_COMPLETE.SUCCESS,
        value: {
          partPayment: {
            months: paymentDetails.downPayTime,
            amount: paymentDetails.totalCost,
            currency: paymentDetails.currency
          }
        }
      };

  if (!isCardPayment && amountToPay && financeableAmount) {
    const payTheDifference = {
      payTheDifference: {
        amountToPay,
        financeableAmount
      }
    };
    Object.assign(webcheckoutEvent.value, payTheDifference);
  }

  if (customerDetails) {
    Object.assign(webcheckoutEvent.value, { customerDetails });
  }

  return {
    type: WEBCHECKOUT_EVENT,
    webcheckoutEvent
  };
}

export function webcheckoutCompleteErrorEvent(errorDetails) {
  return {
    type: WEBCHECKOUT_EVENT,
    webcheckoutEvent: {
      type: EVENT.WEBCHECKOUT_COMPLETE.ERROR,
      value: errorDetails
    }
  };
}

export function webcheckoutCompleteCardErrorEvent(errorDetails) {
  return {
    type: WEBCHECKOUT_EVENT,
    webcheckoutEvent: {
      type: EVENT.WEBCHECKOUT_COMPLETE.CARD_ERROR,
      value: errorDetails
    }
  };
}

export function webcheckoutCompleteCancelEvent() {
  return {
    type: WEBCHECKOUT_EVENT,
    webcheckoutEvent: { type: EVENT.WEBCHECKOUT_COMPLETE.CANCEL }
  };
}

export function creditDeniedRedirectClicked() {
  return {
    type: WEBCHECKOUT_EVENT,
    webcheckoutEvent: {
      type: EVENT.WEBCHECKOUT_COMPLETE.REDIRECT_AT_CREDIT_DENIED
    }
  };
}

export function orderAmountTooLowRedirectClicked() {
  return {
    type: WEBCHECKOUT_EVENT,
    webcheckoutEvent: {
      type: EVENT.WEBCHECKOUT_COMPLETE.REDIRECT_AT_ORDER_AMOUNT_TOO_LOW
    }
  };
}

export function emailAndPhoneRedirectClicked() {
  return {
    type: WEBCHECKOUT_EVENT,
    webcheckoutEvent: {
      type: EVENT.WEBCHECKOUT_COMPLETE.REDIRECT_AT_CONTACT_INFORMATION
    }
  };
}

export function creditCheckDenied() {
  return {
    type: WEBCHECKOUT_EVENT,
    webcheckoutEvent: {
      type: EVENT.CREDIT_CHECK_DENIED
    }
  };
}
