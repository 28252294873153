import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Loading from './Loading';
import ROUTES from '../constants/routes';
import postMessageOut from '../helpers/postMessage/post-message-out';

const Initialize = ({ history }) => {
  const { paymentMethods, selectedPaymentMethod } = useSelector(
    (state) => state.app
  );

  useEffect(() => {
    postMessageOut({ type: 'booted', internal: true });
  }, []);

  useEffect(() => {
    history.push(`${ROUTES.PAYMENT_METHOD}/${selectedPaymentMethod}`);
  }, [paymentMethods, selectedPaymentMethod]);

  return (
    <>
      <LoadingContainer>
        <Loading />
      </LoadingContainer>
    </>
  );
};

Initialize.propTypes = {
  history: PropTypes.object.isRequired
};

export default Initialize;

const LoadingContainer = styled.div`
  width: 50px;
  margin: 0 auto;
`;
