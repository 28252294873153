import globals from '../globals';
import UnexpectedServerResponse from '../UnexpectedServerResponse';

export const configPollStatus = {
  OK: 'OK',
  HIGH_LOAD: 'HIGH_LOAD',
  CIRCUIT_BREAKER: 'CIRCUIT_BREAKER'
};

async function creditAccountConfig(
  purchaseRequestToken,
  correlationId,
  fetch = window.fetch
) {
  const response = await fetch(globals.config, {
    method: 'GET',
    headers: {
      'Purchase-Request-Token': purchaseRequestToken,
      'X-Correlation-ID': correlationId
    }
  });

  switch (response.status) {
    case 212:
      return {
        ...(await response.json()),
        status: configPollStatus.CIRCUIT_BREAKER
      };
    case 211:
      return { ...(await response.json()), status: configPollStatus.HIGH_LOAD };
    case 200:
      return { ...(await response.json()), status: configPollStatus.OK };
    default:
      throw new UnexpectedServerResponse(response);
  }
}

export default creditAccountConfig;
