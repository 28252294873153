import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import React from 'react';
import * as propTypes from 'prop-types';
import {
  Key,
  SummaryRow,
  SummaryRowTotal,
  Value
} from '../styled-components/styledcomponents';
import { decimalFormatAmount, formatCurrency } from '../helpers/amount';

const PayTheDifferenceTotalSummary = ({ totalCost }) => {
  const { financeableAmount, offLimitAmountToPay, currency } = useSelector(
    (state) => state.partPayment
  );
  const { t } = useTranslation();

  return (
    <>
      <SummaryRow>
        <Key>
          {t('pay_in_parts_summary.pay_the_difference_credit_amount', {
            defaultValue: 'Part payment'
          })}
        </Key>
        <Value>
          {`${decimalFormatAmount(financeableAmount)}`}
          <span className="currency">{formatCurrency(currency)}</span>
        </Value>
      </SummaryRow>
      <SummaryRow>
        <Key>
          {t('pay_in_parts_summary.pay_the_difference_direct_amount', {
            defaultValue: 'Direct payment'
          })}
        </Key>
        <Value>
          {`${decimalFormatAmount(offLimitAmountToPay)}`}
          <span className="currency">{formatCurrency(currency)}</span>
        </Value>
      </SummaryRow>
      <SummaryRowTotal>
        <Key>
          {t('pay_in_parts_summary.pay_the_difference_total_amount', {
            defaultValue: 'Amount to pay'
          })}
        </Key>
        <Value>
          {`${decimalFormatAmount(totalCost)}`}
          <span className="currency">{formatCurrency(currency)}</span>
        </Value>
      </SummaryRowTotal>
    </>
  );
};

PayTheDifferenceTotalSummary.propTypes = {
  totalCost: propTypes.number.isRequired
};

export default PayTheDifferenceTotalSummary;
