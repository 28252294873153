/**
 * Credit Account Service API.
 *
 * Provides the API to the Credit Account Service.
 */

import globals from './globals';
import UnexpectedServerResponse from './UnexpectedServerResponse';
import UnexpectedStatusCode from './UnexpectedStatusCode';

async function prepare(
  purchaseRequestToken,
  selectedMonths,
  authToken,
  correlationId,
  fetch = window.fetch
) {
  const response = await fetch(globals.prepare, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Purchase-Request-Token': purchaseRequestToken,
      'X-Correlation-ID': correlationId
    },
    body: JSON.stringify(
      authToken
        ? {
            customer: { authJwt: authToken },
            order: {
              selectedMonths
            }
          }
        : {
            order: {
              selectedMonths
            }
          }
    )
  });

  try {
    switch (response.status) {
      case 200: {
        const {
          payment: {
            customerState,
            currentLimit,
            newLimit,
            maximumCreditLimit,
            amountToPay,
            creditSpace,
            financeableAmount,
            partPaymentMinimumAmount
          }
        } = await response.json();
        return {
          status: 'OK',
          value: {
            type: customerState,
            currentLimit,
            newLimit,
            maximumCreditLimit,
            amountToPay,
            creditSpace,
            financeableAmount,
            partPaymentMinimumAmount
          }
        };
      }
      case 400:
      case 401:
      case 404:
      case 435:
        return { status: 'ERR', value: response };
      default:
        throw new UnexpectedStatusCode(response.status);
    }
  } catch (e) {
    throw new UnexpectedServerResponse(response, e);
  }
}

async function authorize(
  purchaseRequestToken,
  correlationId,
  signJwt = null,
  fetch = window.fetch
) {
  const signJwtParameters = {
    customer: {
      signJwt
    }
  };

  const body = signJwt ? signJwtParameters : {};

  const response = await fetch(globals.authorize, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Purchase-Request-Token': purchaseRequestToken,
      'X-Correlation-ID': correlationId
    },
    body: JSON.stringify(body)
  });

  switch (response.status) {
    case 200:
      return { status: 'OK', value: await response.json() };
    case 401: {
      return { status: 'UNAUTHORIZED', value: await response.json() };
    }
    case 435: {
      return { status: 'ERR', value: await response.json() };
    }
    default:
      throw new UnexpectedServerResponse(response);
  }
}

async function abort(
  purchaseRequestToken,
  correlationId,
  fetch = window.fetch
) {
  const response = await fetch(globals.abort, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Purchase-Request-Token': purchaseRequestToken,
      'X-Correlation-ID': correlationId
    }
  });

  switch (response.status) {
    case 200:
      return { status: 'OK' };
    case 404:
      return { status: 'ERR', value: await response.json() };
    default:
      throw new UnexpectedServerResponse(response);
  }
}

async function customerContactInfo(
  purchaseRequestToken,
  correlationId,
  email,
  mobile,
  fetch = window.fetch
) {
  const response = await fetch(globals.contact, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      'Purchase-Request-Token': purchaseRequestToken,
      'X-Correlation-ID': correlationId
    },
    body: JSON.stringify({
      email,
      mobile
    })
  });

  switch (response.status) {
    case 200:
      return { status: 'OK' };
    case 400:
      return { status: 'VALIDATION_ERR', value: await response.json() };
    case 404:
    case 409:
      return { status: 'ERR', value: await response.json() };
    default:
      throw new UnexpectedServerResponse(response);
  }
}

async function kycCheck(
  purchaseRequestToken,
  correlationId,
  fetch = window.fetch
) {
  const response = await fetch(globals.kyc, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Purchase-Request-Token': purchaseRequestToken,
      'X-Correlation-ID': correlationId
    }
  });
  const data = await response.json();
  switch (response.status) {
    case 200:
      if (data.value) {
        return { status: 'FOUND' };
      }
      return { status: 'NOT FOUND' };
    case 204:
      return { status: 'OK' };
    default:
      throw new UnexpectedServerResponse(response);
  }
}

async function getKycQuestions(
  purchaseRequestToken,
  correlationId,
  fetch = window.fetch
) {
  const response = await fetch(globals.kycQuestions, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Purchase-Request-Token': purchaseRequestToken,
      'X-Correlation-ID': correlationId
    }
  });
  switch (response.status) {
    case 200:
      return response.json();
    default:
      throw new UnexpectedServerResponse(response);
  }
}

async function kycCreate(
  purchaseRequestToken,
  correlationId,
  language,
  version,
  values,
  fetch = window.fetch
) {
  const response = await fetch(globals.kyc, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Purchase-Request-Token': purchaseRequestToken,
      'X-Correlation-ID': correlationId
    },
    body: JSON.stringify({ language, values, version })
  });
  switch (response.status) {
    case 201:
      return { status: 'OK' };
    case 400:
    case 404:
    case 405:
      return { status: 'ERROR' };
    default:
      throw new UnexpectedServerResponse(response);
  }
}

async function getKalpQuestions(
  purchaseRequestToken,
  correlationId,
  fetch = window.fetch
) {
  const response = await fetch(globals.kalpQuestions, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Purchase-Request-Token': purchaseRequestToken,
      'X-Correlation-ID': correlationId
    }
  });
  switch (response.status) {
    case 200:
      return response.json();
    default:
      throw new UnexpectedServerResponse(response);
  }
}

async function kalpCheck(
  purchaseRequestToken,
  correlationId,
  country,
  fetch = window.fetch
) {
  const response = await fetch(globals.kalpCheck, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Purchase-Request-Token': purchaseRequestToken,
      'X-Correlation-ID': correlationId
    },
    body: JSON.stringify({
      countryCode: country
    })
  });

  switch (response.status) {
    case 200:
      return { status: 'OK' };
    case 204:
    case 404:
      return { status: 'NOT FOUND' };
    default:
      throw new UnexpectedServerResponse(response);
  }
}

async function kalpCreate(
  purchaseRequestToken,
  correlationId,
  countryCode,
  questionnaireVersion,
  values
) {
  const response = await fetch(globals.kalpCreate, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Purchase-Request-Token': purchaseRequestToken,
      'X-Correlation-ID': correlationId
    },
    body: JSON.stringify({
      values,
      questionnaireVersion,
      countryCode
    })
  });

  switch (response.status) {
    case 200:
      return { status: 'OK' };
    case 400:
    case 404:
    case 405:
      return { status: 'ERROR' };
    default:
      throw new UnexpectedServerResponse(response);
  }
}

export default {
  prepare,
  authorize,
  abort,
  customerContactInfo,
  kalpCheck,
  getKalpQuestions,
  kalpCreate,
  getKycQuestions,
  kycCheck,
  kycCreate
};
