import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ExpressiveButton } from '../styled-components/styledcomponents';
import { startSign } from '../actions/applicationActions';
import { clickedSignButtonEvent } from '../actions/eventActions';
import AuthenticationErrorBox, {
  errorBoxTypes
} from './AuthenticationErrorBox';

const StrongSign = () => {
  const [signButtonEnabled, setSignButtonEnabled] = useState(true);
  const {
    isSigning,
    isSigningError,
    isUnauthorized,
    isSigningAlreadyInProgressError
  } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { country, redirectUrl } = useSelector((state) => state.app);
  const [windowRef, setWindowRef] = useState(null);

  const initWindowReference = () => {
    setWindowRef(window.open());
  };

  const onClickSign = () => {
    setSignButtonEnabled(false);
    // We do not know if redirect url exists yet, so using country to determine
    // if new window shall be opened.
    if (country !== 'SE') {
      initWindowReference();
    }

    dispatch(clickedSignButtonEvent());
    dispatch(startSign());
  };
  useEffect(() => {
    // Now we have redirect url, set it in new window
    if (windowRef && redirectUrl) {
      windowRef.location = redirectUrl;
    }
  }, [redirectUrl, windowRef]);

  useEffect(() => {
    // Close window if signing flow ends in some way (success or fail)
    if (windowRef && !isSigning) {
      setSignButtonEnabled(true);
      windowRef.close();
    }
    if (country === 'SE' && !isSigning) {
      setSignButtonEnabled(true);
    }
  }, [isSigning]);

  return (
    <AuthenticationWrapper data-cy="AuthenticationWrapper">
      <ExpressiveButton
        data-cy="SigningPurchaseButton"
        onClick={onClickSign}
        disabled={!signButtonEnabled}
      >
        {t('pay_in_parts.pay_strong_sign_button_label')}
      </ExpressiveButton>
      {isSigningAlreadyInProgressError && (
        <AuthenticationErrorBox
          errorText={t('authentication.error_bank_id_in_use')}
          type={errorBoxTypes.button}
        />
      )}
      {isSigningError ||
        (isUnauthorized && (
          <AuthenticationErrorBox
            errorText={t('signing.general_error')}
            type={errorBoxTypes.button}
          />
        ))}
    </AuthenticationWrapper>
  );
};

export default StrongSign;

const AuthenticationWrapper = styled.div`
  width: 100%;
`;
