import React from 'react';
import { useSelector } from 'react-redux';
import { spacing } from '@teliads/components/foundations';
import PartPaymentSummary from './PartPaymentSummary';
import CreditLimitInformation from './CreditLimitInformation';
import {
  CancelButtonContainer,
  Line
} from '../styled-components/styledcomponents';
import {
  selectCreditGranted,
  selectCreditGrantedForPayTheDifference
} from '../selectors/selectors';
import PayTheDifference from './PayTheDifference';
import StrongSign from './StrongSign';
import PurchaseButton from './PurchaseButton';
import FLOWS from '../constants/flows';
import CancelButton from './CancelButton';
import TextSummaryOfAgreements from './TextSummaryOfAgreements';

const PrepareCompleted = () => {
  const {
    selectedPaymentMethodOption: selectedOption,
    flow,
    strongSignRequired
  } = useSelector((state) => state.app);

  const { account: options } = useSelector((state) => state.app.paymentMethods);

  const creditGranted = useSelector(selectCreditGranted);
  const creditGrantedForPayTheDifference = useSelector(
    selectCreditGrantedForPayTheDifference
  );

  return (
    <>
      <PartPaymentSummary
        optionData={options[selectedOption]}
        creditGrantedForPayTheDifference={creditGrantedForPayTheDifference}
      />
      <CreditLimitInformation />
      {creditGrantedForPayTheDifference && <PayTheDifference />}
      {creditGranted && (
        <>
          <Line
            marginTop={spacing.spacing20}
            marginBottom={spacing.spacing20}
          />
          <TextSummaryOfAgreements />

          {strongSignRequired ? <StrongSign /> : <PurchaseButton />}

          {flow !== FLOWS.WEB && (
            <CancelButtonContainer>
              <CancelButton />
            </CancelButtonContainer>
          )}
        </>
      )}
    </>
  );
};

export default PrepareCompleted;
