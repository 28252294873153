import {
  CONFIG_COMPLETE,
  AUTHORIZE,
  AUTHORIZE_STARTED,
  AUTHORIZE_FAILED,
  AUTHORIZE_COMPLETE,
  PREPARE,
  PREPARE_STARTED,
  PREPARE_FAILED,
  PREPARE_COMPLETE,
  SET_SELECTED_PAYMENT_METHOD,
  SET_SELECTED_PAYMENT_METHOD_OPTION,
  START_AUTH,
  SET_AUTH_REQUIRED,
  AUTHENTICATION_STARTED,
  AUTHENTICATION_CANCELED,
  AUTHENTICATION_ALREADY_IN_PROGRESS,
  AUTHENTICATION_FAILED,
  AUTHENTICATION_DONE,
  CANCEL_AUTHENTICATION,
  CANCEL_PAYMENT,
  SET_REDIRECT_URL,
  SIGNING_STARTED,
  SIGNING_CANCELED,
  SIGNING_ALREADY_IN_PROGRESS,
  SIGNING_FAILED,
  SIGNING_DONE,
  CANCEL_SIGNING,
  START_SIGN,
  SIGNING_TECHNICAL_ERROR,
  CARD_AUTHORIZE_AND_CAPTURE,
  USER_INFO_COLLECTION_STARTED,
  USER_INFO_COLLECTION_COMPLETED,
  USER_INFO_COLLECTION_CONTINUE_CLICKED,
  USER_INFO_COLLECTION_EMAIL,
  USER_INFO_COLLECTION_PHONE,
  USER_INFO_COLLECTION_API_ERROR,
  USER_INFO_COLLECTION_CANCELLED_CLICKED,
  AUTHORIZE_UNAUTHORIZED,
  MONTHIO_CANCEL,
  MONTHIO_FLOW_COMPLETED_ERROR,
  MONTHIO_FLOW_COMPLETED_DONE,
  MONTHIO_POLL_ONCE,
  MONTHIO_POLL_ONCE_DONE,
  IS_MONTHIO_FLOW,
  MONTHIO_CREATE_DONE,
  MONTHIO_CREATE_ERROR,
  MONTHIO_POLL_ERROR,
  MONTHIO_OPEN_AGAIN,
  SBID_QR,
  SBID_AUTO_START_TOKEN,
  CANCEL_SBID_AUTH_OR_SIGN,
  QUESTIONS_POST,
  QUESTIONS_VIEW_TOGGLE,
  QUESTIONS_CHECK,
  KYC_FLOW_DONE,
  SET_AUTH_TOKEN,
  SET_KYC_QUESTIONS,
  SET_KYC_VERSION,
  RISK_ASSESSMENT_COMPLETE,
  ACCOUNT_LOOKUP_COMPLETE,
  ACCOUNT_LOOKUP_STARTED,
  RISK_ASSESSMENT_STARTED,
  TECHNICAL_ERROR,
  SET_KALP_QUESTIONS,
  SET_KALP_VERSION,
  KALP_FLOW_DONE,
  SET_QUESTIONS_TO_DISPLAY,
  IS_LOADING,
  CONFIG_HIGH_LOAD,
  CONFIG_CIRCUIT_BREAKER,
  SET_LANGUAGE,
  CONFIG_RETRY
} from '../constants/actions';
import RENDER_PAYMENT_OPTIONS from '../constants/renderPaymentOptions';

const instantiatePaymentOptions = (
  availableMonths,
  totalCost,
  paperFee,
  currency
) =>
  availableMonths.map((months) => ({
    downPayTime: months,
    totalCost,
    currency,
    monthlyCost: totalCost / months,
    startupFee: 0,
    adminFee: 0,
    billingFeePaper: paperFee,
    interest: 0,
    effectiveInterest: 0
  }));

export function configHighLoad(trafficFlattenerWaitTimeInSeconds) {
  return {
    type: CONFIG_HIGH_LOAD,
    trafficFlattenerWaitTimeInSeconds
  };
}

export function configCircuitBreaker(timeout) {
  return {
    type: CONFIG_CIRCUIT_BREAKER,
    timeout
  };
}

export function setLanguage(language) {
  return {
    type: SET_LANGUAGE,
    language
  };
}

export function retryConfig() {
  return {
    type: CONFIG_RETRY
  };
}

export function configComplete(configuration, preSelectedMonth) {
  const {
    locale,
    flow,
    order,
    paymentOptions,
    lockedMonth,
    partPaymentMinimumAmount,
    storeName,
    strongSignRequired,
    emailPhoneCollectionEnabled,
    ssn,
    email,
    mobile,
    merchantId,
    cardPaymentEnabled,
    kalpEnabled,
    kycEnabled,
    uiConfig = {
      headerEnabled: true,
      renderPaymentOption: RENDER_PAYMENT_OPTIONS.ALL
    }
  } = configuration;

  const amount = order.items.reduce((acc, item) => acc + item.amount, 0);

  const renderCardPayments =
    uiConfig.renderPaymentOption !== RENDER_PAYMENT_OPTIONS.PART_PAYMENT;
  const renderPartPayments =
    uiConfig.renderPaymentOption !== RENDER_PAYMENT_OPTIONS.CARD;

  let selectedPaymentMethodOption = -1;

  const isValidPreSelectedMonth =
    paymentOptions.availableMonths.includes(preSelectedMonth);

  if (isValidPreSelectedMonth) {
    selectedPaymentMethodOption = paymentOptions.availableMonths.indexOf(
      lockedMonth || preSelectedMonth || paymentOptions.defaultMonths
    );
  } else {
    selectedPaymentMethodOption = paymentOptions.availableMonths.indexOf(
      lockedMonth || paymentOptions.defaultMonths
    );
  }

  if (selectedPaymentMethodOption === -1) {
    // Invalid default setting. Fallback to first option instead.
    selectedPaymentMethodOption = 0;
  }

  const paymentMethods = {};

  if (cardPaymentEnabled && renderCardPayments) {
    const cardPayment = {
      cardPayment: {
        amount,
        paperInvoiceFee: order.paperInvoiceFee,
        currency: locale.currency
      }
    };

    Object.assign(paymentMethods, cardPayment);
  }

  if (renderPartPayments) {
    const partPayment = {
      account: instantiatePaymentOptions(
        paymentOptions.availableMonths,
        amount,
        order.paperInvoiceFee,
        locale.currency
      )
    };

    Object.assign(paymentMethods, partPayment);
  }
  const selectedPaymentMethod =
    renderCardPayments && !renderPartPayments ? 'card' : 'account';

  return {
    type: CONFIG_COMPLETE,
    locale,
    flow,
    paymentMethods,
    selectedPaymentMethod,
    selectedPaymentMethodOption,
    isLockedMonth: !!lockedMonth,
    partPaymentMinimumAmount,
    storeName,
    strongSignRequired,
    emailPhoneCollectionEnabled,
    ssn,
    email,
    mobile,
    merchantId,
    kalpEnabled,
    kycEnabled,
    uiConfig
  };
}

export function authorize() {
  return {
    type: AUTHORIZE
  };
}

export function authorizeStarted() {
  return {
    type: AUTHORIZE_STARTED
  };
}

export function authorizeIsUnauthorized() {
  return {
    type: AUTHORIZE_UNAUTHORIZED
  };
}

export function authorizeFailed() {
  return {
    type: AUTHORIZE_FAILED
  };
}

export function authorizeComplete(customerDetails) {
  return {
    type: AUTHORIZE_COMPLETE,
    customerDetails
  };
}

export function monthioCreateDone(isMonthioCreateDone) {
  return {
    type: MONTHIO_CREATE_DONE,
    isMonthioCreateDone
  };
}

export function setIsMonthioFlow() {
  return {
    type: IS_MONTHIO_FLOW
  };
}

export function monthioFlowCompletedDone() {
  return {
    type: MONTHIO_FLOW_COMPLETED_DONE
  };
}

export function monthioFlowCompletedError() {
  return {
    type: MONTHIO_FLOW_COMPLETED_ERROR
  };
}

export function monthioCreateError() {
  return {
    type: MONTHIO_CREATE_ERROR
  };
}

export function monthioPollError() {
  return {
    type: MONTHIO_POLL_ERROR
  };
}

export function monthioCancel() {
  return {
    type: MONTHIO_CANCEL
  };
}

export function monthioPollOnce() {
  return {
    type: MONTHIO_POLL_ONCE
  };
}

export function monthioPollOnceDone() {
  return {
    type: MONTHIO_POLL_ONCE_DONE
  };
}

export function monthioOpenAgain() {
  return {
    type: MONTHIO_OPEN_AGAIN
  };
}

export function prepare() {
  return {
    type: PREPARE
  };
}

export function prepareStarted() {
  return {
    type: PREPARE_STARTED
  };
}

export function prepareFailed() {
  return {
    type: PREPARE_FAILED
  };
}

export function prepareComplete(customerState) {
  return {
    type: PREPARE_COMPLETE,
    customerState
  };
}

export function accountLookupStarted() {
  return {
    type: ACCOUNT_LOOKUP_STARTED
  };
}

export function accountLookupComplete(
  customerType,
  accountLookupStatus,
  completeWithoutRiskAssessment = false
) {
  return {
    type: ACCOUNT_LOOKUP_COMPLETE,
    customerType,
    accountLookupStatus,
    completeWithoutRiskAssessment
  };
}

export function riskAssessmentStarted() {
  return {
    type: RISK_ASSESSMENT_STARTED
  };
}

export function riskAssessmentComplete(
  riskAssessmentStatus,
  financeableAmount,
  maximumLimit,
  approvedLimit,
  requiredLimit,
  offLimitAmountToPay,
  hasCreditLimitChanged,
  creditLimit
) {
  return {
    type: RISK_ASSESSMENT_COMPLETE,
    riskAssessmentStatus,
    financeableAmount,
    maximumLimit,
    approvedLimit,
    requiredLimit,
    offLimitAmountToPay,
    hasCreditLimitChanged,
    creditLimit
  };
}

export function riskAssessmentFailed() {
  return {
    type: RISK_ASSESSMENT_COMPLETE
  };
}

export function startAuth() {
  return {
    type: START_AUTH
  };
}

export function startSign() {
  return {
    type: START_SIGN
  };
}

export function setAuthRequired(ssn) {
  return {
    type: SET_AUTH_REQUIRED,
    ssn
  };
}

export function authenticationStarted() {
  return {
    type: AUTHENTICATION_STARTED
  };
}

export function authenticationSignQR(qr) {
  return {
    type: SBID_QR,
    qr
  };
}

export function authenticationSignAutoStartToken(autoStartToken, isSignFlow) {
  return {
    type: SBID_AUTO_START_TOKEN,
    autoStartToken,
    isSignFlow
  };
}

export function authenticationCanceled() {
  return {
    type: AUTHENTICATION_CANCELED
  };
}

export function authenticationAlreadyInProgress() {
  return {
    type: AUTHENTICATION_ALREADY_IN_PROGRESS
  };
}

export function authenticationFailed() {
  return {
    type: AUTHENTICATION_FAILED
  };
}

export function authenticationDone() {
  return {
    type: AUTHENTICATION_DONE
  };
}

export function cancelSbidAuthenticationOrSigning() {
  return {
    type: CANCEL_SBID_AUTH_OR_SIGN
  };
}

export function cancelAuthentication() {
  return {
    type: CANCEL_AUTHENTICATION
  };
}

export function signingStarted() {
  return {
    type: SIGNING_STARTED
  };
}

export function signingCanceled() {
  return {
    type: SIGNING_CANCELED
  };
}

export function signingAlreadyInProgress() {
  return {
    type: SIGNING_ALREADY_IN_PROGRESS
  };
}

export function signingFailed() {
  return {
    type: SIGNING_FAILED
  };
}

export function signingTechnicalError() {
  return {
    type: SIGNING_TECHNICAL_ERROR
  };
}

export function signingDone(signToken) {
  return {
    type: SIGNING_DONE,
    signToken
  };
}

export function cancelSigning() {
  return {
    type: CANCEL_SIGNING
  };
}

export function setSelectedPaymentMethod(paymentMethod) {
  return {
    type: SET_SELECTED_PAYMENT_METHOD,
    paymentMethod
  };
}

export function setSelectedPaymentMethodOption(option) {
  return {
    type: SET_SELECTED_PAYMENT_METHOD_OPTION,
    option
  };
}

export function cancelPayment() {
  return {
    type: CANCEL_PAYMENT
  };
}

export function setRedirectUrl(url) {
  return { type: SET_REDIRECT_URL, value: url };
}

export function cardAuthorizeAndCapture() {
  return {
    type: CARD_AUTHORIZE_AND_CAPTURE
  };
}

export function userInfoCollectionStart() {
  return {
    type: USER_INFO_COLLECTION_STARTED
  };
}

export function userInfoCollectionContinueClicked() {
  return {
    type: USER_INFO_COLLECTION_CONTINUE_CLICKED
  };
}
export function userInfoCollectionCancelClicked() {
  return {
    type: USER_INFO_COLLECTION_CANCELLED_CLICKED
  };
}
export function userInfoCollectionEmail(email) {
  return { type: USER_INFO_COLLECTION_EMAIL, email };
}

export function userInfoCollectionPhone(mobile) {
  return { type: USER_INFO_COLLECTION_PHONE, mobile };
}

export function userInfoCollectionComplete() {
  return {
    type: USER_INFO_COLLECTION_COMPLETED
  };
}

export function userInfoCollectionEApiError(userCollectionApiError) {
  return {
    type: USER_INFO_COLLECTION_API_ERROR,
    userCollectionApiError
  };
}

export function questionsCheck() {
  return {
    type: QUESTIONS_CHECK
  };
}

export function questionsPost(values) {
  return {
    type: QUESTIONS_POST,
    values
  };
}

export function setIsLoading(isLoading) {
  return {
    type: IS_LOADING,
    isLoading
  };
}

export function kycFlowToggle(flowDone) {
  return {
    type: KYC_FLOW_DONE,
    value: flowDone
  };
}

export function setKycQuestions(questions) {
  return {
    type: SET_KYC_QUESTIONS,
    value: questions
  };
}

export function setKycVersion(version) {
  return {
    type: SET_KYC_VERSION,
    value: version
  };
}

export function questionsViewToggle(viewActive) {
  return {
    type: QUESTIONS_VIEW_TOGGLE,
    value: viewActive
  };
}

export function kalpFlowToggle(flowDone) {
  return {
    type: KALP_FLOW_DONE,
    value: flowDone
  };
}

export function setKalpQuestions(questions) {
  return {
    type: SET_KALP_QUESTIONS,
    value: questions
  };
}

export function setKalpVersion(version) {
  return {
    type: SET_KALP_VERSION,
    value: version
  };
}

export function setQuestionsToDisplay(questions) {
  return {
    type: SET_QUESTIONS_TO_DISPLAY,
    value: questions
  };
}

export function setAuthToken(authToken) {
  return {
    type: SET_AUTH_TOKEN,
    value: authToken
  };
}

export function technicalError() {
  return {
    type: TECHNICAL_ERROR
  };
}
