import globals from './globals';
import UnexpectedServerResponse from './UnexpectedServerResponse';
import { SIGNING_ALREADY_IN_PROGRESS } from '../constants/actions';

async function start(
  purchaseRequestToken,
  correlationId,
  fetch = window.fetch
) {
  const response = await fetch(`${globals.signStart}`, {
    method: 'GET',
    headers: {
      'X-Correlation-ID': correlationId,
      'Purchase-Request-Token': purchaseRequestToken
    }
  });

  switch (response.status) {
    case 200: {
      const { redirectUrl, token: pollingToken } = await response.json();
      return { redirectUrl, pollingToken };
    }
    case 400:
      throw new UnexpectedServerResponse(response, 'Received no token');
    case 409:
      return { status: SIGNING_ALREADY_IN_PROGRESS };
    default:
      throw new UnexpectedServerResponse(response);
  }
}

async function poll(
  pollingToken,
  correlationId,
  purchaseRequestToken = null, // Only needed for logs
  fetch = window.fetch
) {
  const query = new URLSearchParams({ token: pollingToken });
  const response = await fetch(`${globals.signPoll}?${query}`, {
    headers: {
      'X-Correlation-ID': correlationId,
      'Purchase-Request-Token': purchaseRequestToken
    }
  });
  switch (response.status) {
    case 200: {
      const { signJwt: signToken } = await response.json();
      if (!signToken) {
        throw new UnexpectedServerResponse(response, 'Received no auth JWT');
      }
      return { status: 'DONE', signToken };
    }
    case 202:
      return { status: 'ONGOING' };
    case 400:
    case 404:
    case 410:
      return { status: 'EXPIRED' };
    default:
      throw new UnexpectedServerResponse(response);
  }
}

async function cancel(
  pollingToken,
  correlationId,
  purchaseRequestToken = null, // Only needed for logs
  fetch = window.fetch
) {
  const query = new URLSearchParams({ token: pollingToken });
  const response = await fetch(`${globals.signCancel}?${query}`, {
    method: 'GET',
    headers: {
      'X-Correlation-ID': correlationId,
      'Purchase-Request-Token': purchaseRequestToken
    }
  });
  if (response.status === 204) {
    return;
  }
  throw new UnexpectedServerResponse(response);
}

export default { start, poll, cancel };
