export const getCssVariable = (varName) => {
  return getComputedStyle(document.documentElement).getPropertyValue(varName);
};

export const getCssVariableOrDeprecatedVariableIfNotSet = (
  variableName,
  deprecatedVariableName
) => {
  return getCssVariable(variableName) || getCssVariable(deprecatedVariableName);
};
