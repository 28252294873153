import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Loading = () => {
  return (
    <LoadingWrapper data-cy="Loading">
      <Circle />
      <Circle />
      <Circle />
    </LoadingWrapper>
  );
};

Loading.propTypes = {
  color: PropTypes.string
};

Loading.defaultProps = {
  color: null
};

export default Loading;

const duration = 1.2;
const Circle = styled.div.attrs({ className: 'Circle' })`
  width: 24%;
  margin-top: 16%;
  background-color: ${({ theme }) => theme.color.primary};
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: bounce ${`${duration}s`} infinite ease-in-out both;
  animation: bounce ${`${duration}s`} infinite ease-in-out both;

  &:nth-child(1) {
    -webkit-animation-delay: ${`-${duration * 0.3}s`};
    animation-delay: ${`-${duration * 0.3}s`};
  }

  &:nth-child(2) {
    -webkit-animation-delay: ${`-${duration * 0.15}s`};
    animation-delay: ${`-${duration * 0.15}s`};
  }

  &:after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
`;

const LoadingWrapper = styled.div.attrs({ className: 'LoadingWrapper' })`
  width: 100%;
  display: flex;
  justify-content: space-between;
  @-webkit-keyframes bounce {
    0%,
    60%,
    100% {
      -webkit-transform: translateY(0px);
    }
    30% {
      -webkit-transform: translateY(-62.5%);
    }
  }

  @keyframes bounce {
    0%,
    60%,
    100% {
      -webkit-transform: translateY(0px);
      transform: translateY(0px);
    }
    30% {
      -webkit-transform: translateY(-62.5%);
      transform: translateY(-62.5%);
    }
  }
`;
