const riskAssessmentStatusType = {
  MAXIMUM_AMOUNT_EXCEEDED: 'MAXIMUM_AMOUNT_EXCEEDED',
  CREATE_ACCOUNT_NEEDED: 'CREATE_ACCOUNT_NEEDED',
  UPDATE_ACCOUNT_NEEDED: 'UPDATE_ACCOUNT_NEEDED',
  PAY_THE_DIFFERENCE_CREATE_ACCOUNT_NEEDED:
    'PAY_THE_DIFFERENCE_CREATE_ACCOUNT_NEEDED',
  PAY_THE_DIFFERENCE_UPDATE_ACCOUNT_NEEDED:
    'PAY_THE_DIFFERENCE_UPDATE_ACCOUNT_NEEDED',
  ACCOUNT_BLOCKED: 'ACCOUNT_BLOCKED',
  UNPAID_INVOICES: 'UNPAID_INVOICES',
  CREDIT_CHECK_REJECTED: 'CREDIT_CHECK_REJECTED',
  CREDIT_LIMIT_TOO_LOW: 'CREDIT_LIMIT_TOO_LOW'
};

export default riskAssessmentStatusType;
