import React, { useState, useEffect, useRef } from 'react';
import QRCode from 'qrcode';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

const AuthenticatingQR = () => {
  const { qr } = useSelector((state) => state.app);
  const [child, setChild] = useState(null);
  const ref = useRef();
  useEffect(() => {
    if (qr) {
      const opts = {
        errorCorrectionLevel: 'H'
      };

      QRCode.toCanvas(qr, opts, (err, canvas) => {
        if (err) throw err;

        if (canvas && ref.current) {
          if (ref.current.hasChildNodes()) {
            ref.current.removeChild(child);
          }
          setChild(ref.current.appendChild(canvas));
        }
      });
    }
  }, [qr]);

  return (
    <>
      {qr && (
        <QRWrapper>
          <QRviewer data-cy="QRViewer" ref={ref} />
        </QRWrapper>
      )}
    </>
  );
};

const QRviewer = styled.div.attrs({ className: 'QRviewer' })`
  width: 260px;
  height: 260px;
`;

const QRWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 260px;
`;

export default AuthenticatingQR;
