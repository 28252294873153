import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Heading, Icon } from '@purpurds/purpur';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import PoweredByTeliaFinance from '../PoweredByTeliaFinance';
import { ExpressiveButton } from '../../styled-components/styledcomponents';
import { retryConfig } from '../../actions/applicationActions';
import DecimalFormatAmountWithPostFix from './DecimalFormatAmountWithPostFix';

const CircuitBreakerView = () => {
  const { configCircuitBreaker, tryAgainTimeout } = useSelector(
    (state) => state.app
  );
  const { t } = useTranslation();
  const [isDisabled, setIsDisabled] = useState(true);
  const [countdown, setCountdown] = useState(180000);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!Number.isNaN(tryAgainTimeout)) {
      setCountdown(tryAgainTimeout);
      setTimeout(() => {
        setIsDisabled(true);
      }, tryAgainTimeout);
    }
  }, [tryAgainTimeout]);

  useEffect(() => {
    let interval;
    if (countdown >= 0) {
      const decreaseAmount = 1000;
      interval = setInterval(() => {
        setCountdown(countdown - decreaseAmount);
      }, decreaseAmount);
    } else if (countdown) {
      setIsDisabled(false);
      setCountdown(0);
    }
    return () => clearInterval(interval);
  }, [countdown]);

  const clickedRetry = useCallback(() => {
    dispatch(retryConfig());
  }, []);

  if (configCircuitBreaker) {
    return (
      <>
        <Container data-cy="CircuitBreakerView">
          <IconContainer>
            <Icon
              size="xl"
              svg={{
                category: 'other',
                keywords: ['error'],
                name: 'error',
                svg: '<svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path fill-rule="evenodd" d="M10.354 3.05c.732-1.267 2.56-1.267 3.291 0l8.66 15c.732 1.267-.182 2.85-1.645 2.85H3.34c-1.463 0-2.377-1.583-1.646-2.85l8.66-15Zm1.595.862a.092.092 0 0 0-.036.038l-8.66 15a.092.092 0 0 0-.015.05c0 .014.005.032.015.05a.11.11 0 0 0 .036.038.09.09 0 0 0 .05.012H20.66a.088.088 0 0 0 .05-.012.106.106 0 0 0 .037-.038.105.105 0 0 0 .015-.05.092.092 0 0 0-.015-.05l-8.66-15a.092.092 0 0 0-.036-.038A.106.106 0 0 0 12 3.9a.105.105 0 0 0-.05.012Z" clip-rule="evenodd"></path><path d="m12.523 14.922.412-6.25a.936.936 0 1 0-1.868 0l.421 6.25h1.035ZM12 17.52c.44 0 .826-.387.826-.815 0-.418-.387-.816-.826-.816-.428 0-.825.398-.825.816 0 .428.397.815.825.815Z"></path><path fill-rule="evenodd" d="M12.691 15.096h-1.37l-.431-6.53a1 1 0 0 1 .997-1.066h.227a1 1 0 0 1 .998 1.064l-.42 6.532Zm.244-6.425a.936.936 0 1 0-1.868.002l.421 6.249h1.035l.412-6.25ZM13 16.706c0 .526-.466.99-1 .99-.522 0-1-.463-1-.99 0-.264.125-.51.304-.689.18-.178.429-.301.696-.301.536 0 1 .475 1 .99Zm-.174 0c0 .428-.387.815-.826.815-.428 0-.825-.387-.825-.815 0-.418.397-.816.825-.816.44 0 .826.398.826.816Z" clip-rule="evenodd"></path></svg>'
              }}
            />
          </IconContainer>
          <Heading tag="h2" variant="title-200">
            {t('circuit_breaker.title', {
              defaultValue: 'An error has occurred'
            })}
          </Heading>
          <BodyText>
            {t('circuit_breaker.body', {
              defaultValue:
                'We’re experiencing technical difficulties. Please try again later.'
            })}
          </BodyText>
          <Heading tag="h3" variant="title-200">
            <DecimalFormatAmountWithPostFix amount={countdown} />
          </Heading>
          <ExpressiveButton
            disabled={isDisabled}
            onClick={clickedRetry}
            data-cy="CircuitBreakerTryAgainButton"
          >
            {t('circuit_breaker.button_try_again', {
              defaultValue: 'Try again'
            })}
          </ExpressiveButton>
        </Container>
        <PoweredByContainer>
          <PoweredByTeliaFinance />
        </PoweredByContainer>
      </>
    );
  }
  return <></>;
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  max-width: ${(p) => p.theme.width.innerMax};
  margin: var(--purpur-spacing-1600) var(--purpur-spacing-200)
    var(--purpur-spacing-1600) var(--purpur-spacing-200);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
`;

const IconContainer = styled.div`
  margin: var(--purpur-spacing-250) 0 var(--purpur-spacing-200) 0;
`;

const BodyText = styled.p.attrs({
  className: 'TitleLoading'
})`
  margin: var(--purpur-spacing-250) 0 var(--purpur-spacing-250) 0;
  font-size: ${(p) => p.theme.fontSize.base};
  line-height: var(--purpur-spacing-250);
  text-align: center;
  font-weight: 400;
  color: var(--purpur-color-gray-600);
`;

const PoweredByContainer = styled.div`
  padding: 0 0 var(--purpur-spacing-200) 0;
`;

export default CircuitBreakerView;
