import globals from '../globals';
import UnexpectedServerResponse from '../UnexpectedServerResponse';

export const sbidCancelStatus = {
  CANCELLED: 'CANCELLED',
  MISSING_AUTHENTICATION_REQUEST_TOKEN: 'MISSING_AUTHENTICATION_REQUEST_TOKEN',
  EXPIRED: 'EXPIRED',
  SERVICE_UNAVAILABLE: 'SERVICE_UNAVAILABLE'
};

const sbidCancel = async (
  token,
  correlationId,
  purchaseRequestToken = null, // Only needed for logs
  fetch = window.fetch
) => {
  const query = new URLSearchParams({ token });
  const response = await fetch(`${globals.authSbidCancel}?${query}`, {
    method: 'GET',
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Correlation-ID': correlationId,
      'Purchase-Request-Token': purchaseRequestToken
    }
  });

  switch (response.status) {
    case 204: {
      return { status: sbidCancelStatus.CANCELLED };
    }
    case 400: {
      return { status: sbidCancelStatus.MISSING_AUTHENTICATION_REQUEST_TOKEN };
    }
    case 404:
      return { status: sbidCancelStatus.EXPIRED };
    case 503:
      return { status: sbidCancelStatus.SERVICE_UNAVAILABLE };
    default:
      throw new UnexpectedServerResponse(response);
  }
};

export default sbidCancel;
