import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectPaymentDetailsCardPaymentOption } from '../../selectors/selectors';
import { webcheckoutCompleteSuccessEvent } from '../../actions/eventActions';

const ThankYouCardPayment = () => {
  const paymentDetails = useSelector(selectPaymentDetailsCardPaymentOption);
  const { customerDetails } = useSelector((state) => state.app);
  const { customerState } = useSelector((state) => state.partPayment);

  const dispatch = useDispatch();

  useEffect(() => {
    const amountToPay = customerState?.amountToPay;
    const financeableAmount = customerState?.financeableAmount;
    dispatch(
      webcheckoutCompleteSuccessEvent(
        paymentDetails,
        amountToPay,
        financeableAmount,
        customerDetails,
        true
      )
    );
  }, []);

  return <></>;
};

export default ThankYouCardPayment;
