const accountLookupStatusType = {
  CREDIT_CHECK_NEEDED: 'CREDIT_CHECK_NEEDED',
  NO_ACTION_NEEDED: 'NO_ACTION_NEEDED',
  ORDER_AMOUNT_TOO_LOW: 'ORDER_AMOUNT_TOO_LOW',
  ACCOUNT_BLOCKED: 'ACCOUNT_BLOCKED',
  CREDIT_CHECK_REJECTED: 'CREDIT_CHECK_REJECTED',
  GENERIC_DECLINE: 'GENERIC_DECLINE'
};

export default accountLookupStatusType;
