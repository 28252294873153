import React from 'react';
import PropTypes from 'prop-types';
import { Column } from '../styled-components/styledcomponents';
import PrepareNotCompleted from './PrepareNotCompleted';
import PrepareCompleted from './PrepareCompleted';
import MonthOverview from './MonthOverview';

const SdkView = ({ isPrepareComplete }) => {
  return (
    <Column>
      <MonthOverview />
      {!isPrepareComplete ? <PrepareNotCompleted /> : <PrepareCompleted />}
    </Column>
  );
};

SdkView.propTypes = {
  isPrepareComplete: PropTypes.bool.isRequired
};

export default SdkView;
