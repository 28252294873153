import React from 'react';
import styled from 'styled-components';
import { spacing, typography } from '@teliads/components/foundations';
import AdyenLogo from '../graphics/AdyenLogo';

const PoweredByAdyen = () => {
  return (
    <Wrapper>
      <PoweredBy>Powered by</PoweredBy>
      <AdyenLogo />
    </Wrapper>
  );
};
export default PoweredByAdyen;

const Wrapper = styled.div.attrs({
  className: 'PoweredByAdyenWrapper'
})`
  margin: ${spacing.spacing24} 0 ${spacing.spacing16} 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PoweredBy = styled.span.attrs({
  className: 'PoweredBy'
})`
  font-size: ${(p) => p.theme.fontSize.xsmall};
  line-height: 20px;
  margin: 0 0 ${spacing.spacing4} 0;
  font-weight: ${typography.weightBold};
  color: #a0a0a0;
  text-align: center;
`;
