function getBaseUrl() {
  return `/payment/monthio`;
}

function setHeaders(purchaseRequestToken, correlationId) {
  return {
    'Purchase-Request-Token': purchaseRequestToken,
    'X-Correlation-ID': correlationId,
    'Content-Type': 'application/json'
  };
}
export default { getBaseUrl, setHeaders };
