import monthioApiHelpers from './monthioApiHelpers';

const monthioCreate = async (
  {
    purchaseRequestToken,
    correlationId,
    ssn,
    downPayTime,
    monthlyCost,
    totalCost
  },
  fetch = window.fetch
) => {
  try {
    const response = await fetch(
      `${monthioApiHelpers.getBaseUrl()}/create-case`,
      {
        method: 'POST',
        headers: monthioApiHelpers.setHeaders(
          purchaseRequestToken,
          correlationId
        ),
        body: JSON.stringify({
          consumerId: purchaseRequestToken,
          consumerCpr: ssn,
          clientInputs: {
            futureLoanPeriod: downPayTime,
            futureMonthlyInstallment: Math.round(monthlyCost),
            futurePrincipalAmount: totalCost
          }
        })
      }
    );

    switch (response.status) {
      case 200: {
        return { status: 'OK', response: await response.json() };
      }
      case 404:
        return { status: 'EXPIRED' };
      default:
        return { status: 'ERR' };
    }
  } catch (error) {
    return null;
  }
};

export default monthioCreate;
