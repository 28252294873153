import React from 'react';
import styled from 'styled-components';
import { spacing, typography } from '@teliads/components/foundations';
import TeliaFinanceLogo from './graphics/TeliaFinanceLogo';

const PoweredByTeliaFinance = () => {
  return (
    <PoweredByTeliaFinanceWrapper>
      <PoweredBy>Powered by</PoweredBy>
      <TeliaFinanceLogo />
    </PoweredByTeliaFinanceWrapper>
  );
};
export default PoweredByTeliaFinance;

const PoweredByTeliaFinanceWrapper = styled.div.attrs({
  className: 'PoweredByTeliaPayWrapper'
})`
  margin: ${spacing.spacing24} 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PoweredBy = styled.span.attrs({
  className: 'PoweredBy'
})`
  font-size: ${(p) => p.theme.fontSize.xsmall};
  line-height: 20px;
  font-weight: ${typography.weightBold};
  color: #a0a0a0;
  text-align: center;
`;
