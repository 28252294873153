import { snakeCase } from 'lodash';

/**
 * Append merchantId to key with current Phrase formatting standard
 *
 * @param key
 * @param merchantId
 * @returns key.{phrase_merchant_id}
 */
const usePhraseFormattedKeyFromMerchantId = (key, merchantId) => {
  return `${key}.${snakeCase(merchantId)}`;
};

export default usePhraseFormattedKeyFromMerchantId;
