import { useSelector } from 'react-redux';

export const decimalFormatAmount = (amount) => {
  const { language } = useSelector((state) => state.app);
  if (amount != null) {
    return (amount / 100).toLocaleString(language, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  }
  return '';
};

export const formatTimeInMinutesAndSeconds = (amount) => {
  const { language } = useSelector((state) => state.app);
  const languageWithFallback = language || 'sv-SE';
  if (amount != null) {
    return new Date(amount)
      .toLocaleTimeString(languageWithFallback, { hour12: false })
      .substring(3);
  }
  return '';
};

export const integerFormatAmount = (amount) => {
  return (amount / 100).toLocaleString('sv-SE', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });
};

export const autoFormatAmount = (amount) =>
  decimalFormatAmount(amount).replace(/.00$/, '');

export const currencyAddStkIfDK = (value) => {
  const { country } = useSelector((state) => state.app);
  if (country === 'DK') {
    return `${value}/stk.`;
  }
  return value;
};

export const formatCurrency = (currency) => {
  switch (currency) {
    case 'SEK':
    case 'NOK':
      return 'kr';
    case 'EUR':
      return '€';
    case 'DKK':
      return 'kr.';
    default:
      return currency;
  }
};

const formattedCurrencyWithOrder = (x, currency) => {
  const formattedCurrency = formatCurrency(currency);
  switch (currency) {
    case 'SEK':
    case 'DKK':
    case 'NOK':
      return `${x} ${formattedCurrency}`;
    case 'EUR':
      return `${formattedCurrency} ${x}`;
    default:
      return `${x} ${formattedCurrency}`;
  }
};

export const integerFormatAmountWithCurrency = (amount, currency) => {
  const x = integerFormatAmount(amount);
  return formattedCurrencyWithOrder(x, currency);
};

export const decimalFormatAmountWithCurrency = (amount, currency) => {
  const x = decimalFormatAmount(amount);
  return formattedCurrencyWithOrder(x, currency);
};
