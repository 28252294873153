import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { userInfoCollectionEmail } from '../../actions/applicationActions';

const useValidateEmail = () => {
  const { email: customerEmail } = useSelector((state) => state.app);
  const [emailValidation, setEmailValidation] = useState({
    isEmailValid: true,
    hasEmailBeenValidated: false
  });
  const dispatch = useDispatch();

  const email = (value = customerEmail) => {
    const emailRegEx =
      /^[_A-Za-z0-9-+]+(\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9]+)*(\.[A-Za-z]{2,})$/g;
    setEmailValidation((prevState) => ({ ...prevState, isEmailValid: false }));
    if (emailRegEx.test(value)) {
      setEmailValidation((prevState) => ({ ...prevState, isEmailValid: true }));
      dispatch(userInfoCollectionEmail(value));
    }
    setEmailValidation((prevState) => ({
      ...prevState,
      hasEmailBeenValidated: true
    }));
  };

  return {
    emailValidation,
    validateEmail: (e) => email(e)
  };
};

export default useValidateEmail;
