import {
  CONFIG_COMPLETE,
  SET_SHOW_MORE_INFORMATION_PART_PAYMENT,
  NEW_CREDIT_LIMIT,
  NEW_CREDIT_LIMIT_CURRENCY,
  PREPARE_COMPLETE,
  RISK_ASSESSMENT_COMPLETE,
  ACCOUNT_LOOKUP_COMPLETE,
  RISK_ASSESSMENT_FAILED,
  ACCOUNT_LOOKUP_STARTED,
  RISK_ASSESSMENT_STARTED
} from '../constants/actions';

const initialState = {
  showMoreInformation: false,
  previousCredit: null,
  newCredit: null,
  isPrepareComplete: false
};

function partPaymentReducer(state = initialState, action) {
  switch (action.type) {
    case CONFIG_COMPLETE:
      return {
        ...state,
        currency: action.locale.currency
      };
    case SET_SHOW_MORE_INFORMATION_PART_PAYMENT:
      return { ...state, showMoreInformation: action.value };
    case NEW_CREDIT_LIMIT:
      return { ...state, newCreditLimit: action.newCreditLimit };
    case NEW_CREDIT_LIMIT_CURRENCY:
      return {
        ...state,
        newCreditLimitCurrency: action.newCreditLimitCurrency
      };
    case PREPARE_COMPLETE:
      return {
        ...state,
        isPrepareComplete: true,
        previousCredit: action.customerState.currentLimit,
        newCredit: action.customerState.newLimit
      };
    case ACCOUNT_LOOKUP_STARTED:
      return {
        ...state,
        isPreparing: true,
        isPrepareComplete: false
      };
    case ACCOUNT_LOOKUP_COMPLETE:
      return {
        ...state,
        customerType: action?.customerType,
        accountLookupStatus: action?.accountLookupStatus,
        isPreparing: action.completeWithoutRiskAssessment,
        isPrepareComplete: action.completeWithoutRiskAssessment,
        hasClickedButtonToNextStep: true
      };
    case RISK_ASSESSMENT_STARTED:
      return {
        ...state,
        isRiskAssessmentStarted: true,
        isPreparing: true,
        isPrepareComplete: false
      };
    case RISK_ASSESSMENT_COMPLETE:
      return {
        ...state,
        riskAssessmentStatus: action?.riskAssessmentStatus,
        isPreparing: false,
        hasClickedButtonToNextStep: true,
        isPrepareComplete: true,
        // previousCredit: action.currentLimit,
        // newCredit : action.newLimit,
        financeableAmount: action.financeableAmount,
        maximumLimit: action.maximumLimit,
        approvedLimit: action.approvedLimit,
        requiredLimit: action.requiredLimit,
        offLimitAmountToPay: action.offLimitAmountToPay,
        hasCreditLimitChanged: action.hasCreditLimitChanged,
        creditLimit: action.creditLimit
      };
    case RISK_ASSESSMENT_FAILED:
      return {
        ...state,
        processPurchaseRequestErrorContactSupport: true,
        processPurchaseRequestLoading: false,
        errorContactSupport: true
      };
    default:
      return state;
  }
}

export default partPaymentReducer;
