import React from 'react';
import { number } from 'prop-types';

const TeliaFinanceLogo = (props) => {
  const { width } = props;
  return (
    <>
      <div style={{ width: `${width}px` }}>
        <svg
          viewBox="0 0 101 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_459_943)">
            <path
              d="M13.0155 14.0565C15.5965 12.5544 17.9194 10.0045 18.6476 8.1872C18.8596 6.68511 18.8965 6.4904 18.9241 4.83068C18.9241 4.59888 18.9149 4.38562 18.8965 4.17236C18.749 6.60166 15.6241 9.66147 11.4853 11.6642C6.4985 14.075 2.75607 14.7241 1.08765 12.8882C1.66837 14.0379 2.0463 14.6035 2.70076 15.4566C4.61807 17.1626 8.67391 16.5878 13.0155 14.0565Z"
              fill="black"
            />
            <path
              d="M10.9047 9.93046C15.6058 7.64951 18.8873 4.40427 18.3158 2.24386C17.3756 0.621233 15.4767 0 13.4396 0C7.4019 0 0 3.99629 0 9.10524C0 9.76356 0.147485 10.4961 0.405584 11.2656C1.77904 13.1015 5.98236 12.3227 10.9047 9.93046Z"
              fill="black"
            />
            <path
              d="M10.5083 17.1627C7.19915 18.5164 5.3095 18.0806 4.25867 17.2368L4.24023 17.2276C5.86257 18.8502 7.70613 19.9999 9.32847 19.9999C12.1676 19.9999 16.0483 16.5414 17.8826 11.1729C16.841 13.1107 14.6195 15.4937 10.5083 17.1627Z"
              fill="black"
            />
            <path
              d="M30.1699 7.06545H27.1004V15.7627H25.1554V7.06545H22.0582V5.36865H30.1699V7.06545Z"
              fill="black"
            />
            <path
              d="M36.014 12.5639H30.5755C30.7691 13.658 31.5526 14.3905 32.5481 14.3905C33.304 14.3905 33.9031 14.0474 34.2718 13.4818L35.719 14.3534C34.9816 15.3455 33.7741 15.939 32.5481 15.939C30.5294 15.939 28.7688 14.3256 28.7688 12.0076C28.7688 9.68953 30.511 8.09473 32.4559 8.09473C35.0093 8.104 36.2629 10.1439 36.014 12.5639ZM30.6308 11.1823H34.2626C34.0414 10.218 33.4238 9.60608 32.4651 9.60608C31.5526 9.61536 30.8797 10.2366 30.6308 11.1823Z"
              fill="black"
            />
            <path
              d="M39.8763 15.7813C39.5998 15.8369 39.3048 15.8554 39.0375 15.8554C37.7378 15.8554 37.1663 15.2342 37.1663 13.9732V5.36865H38.9822V13.7228C38.9822 14.3626 39.3693 14.4461 39.8855 14.3904V15.7813H39.8763Z"
              fill="black"
            />
            <path
              d="M40.8165 6.23085C40.8165 5.65598 41.3143 5.17383 41.895 5.17383C42.4573 5.17383 42.9551 5.64671 42.9551 6.23085C42.9551 6.815 42.4573 7.28787 41.895 7.28787C41.3235 7.28787 40.8165 6.80572 40.8165 6.23085ZM40.9825 15.7533V8.33563H42.7984V15.7533H40.9825Z"
              fill="black"
            />
            <path
              d="M50.4215 10.5055V15.7535H48.7254V14.8912C48.1539 15.6515 47.2321 15.9297 46.467 15.9297C44.8723 15.9297 43.9321 14.919 43.9321 13.658C43.9321 12.2208 45.536 11.0433 47.4994 11.0433C47.8866 11.0433 48.2922 11.0711 48.6609 11.1916V10.7002C48.6609 9.95844 48.2368 9.56901 47.3612 9.56901C46.5776 9.56901 45.8863 9.96771 45.4623 10.4869L44.181 9.55046C44.9 8.7067 46.1444 8.11328 47.398 8.11328C49.3614 8.11328 50.4215 8.97559 50.4215 10.5055ZM48.6609 12.8884V12.5639C48.3659 12.4619 48.0525 12.4341 47.6746 12.4341C46.3472 12.4341 45.6651 12.9719 45.6651 13.6209C45.6651 14.1587 46.126 14.4925 46.8173 14.4925C47.7391 14.4925 48.6609 13.9455 48.6609 12.8884Z"
              fill="black"
            />
            <path
              d="M60.8192 6.47204H55.6111V10.0325H59.8697V11.1266H55.6111V15.7534H54.3298V5.36865H60.8192V6.47204Z"
              fill="black"
            />
            <path
              d="M61.6304 6.10112C61.6304 5.68387 62.0175 5.31299 62.4415 5.31299C62.8656 5.31299 63.2343 5.68387 63.2343 6.10112C63.2343 6.52764 62.8656 6.89852 62.4415 6.89852C62.0175 6.89852 61.6304 6.52764 61.6304 6.10112ZM61.8516 15.7534V8.33571H63.0499V15.7534H61.8516Z"
              fill="black"
            />
            <path
              d="M70.7836 10.589V15.7535H69.5853V10.9042C69.5853 9.83792 69.1982 9.19815 68.1842 9.19815C66.8845 9.19815 65.9259 10.1624 65.9259 11.3956V15.7535H64.7275V8.33584H65.8613V9.50413C66.4236 8.62327 67.3915 8.15967 68.4239 8.15967C69.9909 8.15967 70.7836 9.19815 70.7836 10.589Z"
              fill="black"
            />
            <path
              d="M78.0842 10.3572V15.7536H76.978V14.7151C76.4342 15.5867 75.4479 15.9298 74.5445 15.9298C72.9038 15.9298 72.0096 14.8635 72.0096 13.723C72.0096 12.2395 73.5121 11.1453 75.5769 11.1453C76.047 11.1453 76.5172 11.1917 76.9227 11.3122V10.5705C76.9227 9.58763 76.3881 9.10548 75.3004 9.10548C74.3694 9.10548 73.6228 9.532 73.1342 10.1996L72.277 9.57836C72.8946 8.73459 74.0652 8.12263 75.3188 8.12263C77.0979 8.11336 78.0842 8.97567 78.0842 10.3572ZM76.9227 12.9534V12.2395C76.5356 12.1189 76.13 12.0911 75.6968 12.0911C74.1666 12.0911 73.1619 12.7123 73.1619 13.6766C73.1619 14.437 73.7518 14.9377 74.7289 14.9377C75.8258 14.9377 76.9227 14.2701 76.9227 12.9534Z"
              fill="black"
            />
            <path
              d="M85.7534 10.589V15.7535H84.555V10.9042C84.555 9.83792 84.1679 9.19815 83.1539 9.19815C81.8542 9.19815 80.8956 10.1624 80.8956 11.3956V15.7535H79.6973V8.33584H80.8403V9.50413C81.3841 8.62327 82.3612 8.15967 83.3936 8.15967C84.9606 8.15967 85.7534 9.19815 85.7534 10.589Z"
              fill="black"
            />
            <path
              d="M86.8687 12.0447C86.8687 9.77298 88.4633 8.09473 90.5558 8.09473C91.5882 8.09473 92.5284 8.52124 93.1921 9.2352L92.3809 9.99551C91.92 9.42991 91.2748 9.12393 90.5558 9.12393C89.127 9.12393 88.0762 10.3571 88.0762 12.0447C88.0762 13.7229 89.127 14.9561 90.5558 14.9561C91.3116 14.9561 91.9569 14.6316 92.427 14.0382L93.2842 14.7336C92.6206 15.5217 91.6435 15.976 90.5558 15.976C88.4633 15.976 86.8687 14.2978 86.8687 12.0447Z"
              fill="black"
            />
            <path
              d="M100.29 12.2116H94.6117C94.7039 13.8435 95.7178 14.9561 97.0729 14.9561C97.9393 14.9561 98.6952 14.5389 99.1837 13.8249L100.087 14.4183C99.3773 15.4104 98.2988 15.976 97.0821 15.976C95.0634 15.976 93.441 14.3256 93.441 11.9983C93.441 9.71735 95.0449 8.09473 96.9807 8.09473C99.0363 8.104 100.483 9.81934 100.29 12.2116ZM94.6762 11.2751H99.1284C98.9625 9.93988 98.1145 9.10539 96.9715 9.10539C95.81 9.10539 94.9067 9.95843 94.6762 11.2751Z"
              fill="black"
            />
          </g>
          <defs>
            <clipPath id="clip0_459_943">
              <rect width="100.317" height="20" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
    </>
  );
};

TeliaFinanceLogo.defaultProps = {
  width: 101
};

TeliaFinanceLogo.propTypes = {
  width: number
};

export default TeliaFinanceLogo;
