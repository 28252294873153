import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { spacing } from '@teliads/components/foundations';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import MonthPicker from './MonthPicker';
import MonthViewer from './MonthViewer';
import { setSelectedPaymentMethodOption } from '../actions/applicationActions';
import { decimalFormatAmount, formatCurrency } from '../helpers/amount';

const MonthOverview = () => {
  const { t } = useTranslation();
  const { selectedPaymentMethodOption: selectedOption, isLockedMonth } =
    useSelector((state) => state.app);
  const { isPreparing, isPrepareComplete } = useSelector(
    (state) => state.partPayment
  );

  const { account: options } = useSelector((state) => state.app.paymentMethods);
  const { financeableAmount } = useSelector((state) => state.app.customerState);

  const dispatch = useDispatch();

  const [moddedOptions, setModdedOptions] = useState(options);

  const setSelectedOption = useCallback(
    (option) => {
      document.activeElement.blur();
      dispatch(setSelectedPaymentMethodOption(option));
    },
    [dispatch]
  );

  useEffect(() => {
    if (financeableAmount) {
      setModdedOptions(
        moddedOptions.map((option) => {
          const tmpOption = option;
          tmpOption.monthlyCost = financeableAmount / option.downPayTime;
          return { ...tmpOption };
        })
      );
    }
  }, [financeableAmount, options[selectedOption]]);

  const decimalFormattedAmount = decimalFormatAmount(
    moddedOptions[selectedOption].monthlyCost
  );
  const formattedCurrency = formatCurrency(
    moddedOptions[selectedOption].currency
  );

  const isSingleSelection =
    moddedOptions.length === 1 || isLockedMonth || isPreparing;

  return (
    <>
      <MonthlyAmount data-cy="MonthlyAmount">
        {decimalFormattedAmount}
        <span>{`${formattedCurrency}/${t('month')}`}</span>
      </MonthlyAmount>

      <Wrapper>
        {!isLockedMonth && !isSingleSelection && !isPrepareComplete && (
          <MonthPicker
            options={moddedOptions}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
          />
        )}
        {(isSingleSelection || isPrepareComplete) && (
          <MonthViewer option={moddedOptions?.[selectedOption]} />
        )}
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div.attrs({
  className: 'SdkInStoreViewWrapper'
})`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const MonthlyAmount = styled.h1.attrs({ className: 'MonthlyAmount' })`
  font-family: ${(p) => p.theme.font.primary};
  font-weight: bold;
  margin-top: ${spacing.spacing48};
  margin-bottom: 0;
  font-size: ${(p) => p.theme.fontSize.large};
  text-align: center;
  width: 100%;
  span {
    font-family: ${(p) => p.theme.font.primary};
    font-weight: normal;
    font-size: ${(p) => p.theme.fontSize.base};
    margin-left: ${spacing.spacing4};
  }
`;

export default MonthOverview;
