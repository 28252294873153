import React, { useEffect } from 'react';
import { Router, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import styled, { ThemeProvider } from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import Measure from 'react-measure';
import i18n from '../i18n/i18n';
import Initialize from './Initialize';
import Authenticated from './Authenticated';
import '../css/index.scss';
import ROUTES from '../constants/routes';
import postMessageOut from '../helpers/postMessage/post-message-out';
import receiveMessage from '../helpers/postMessage/receiveMessage';
import ThankYouPartPayment from './ThankYouPartPayment';

import '../helpers/analytics';
import PaymentMethods from './PaymentMethods';
import ThankYouCardPayment from './CardPayment/ThankYouCardPayment';

const history = createBrowserHistory();

const App = () => {
  const { language, parentWindowOrigin, theme } = useSelector(
    (state) => state.app
  );

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language]);

  const dispatch = useDispatch();

  useEffect(() => {
    window.addEventListener(
      'message',
      receiveMessage(dispatch, parentWindowOrigin),
      false
    );

    return () => window.removeEventListener('message', receiveMessage);
  }, [parentWindowOrigin]);

  useEffect(() => {
    postMessageOut({
      type: 'setHeight',
      internal: true,
      value: document.body.clientHeight
    });
  }, [document.body.clientHeight]);

  return (
    <ThemeProvider theme={theme}>
      <Router history={history}>
        <Measure
          bounds
          onResize={() => {
            postMessageOut({
              type: 'setHeight',
              internal: true,
              value: document.body.clientHeight
            });
          }}
        >
          {({ measureRef }) => (
            <Container ref={measureRef}>
              <Route path="/" exact component={Initialize} />

              <Route
                exact
                path={`${ROUTES.PAYMENT_METHOD}/:paymentMethod`}
                component={PaymentMethods}
              />
              <Route
                path={ROUTES.AUTHENTICATED}
                exact
                component={Authenticated}
              />

              <Route
                exact
                path={ROUTES.THANK_YOU_PART_PAYMENT}
                component={ThankYouPartPayment}
              />

              <Route
                exact
                path={ROUTES.THANK_YOU_CARD_PAYMENT}
                component={ThankYouCardPayment}
              />
            </Container>
          )}
        </Measure>
      </Router>
    </ThemeProvider>
  );
};
export default App;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: ${(p) => p.theme.font.primary};
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale;
`;
