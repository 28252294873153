import React from 'react';

const AdyenLogo = () => {
  return (
    <svg
      width="100"
      height="33"
      viewBox="0 0 100 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.2312 8.09668H0.424335V12.4837L9.42315 12.4838C9.69044 12.4838 9.94678 12.5897 10.1358 12.7783C10.3248 12.9669 10.431 13.2227 10.431 13.4894V21.2579H8.50779C8.2405 21.2579 7.98415 21.152 7.79515 20.9634C7.60614 20.7748 7.49996 20.519 7.49996 20.2523V14.6774H3.69976C2.76423 14.6774 1.86703 15.0482 1.20551 15.7083C0.544 16.3684 0.172363 17.2636 0.172363 18.1972V22.1253C0.172363 23.0588 0.544 23.954 1.20551 24.6141C1.86703 25.2742 2.76423 25.6451 3.69976 25.6451H17.7586V11.6165C17.7586 10.683 17.3869 9.7877 16.7254 9.12761C16.0639 8.46752 15.1667 8.09668 14.2312 8.09668Z"
        fill="#0ABF53"
      />
      <path
        d="M30.948 21.258H29.0248C28.7575 21.258 28.5011 21.1521 28.3121 20.9635C28.1231 20.7749 28.0169 20.5191 28.0169 20.2523V8.09668H24.217C23.2814 8.09668 22.3842 8.46752 21.7227 9.12761C21.0612 9.7877 20.6896 10.683 20.6896 11.6165V22.1253C20.6896 23.0588 21.0612 23.9541 21.7227 24.6142C22.3842 25.2743 23.2814 25.6451 24.217 25.6451H38.2758V0.784912H30.9482L30.948 21.258Z"
        fill="#0ABF53"
      />
      <path
        d="M51.4653 21.258H49.5421C49.2748 21.258 49.0185 21.1521 48.8294 20.9635C48.6404 20.7749 48.5343 20.5191 48.5343 20.2524V8.09668H41.2068V22.1253C41.2068 23.0588 41.5784 23.9541 42.2399 24.6142C42.9014 25.2743 43.7987 25.6451 44.7342 25.6451H51.4654V27.8387H41.4588V32.956H55.2656C56.2011 32.956 57.0984 32.5852 57.7599 31.9251C58.4214 31.265 58.793 30.3697 58.793 29.4362V8.09668H51.4653V21.258Z"
        fill="#0ABF53"
      />
      <path
        d="M75.7829 8.09668H61.7241V22.1253C61.7241 23.0588 62.0958 23.954 62.7573 24.6141C63.4188 25.2742 64.316 25.6451 65.2515 25.6451H79.0583V21.2579H70.0595C69.7923 21.2579 69.5359 21.152 69.3469 20.9634C69.1579 20.7748 69.0517 20.519 69.0517 20.2523V12.4839H70.9749C71.2422 12.4839 71.4985 12.5898 71.6875 12.7784C71.8765 12.967 71.9827 13.2228 71.9827 13.4895V19.0644H75.7829C76.7185 19.0644 77.6157 18.6936 78.2772 18.0335C78.9387 17.3734 79.3103 16.4781 79.3103 15.5446V11.6165C79.3103 11.1543 79.2191 10.6966 79.0418 10.2695C78.8646 9.84247 78.6047 9.45445 78.2772 9.12761C77.9496 8.80076 77.5608 8.5415 77.1328 8.36461C76.7048 8.18772 76.2462 8.09668 75.7829 8.09668Z"
        fill="#0ABF53"
      />
      <path
        d="M96.3001 8.09668H82.2413V25.6451H89.5689V12.4837H91.4921C91.7592 12.4845 92.0151 12.5907 92.2039 12.7791C92.3927 12.9676 92.4992 13.2229 92.4999 13.4894V25.6451H99.8275V11.6165C99.8275 10.683 99.4559 9.7877 98.7944 9.12761C98.1329 8.46752 97.2357 8.09668 96.3001 8.09668Z"
        fill="#0ABF53"
      />
    </svg>
  );
};

export default AdyenLogo;
