import React from 'react';
import { Route } from 'react-router-dom';
import Accordion from './Accordion';
import ROUTES from '../constants/routes';

const PaymentMethods = () => {
  return (
    <>
      <Route
        exact
        path={`${ROUTES.PAYMENT_METHOD}/:paymentMethod`}
        component={Accordion}
      />
    </>
  );
};

export default PaymentMethods;
