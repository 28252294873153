import { colors } from '@teliads/components/foundations';
import {
  getCssVariable,
  getCssVariableOrDeprecatedVariableIfNotSet
} from '../customThemeHelper';

const colorTheme = {
  core: {
    black: colors.black
  },
  // Transparent color in Voca is black, if it is changed to
  // transparent in the future this can be removed.
  transparent: 'transparent',
  primary: colors.purple500,
  primaryHover: colors.purple600,
  primaryDisabled: colors.gray100,
  primaryTextColor: colors.white,
  white: colors.white,
  expressiveButton: colors.purple500,
  expressiveButtonHover: colors.purple600,
  expressiveButtonFocus: colors.purple700,
  expressiveButtonDisabled: colors.gray100,
  expressiveButtonTextColor: colors.white,
  secondaryButton: 'transparent',
  secondaryButtonBorder: colors.purple850,
  secondaryButtonHover: colors.purple850,
  secondaryButtonDark: colors.red600,
  secondaryButtonDisabled: colors.gray300,
  secondaryButtonTextColor: colors.purple850,
  secondaryButtonTextColorHover: colors.white,
  destructiveDark: colors.red600,
  mainLayerBackground: colors.white,
  secondaryLayerBackground: colors.gray50,
  lineColor: colors.gray50,
  outerBorderColor: colors.gray50,
  monthItemColor: '#6D02A3',
  monthItemTextColor: colors.functionalBlack
};

const colorHandler = {
  get: (target, prop) => {
    if (prop === 'primary') {
      return getCssVariable('--primary-color') || target[prop];
    }
    if (prop === 'primaryHover') {
      return getCssVariable('--primary-hover-color') || target[prop];
    }
    if (prop === 'primaryTextColor') {
      return getCssVariable('--primary-text-color') || target[prop];
    }
    if (prop === 'expressiveButton') {
      return (
        getCssVariableOrDeprecatedVariableIfNotSet(
          '--expressive-button',
          '--purchase-button'
        ) || target[prop]
      );
    }
    if (prop === 'expressiveButtonHover') {
      return (
        getCssVariableOrDeprecatedVariableIfNotSet(
          '--expressive-button-hover',
          '--purchase-button-hover'
        ) || target[prop]
      );
    }
    if (prop === 'expressiveButtonFocus') {
      return (
        getCssVariableOrDeprecatedVariableIfNotSet(
          '--expressive-button-focus',
          '--purchase-button-focus'
        ) || target[prop]
      );
    }
    if (prop === 'expressiveButtonDisabled') {
      return (
        getCssVariableOrDeprecatedVariableIfNotSet(
          '--expressive-button-disabled',
          '--purchase-button-disabled'
        ) || target[prop]
      );
    }
    if (prop === 'expressiveButtonTextColor') {
      return (
        getCssVariableOrDeprecatedVariableIfNotSet(
          '--expressive-button-text-color',
          '--purchase-button-text-color'
        ) || target[prop]
      );
    }
    if (prop === 'secondaryButton') {
      return (
        getCssVariableOrDeprecatedVariableIfNotSet(
          '--secondary-button',
          '--destructive'
        ) || target[prop]
      );
    }
    if (prop === 'secondaryButtonHover') {
      return (
        getCssVariableOrDeprecatedVariableIfNotSet(
          '--secondary-button-hover',
          '--destructive-hover'
        ) || target[prop]
      );
    }
    if (prop === 'secondaryButtonDisabled') {
      return (
        getCssVariableOrDeprecatedVariableIfNotSet(
          '--secondary-button-disabled',
          '--destructive-disabled'
        ) || target[prop]
      );
    }
    if (prop === 'secondaryButtonTextColor') {
      return (
        getCssVariableOrDeprecatedVariableIfNotSet(
          '--secondary-button-text-color',
          '--destructive-text-color'
        ) || target[prop]
      );
    }
    if (prop === 'destructiveDark') {
      return getCssVariable('--destructive-dark') || target[prop];
    }
    if (prop === 'mainLayerBackground') {
      return getCssVariable('--main-layer-background') || target[prop];
    }
    if (prop === 'secondaryLayerBackground') {
      return getCssVariable('--second-layer-background') || target[prop];
    }
    if (prop === 'lineColor') {
      return getCssVariable('--line-color') || target[prop];
    }
    if (prop === 'outerBorderColor') {
      return getCssVariable('--outer-border-color') || target[prop];
    }
    if (prop === 'monthItemColor') {
      return getCssVariable('--month-item-color') || target[prop];
    }
    if (prop === 'monthItemTextColor') {
      return getCssVariable('--month-item-text-color') || target[prop];
    }
    return target[prop];
  }
};

const color = new Proxy(colorTheme, colorHandler);

export default color;
