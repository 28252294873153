import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import AuthenticationErrorBox, {
  errorBoxTypes
} from '../AuthenticationErrorBox';
import { InputEmail, InputLabel } from './CollectionStyles';

const EmailField = ({ apiError, emailValidation, validateEmail }) => {
  const { t } = useTranslation();
  const { email: customerEmail } = useSelector((state) => state.app);

  useEffect(() => {
    if (customerEmail?.length > 0) {
      validateEmail(customerEmail);
    }
  }, []);

  return (
    <>
      <InputLabel>
        {t('email_phone_collection.field_email_header', {
          defaultValue: '* E-mail'
        })}
      </InputLabel>
      <InputEmail
        onChange={(e) => validateEmail(e?.target?.value)}
        defaultValue={customerEmail}
        key="email"
        data-cy="InputEmail"
      />
      {(apiError.emailNotValid || !emailValidation.isEmailValid) && (
        <AuthenticationErrorBox
          errorText={t('email_phone_collection.email_validation_failed', {
            defaultValue: 'Use a valid e-mail address'
          })}
          type={errorBoxTypes.inputField}
        />
      )}
    </>
  );
};

EmailField.propTypes = {
  apiError: PropTypes.shape({
    emailNotValid: PropTypes.bool
  }),
  emailValidation: PropTypes.shape({
    isEmailValid: PropTypes.bool
  }).isRequired,
  validateEmail: PropTypes.func.isRequired
};

EmailField.defaultProps = {
  apiError: PropTypes.shape({
    emailNotValid: undefined
  })
};

export default EmailField;
