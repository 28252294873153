import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { typography } from '@teliads/components/foundations';
import ContactSupport from './ContactSupport';
import Loading from './Loading';
import PoweredByTeliaFinance from './PoweredByTeliaFinance';

const ProcessingPurchase = () => {
  const {
    processPurchaseRequestLoading,
    processPurchaseRequestErrorContactSupport
  } = useSelector((state) => state.app);

  const { t } = useTranslation();

  return (
    <ProcessingPurchaseWrapper data-cy="ProcessingPurchase">
      {processPurchaseRequestLoading && (
        <>
          <LoadingWrapper>
            <Loading />
          </LoadingWrapper>
          <Text>{t('processing_purchase.text')}</Text>
        </>
      )}
      {processPurchaseRequestErrorContactSupport && <ContactSupport />}

      <PoweredByTeliaFinance />
    </ProcessingPurchaseWrapper>
  );
};

export default ProcessingPurchase;

const ProcessingPurchaseWrapper = styled.div.attrs({
  className: 'ProcessingPurchaseWrapper'
})`
  width: 100%;
  min-height: 300px;
  max-width: ${(p) => p.theme.width.innerMax};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  /*Center horizontaly ie */
  -ms-flex-pack: center;
`;

const LoadingWrapper = styled.div`
  width: 50px;
  height: 50px;
  margin: 0 auto;
`;

const Text = styled.p.attrs({ className: 'Text' })`
  margin: ${(p) => p.theme.margin.unit.double} 0;
  font-size: ${(p) => p.theme.fontSize.base};
  font-weight: ${typography.weightNormal};
  display: block;
  text-align: center;
  width: 100%;
`;
