module.exports = {
  "authenticating.cancel": "Peruuta",
  "authenticating.waiting_message": "Odottaa tunnistautumista",
  "authentication.error_bank_id_in_use": "Tunnistautuminen on aloitettu useammalla sivustolla. Yritä uudelleen.",
  "authentication.general_error": "Tunnistautumisessa tapahtui virhe. Yritä hetken päästä uudelleen.",
  "authentication.identify_button_label": "Tunnistaudu",
  "cancel_view.guidance_header": "Olet perunut tilauksesi",
  "cancel_view.guidance_text": "Jos sinulla on kysymyksiä voit olla yhteydessä asiakaspalveluumme.",
  "cancel_view.sdk_in_store_guidance_header": "Olet perunut ostoksesi",
  "cancel_view.sdk_in_store_guidance_text": "Jos sinulla on kysymyksiä, olethan yhteydessä Telia kaupan myyjään.",
  "card.general_error": "Pahoittelut, jokin meni vikaan",
  "circuit_breaker.body": "Sivun lataaminen epäonnistui! Yritä uudelleen, kun alla oleva aika on kulunut:",
  "circuit_breaker.button_try_again": "Yritä uudelleen",
  "circuit_breaker.minutes_postfix": "min",
  "circuit_breaker.seconds_postfix": "sekuntia",
  "circuit_breaker.title": "Tapahtui virhe",
  "credit_information.inform_about_credit_report": "Tarkistamme luottotietosi tarvittaessa ostoksen yhteydessä.",
  "credit_information.sign_purchase_text": "Vahvista ja maksa",
  "credit_limit_information.could_not_update_credit_limit_title": "Valitettavasti emme voi tarjota sinulle luottoa.",
  "credit_limit_information.credit_limit_has_been_increased_to": "Luottorajaasi on korotettu, uusi luottorajasi on {{amount}} {{currency}}",
  "credit_limit_information.credit_limit_to_low": "Et voi maksaa tätä ostosta osissa koska luottorajasi {{amount}} ei riitä tähän ostokseen.",
  "credit_limit_information.declined_credit_redirect_button": "Peruuta",
  "credit_limit_information.increase_limit_info": "Hyväksytty! Voit maksaa luotolla!",
  "credit_limit_information.kyc_block": "Et ole täyttänyt meidän asiakastietolomaketta (KYC). Jotta voimme jatkossa tarjota sinulle rahoitusta sinun täytyy täyttää lomake verkkosivuillamme osoitteessa teliafinance.com/fi.",
  "credit_limit_information.maximum_amount_exceeded": "Et voi maksaa ostostasi osissa koska summa ylittää Telia Financen luottorajasi {{amount}}.",
  "credit_limit_information.please_try_another_payment_method": "Valitse toinen maksutapa",
  "credit_limit_information.ready_to_pay_title": "Hyväksytty! Voit maksaa luotolla!",
  "credit_limit_information.split_payment_continue_button": "Jatka",
  "credit_limit_information.title": "Luottotarkistus",
  "credit_limit_information.unpaid_invoices": "Sinulla on maksamattomia laskuja jonka takia et voi käyttää Telia Rahoitusta tähän ostokseen.",
  "email_phone_collection.body": "Ennen kuin jatkat, tarkista, että yhteystietosi ovat oikein, jotta voimme lähettää sinulle tärkeää tietoa koskien luottotilisopimustasi.",
  "email_phone_collection.continue": "Jatka",
  "email_phone_collection.email_validation_failed": "Täytä kenttä voimassa olevalla sähköpostiosoitteella.",
  "email_phone_collection.field_email_header": "Sähköpostiosoite",
  "email_phone_collection.field_phone_header": "Matkapuhelin",
  "email_phone_collection.header": "Yhteystiedot",
  "email_phone_collection.phone_validation_failed": "Väärä matkapuhelin.",
  "high_load.body": "Sivustollemme kohdistuu poikkeuksellisen paljon liikennettä, jonka vuoksi sinut on asetettu jonoon. Odota kun sivua ladataan. Sivun päivittäminen aiheuttaa lisää viivettä.",
  "high_load.estimated_time": "Jonotusaika:",
  "high_load.title_loading": "Ladataan…",
  "high_load.title_thank_you": "Kiitos kärsivällisyydestäsi!",
  "month": "kk",
  "month_picker.number_of_months": "{{count}} kk",
  "month_picker.number_of_months_plural": "{{count}} kk",
  "more_information.close_button_label": "Sulje",
  "more_information.paragraph_1.row_1": "Ei korkoja. Ei kuluja*.\n(*Ensimmäinen lasku lähetetään kotiisi maksutta. Sen yhteydessä saat ohjeet laskutuksen muuttamiseksi maksuttomaan sähköiseen laskutusvaihtoehtoon.)",
  "more_information.paragraph_1.row_10": " ",
  "more_information.paragraph_1.row_2": "Kaikki laitteet ja tarvikkeet yhdelle luottotilille ja laskulle.",
  "more_information.paragraph_1.row_3": "Maksa koko velka ensimmäisellä laskulla tai maksa osissa jopa 36 kuukauden maksuajalla.",
  "more_information.paragraph_1.row_3.my_call": " ",
  "more_information.paragraph_1.row_4": "Voit koska tahansa maksaa luoton kokonaan pois tai lyhentää luottoa laskutettua summaa enemmän.",
  "more_information.paragraph_1.row_5": "Lisätietoa tilistä ja laskuista löydät kirjautumalla Telia.fi -> Rahoitus tai Telia Pay sovelluksen kautta.",
  "more_information.paragraph_1.row_6": "Viimeinen kuukausierä saattaa poiketa muutamia senttejä muista kuukausimaksuista.",
  "more_information.paragraph_1.row_8": " ",
  "more_information.paragraph_1.row_8_link_1": "https://developer.signicat.com/documentation/finnish-trust-network/finnish-trust-network-information/",
  "more_information.paragraph_2.charge_cost_1": "0 euroa",
  "more_information.paragraph_2.charge_cost_2": "0 prosenttia",
  "more_information.paragraph_2.charge_cost_3": "0 euroa",
  "more_information.paragraph_2.charge_cost_4": "0 euroa",
  "more_information.paragraph_2.charge_cost_5": "1,50 euroa/lasku",
  "more_information.paragraph_2.charge_cost_6": "0 euroa",
  "more_information.paragraph_2.charge_description_1": "Hallintokulut",
  "more_information.paragraph_2.charge_description_2": "Luottokorko",
  "more_information.paragraph_2.charge_description_3": "Perustamismaksu",
  "more_information.paragraph_2.charge_description_4": "E-lasku tai sähköpostilasku",
  "more_information.paragraph_2.charge_description_5": "Paperilaskutuslisä",
  "more_information.paragraph_2.charge_description_6": "Vuosimaksu",
  "more_information.paragraph_2.charge_title": "Maksut",
  "more_information.paragraph_2.row_1": "Kulut",
  "more_information.paragraph_2.row_2": "Hallintokulut 0 euroa",
  "more_information.paragraph_2.row_3": "Korko 0 prosenttia",
  "more_information.paragraph_2.row_4": "Perustamismaksu 0 euroa",
  "more_information.paragraph_2.row_5": "E-lasku tai sähköpostilasku 0 euroa",
  "more_information.paragraph_2.row_6": "Paperilaskutuslisä 1,50 euroa/lasku",
  "more_information.paragraph_2.row_7": "Vuosimaksu 0 euroa",
  "more_information.title": "Lisätietoa Telia Rahoituksesta",
  "onboarding.terms_and_conditions_secci_checkbox_text": "Hyväksyn sopimuksen, joka koostuu <1>Ehdoista</1> ja <3>SECCI</3> tiedoista sekä sen, että Telia Finance tarkistaa luottotietoni.\n\n",
  "onboarding.terms_and_conditions_secci_checkbox_text_1": "https://www.teliafinance.com/globalassets/telia-finance/documents/kreditavgifter/documents/fitfcc_fi-fi_2019-4.pdf",
  "onboarding.terms_and_conditions_secci_checkbox_text_3": "https://www.teliafinance.com/globalassets/telia-finance/documents/kreditavgifter/documents/secci_fi-fi_2019-6.pdf",
  "part_payment_not_permitted_view.not_enough_amount_for_part_payment_text": "Alle {{amount}} {{currency}} ostoksien rahoitus on mahdollista vain nykyisille Telia Financen asiakkaille.",
  "part_payment_not_permitted_view.title": "Valitettavasti et voi käyttää Telia Rahoitusta tämän ostoksen maksamiseen.",
  "pay_in_parts_summary.credit_interest": "Luottokorko",
  "pay_in_parts_summary.effective_interest": "Todellinen vuosikorko",
  "pay_in_parts_summary.increase_limit_info": "Nykyinen luottorajasi <bold>{{previousCredit}}</bold> <bold>{{currency}}</bold> korotetaan <bold>{{newCredit}}</bold> <bold>{{currency}}</bold> niin että se kattaa ostoksesi.",
  "pay_in_parts_summary.invoice_fee": "Laskutuslisä*",
  "pay_in_parts_summary.invoice_fee_fine_print": "* E-laskut ja sähköpostilaskut ovat ilmaisia. Ensimmäinen paperilasku on ilmainen, sen jälkeen {{amount}} €/lasku.",
  "pay_in_parts_summary.number_of_months": "kuukaudessa",
  "pay_in_parts_summary.pay_the_difference_credit_amount": "Telia Rahoitus luottotili",
  "pay_in_parts_summary.pay_the_difference_direct_amount": "Maksettava korttimaksulla",
  "pay_in_parts_summary.pay_the_difference_total_amount": "Maksettava määrä",
  "pay_in_parts_summary.plan": "Maksusuunnitelma",
  "pay_in_parts_summary.registration_fee": "Perustamismaksu",
  "pay_in_parts_summary.title": "Yhteenveto",
  "pay_in_parts_summary.total_amount": "Luoton kokonaismäärä",
  "pay_in_parts.cancel_button_label": "Peruuta",
  "pay_in_parts.continue_button_label": "Jatka",
  "pay_in_parts.interest_text_part_1": "Luottotilin osissa maksamisen kokonaiskustannukset (todellinen vuosikorko) esimerkkilaskelmassa 15.03.2024:",
  "pay_in_parts.interest_text_part_2": "Kun luottotiliä käytetään <bold>1 000 €</bold> 0 %:n luottokorolla ja 36 kuukauden takaisinmaksuajalla ja luotto maksetaan 36:ssä erässä, jotka ovat suuruudeltaan <bold>27,78 €/kk</bold>, todellinen vuosikorko on e-laskulla/sähköpostilaskulla 0 % (<bold>0 €</bold>) tai paperilaskulla 3,40 % (<bold>1,50 €/kpl</bold>). Täten <bold>1 000 €</bold> suuruisen luoton maksettava kokonaismäärä on siis <bold>1 000 €</bold> jos käytät sähköistä laskua, tai jos käytät paperilaskua <bold>1 052,50 €</bold>. Telia Financen luottolilin osissa maksaminen on korotonta ja kulutonta kun valitset e-laskun tai s-postilaskun.",
  "pay_in_parts.interest_title": "Mitä Telia Rahoituksen käyttäminen maksaa?",
  "pay_in_parts.more_information": "Lisätietoa Telia Rahoituksesta",
  "pay_in_parts.more_information_details": "* Ei kuluja ja todellinen vuosikorko on 0 %, kun valitset e-laskun tai sähköpostilaskun.",
  "pay_in_parts.more_information_details_in_store": "Lisätietoa Telia Rahoituksesta,\n",
  "pay_in_parts.more_information_in_store": "täältä",
  "pay_in_parts.pay_button_label": "Vahvista ja maksa",
  "pay_in_parts.pay_strong_sign_button_label": "Jatka",
  "pay_in_parts.pay_the_difference_information": "Voit maksaa <bold>{{formattedFinanceableAmount}}</bold> ostoskorissa olevasta määrästä Telia Rahoituksen luottotilillä ja loput maksukortilla.",
  "pay_in_parts.pay_the_difference_information_increase_limit": "Maksimiluottorajasi ei riitä koko summan maksuun, voit maksaa <bold>{{formattedFinanceableAmount}}</bold> ostoskorissa olevasta määrästä Telia Rahoituksen luottotilillä ja loput Telia Kaupassa maksukortilla.",
  "pay_in_parts.terms_and_conditions_credit_check_info": "Tarkistamme luottotietosi tarvittaessa ostoksen yhteydessä. Luethan myös <0>SECCI:n</0> ennen ostoa.",
  "pay_in_parts.terms_and_conditions_credit_check_info_link_0": "https://files.teliafinance.com/secci-fi.pdf",
  "pay_in_parts.terms_and_conditions_secci": "Hyväksyn sopimuksen, joka koostuu <0>Ehdoista</0> ja Vakiomuotoiset eurooppalaiset kuluttajaluottotiedot <2>(SECCI)</2> tiedoista sekä että Telia Finance tarkistaa luottotietoni.",
  "pay_in_parts.terms_and_conditions_secci_link_0": "https://www.teliafinance.com/globalassets/telia-finance/documents/kreditavgifter/documents/fitfcc_fi-fi_2019-4.pdf",
  "pay_in_parts.terms_and_conditions_secci_link_2": "https://www.teliafinance.com/globalassets/telia-finance/documents/kreditavgifter/documents/secci_fi-fi_2019-6.pdf",
  "pay_in_parts.terms_and_conditions_secci_text": "Painamalla “Vahvista ja maksa”, hyväksyn Telia Financen Luottosopimuksen, joka koostuu <link0>yleisistä ehdoista</link0>, <link1>SECCI</link1>:stä ja <link2>Tietosuojakäytännöstä</link2>.",
  "pay_in_parts.terms_and_conditions_secci_text_link_0": "https://files.teliafinance.com/luoton-ehdot-fi.pdf",
  "pay_in_parts.terms_and_conditions_secci_text_link_1": "https://files.teliafinance.com/secci-fi.pdf",
  "pay_in_parts.terms_and_conditions_secci_text_link_2": "https://www.teliafinance.com/fi/yksityisille/tietoa-meista/yksityisyys",
  "pay_with_card.error_message_generic": "Tuntematon",
  "pay_with_card.error_message_label": "Tapahtui virhe",
  "pay_with_card.pay_button_label": "MAKSA",
  "pay_with_card.powered_by": "Telia Oy",
  "payment_method.pay_in_parts": "Telia Rahoitus luottotili",
  "payment_method.pay_with_card": "Kortti",
  "powered_by": "Powered by",
  "processing_purchase.error_please_contact_staff": "Emme voi käsitellä tilaustasi. Jos sinulla on kysymyksiä voit olla yhteydessä kaupan henkilökuntaan.",
  "processing_purchase.error_please_contact_support": "Pahoittelut, jokin meni vikaan",
  "processing_purchase.error_please_try_again": "Pahoittelut, jokin meni vikaan.",
  "processing_purchase.error_support_telephone_number": "+358 20 69 04 00",
  "processing_purchase.error_try_another_payment_method": "Valitse toinen maksutapa",
  "processing_purchase.text": "Käsitellään maksua",
  "processing_purchase.try_again_button_label": "Yritä uudelleen",
  "signing.cancel": "Peruuta",
  "signing.general_error": "Allekirjoituksen aikana tapahtui virhe. Yritä uudelleen.",
  "signing.waiting_message": "Odotetaan allekirjoitustasi",
  "thank_you_pay_in_parts.fine_print": "Paperilasku lähetetään vakituiseen osoitteeseesi, ellet ole antanut muuta osoitetta. Voit vaihtaa osoitteen Telia Pay-sovelluksessa, jossa voit myös valita sähköpostilaskun.",
  "thank_you_pay_in_parts.number_of_months": "{{count}} kk",
  "thank_you_pay_in_parts.number_of_months_plural": "{{count}} kk",
  "thank_you_pay_in_parts.pay_in_parts": "Telia Rahoitus luottotili",
  "thank_you_pay_in_parts.summary_monthly_cost": "Kuukausierä",
  "thank_you_pay_in_parts.summary_payment_method": "Maksutapa",
  "thank_you_pay_in_parts.summary_payment_term": "Maksuaika",
  "thank_you_pay_in_parts.summary_title": "Yhteenveto",
  "thank_you_pay_in_parts.summary_total_amount": "Luoton kokonaismäärä",
  "thank_you_pay_in_parts.title": "KIITOS!",
  "thank_you_pay_with_card.pay_in_parts_promo": "Telia Rahoitus luottotilisi tiedot ja laskut näet kirjautumalla Telia.fi -> Rahoitus",
  "thank_you_pay_with_card.summary_payment_method": "Maksutapa",
  "thank_you_pay_with_card.summary_title": "Yhteenveto",
  "thank_you_pay_with_card.title": "KIITOS!",
  "thank_you_pay_with_card.total_amount": "Luoton kokonaismäärä",
  "try_another_payment_method": "Valitse toinen maksutapa",
  "your_details.cancel": "Peruuta",
  "your_details.continue": "Jatka",
  "your_details.save": "Tallenna",
  "your_details.saving": "Tallenna",
  "your_details.ssn_helper_text": "Tunnistaudu pankkitunnistautumisella jotta voimme nähdä jos olet Telia Financen asiakas",
  "your_details.ssn_label": "Henkilötunnus",
  "your_details.ssn_placeholder": "DDMMYYYYXXXX",
  "your_details.title": "Yhteystietosi"
};