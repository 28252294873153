import React from 'react';
import PropTypes from 'prop-types';
import { PAYMENT_METHOD_CARD, PAYMENT_METHOD_PART_PAYMENT } from '../constants';
import TeliaFinanceLogo from './graphics/TeliaFinanceLogo';
import TeliaIconPaymentSuccess from './graphics/TeliaIconPaymentSuccess';

const PaymentMethodIcon = ({ paymentMethod }) => {
  switch (paymentMethod) {
    case PAYMENT_METHOD_PART_PAYMENT:
      return <TeliaFinanceLogo width={82} />;
    case PAYMENT_METHOD_CARD:
      return <TeliaIconPaymentSuccess />;
    default:
      return <></>;
  }
};

PaymentMethodIcon.propTypes = {
  paymentMethod: PropTypes.string.isRequired
};

export default PaymentMethodIcon;
