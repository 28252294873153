module.exports = {
  "authenticating.cancel": "Cancel",
  "authenticating.description_app_at_other_device": "Open the BankID app on your mobile or tablet, press the QR symbol in the BankID app. Point the camera at the QR code below.",
  "authenticating.start_app_button": "Start BankID",
  "authenticating.start_app_text_button": "Use BankID on this device",
  "authenticating.use_app_at_other_device_text_button": "Use BankID on other device",
  "authenticating.waiting_message": "Open BankID on your device",
  "authentication.error_bank_id_in_use": "BankID is started on more than one site. Try again.",
  "authentication.general_error": "Oops, something went wrong. Try again.",
  "authentication.identify_button_label": "Identify with BankID",
  "cancel_view.guidance_header": "You have canceled your order",
  "cancel_view.guidance_text": "Please contact customer service if you have any questions.",
  "cancel_view.sdk_in_store_guidance_header": "You have canceled the purchase",
  "cancel_view.sdk_in_store_guidance_text": "Contact the store representative if you have any questions.",
  "card.complete_payment": "Complete payment",
  "card.expiry_invalid_error": "Card Expiry Invalid",
  "card.general_error": "Something went wrong. Please try again.",
  "card.invalid_info_error": "Invalid Card Info",
  "card.is_processing_body": "Hang on while we're processing your payment.",
  "card.is_processing_title": "Processing payment",
  "card.not_supported_error": "Not supported Card",
  "circuit_breaker.body": "Failed to load the page! Please give it another try after the countdown below:",
  "circuit_breaker.button_try_again": "Try again",
  "circuit_breaker.minutes_postfix": "min.",
  "circuit_breaker.seconds_postfix": "sec.",
  "circuit_breaker.title": "An error has occurred",
  "credit_information.accept_credit_report_to_continue": "You need to give your credit assessment permission to proceed.",
  "credit_information.inform_about_credit_report": "I'm aware that a new credit report may be obtained. ",
  "credit_information.sign_purchase_text": "Complete purchase",
  "credit_limit_information.could_not_update_credit_limit_title": "Unfortunately, we can’t offer you pay in parts.",
  "credit_limit_information.could_not_update_credit_limit_title.my_call": "Unfortunately, we can’t offer you Partpay.",
  "credit_limit_information.credit_limit_has_been_increased_to": "Your credit limit has been increased to {{amount}} {{currency}}",
  "credit_limit_information.credit_limit_to_low": "You can’t pay this purchase in parts because your credit space of {{amount}} is too low.",
  "credit_limit_information.declined_credit_redirect_button": "Cancel",
  "credit_limit_information.increase_limit_info": "Approved! You can pay in parts!",
  "credit_limit_information.kyc_block": "You haven’t filled out our customer knowledge form. To pay in parts, please visit teliafinance.com to fill out our form.",
  "credit_limit_information.maximum_amount_exceeded": "You can’t pay this purchase in parts because the amount exceeds the maximum credit limit of {{amount}} offered by Telia Finance.",
  "credit_limit_information.please_try_another_payment_method": "Please try another payment method",
  "credit_limit_information.ready_to_pay_title": "Approved! You can pay in parts!",
  "credit_limit_information.split_payment_continue_button": "Continue",
  "credit_limit_information.split_payment_info": "We could only offer you {{offeredCredit}} {{currency}} in credit. Pay {{remainingCredit}}  {{currency}} now if you want to continue with Part Payment.",
  "credit_limit_information.title": "Your credit assessment",
  "credit_limit_information.unpaid_invoices": "You can’t pay your purchase in parts due to unpaid invoices.",
  "email_phone_collection.body": "Before you continue, we would like you to fill out your contact details so that we can reach you with important pay in parts information.",
  "email_phone_collection.continue": "Continue ",
  "email_phone_collection.email_validation_failed": "Fill out the field with a valid e-mail.",
  "email_phone_collection.field_email_header": "E-mail",
  "email_phone_collection.field_phone_header": "Phone number",
  "email_phone_collection.header": "Contact details",
  "email_phone_collection.phone_validation_failed": "Incorrect phone number.",
  "high_load.body": "We’re experiencing high traffic. Hang on while we load the page. If you refresh the page, it will cause further delay. ",
  "high_load.estimated_time": "Time remaining:",
  "high_load.title_loading": "Loading…",
  "high_load.title_thank_you": "Thank you for your patience!",
  "merchant_name.telia_se_store": "Telia's",
  "merchant_name.telia_se_web": "Telias",
  "merchant_name.telia_sonera_fi_001": "Telias",
  "merchant_name.telia_sonera_fi_002": "Telias",
  "merchant_name.telia_sonera_no_001": "Telias",
  "merchant_name.telia_sonera_se_001": "Telias",
  "merchant_name.telia_sonera_se_002": "Halebop's",
  "merchant_name.test_merchant_card_payment_se": "Telias",
  "merchant_name.test_merchant_se_store": "Telias",
  "merchant_name.test_merchant_se_store_ptd": "Telias",
  "merchant_name.test_merchant_se_store_ptd_sign": "Telias",
  "merchant_name.test_merchant_se_store_sign": "Telias",
  "merchant_name.test_merchant_se_web": "Telias",
  "merchant_name.test_merchant_se_web_ptd": "Telias",
  "merchant_name.test_merchant_se_web_ptd_sign": "Telias",
  "merchant_name.test_merchant_se_web_sign": "Telias",
  "month": "month",
  "month_picker.number_of_months": "{{count}} month",
  "month_picker.number_of_months_plural": "{{count}} months",
  "monthio.flow_finished": "Please wait while we take you back to your purchase. ",
  "monthio.try_again": "Try again",
  "monthio.window_is_opening": "Hang on while we open the credit form in a new window. ",
  "more_information.close_button_label": "Close",
  "more_information.paragraph_1.row_1": "No interest. ",
  "more_information.paragraph_1.row_10": "You can pay off the total amount at any time.",
  "more_information.paragraph_1.row_10.my_call": "View and manage your account on My pages via teliafinance.com.",
  "more_information.paragraph_1.row_2": "Pay the total amount on your first invoice or pay in parts up to 36 months. ",
  "more_information.paragraph_1.row_2.my_call": "No Invoice fee.",
  "more_information.paragraph_1.row_3": "Apply for credit today and use it for purchases. ",
  "more_information.paragraph_1.row_3.my_call": "You can pay off the total amount at any time.",
  "more_information.paragraph_1.row_4": "Separate invoice for your products from Telia Finance. ",
  "more_information.paragraph_1.row_4.my_call": "Pay the total amount on your first invoice, or pay in parts up to 36 months. ",
  "more_information.paragraph_1.row_5": "No invoice fee if you choose e-invoice. ",
  "more_information.paragraph_1.row_5.my_call": "Apply for credit today and use it for purchases on mycall.no. ",
  "more_information.paragraph_1.row_6": "View and manage your account on My pages via teliafinance.com.",
  "more_information.paragraph_1.row_6.my_call": "You’ll receive one invoice for the products you pay in parts from Telia Finance. The invoice for mobile subscription is sent separately from Mycall.",
  "more_information.paragraph_1.row_7": "Teliakund inkluderar registrerade Refillkunder.",
  "more_information.paragraph_1.row_7.my_call": " ",
  "more_information.paragraph_1.row_8": "Questions about BankID. Click here: <1>Mobilt BankID.</1>",
  "more_information.paragraph_1.row_8_link_1": "https://www.bankid.com/",
  "more_information.paragraph_1.row_8_link_1.my_call": " ",
  "more_information.paragraph_1.row_8.my_call": " ",
  "more_information.paragraph_1.row_9": "Bud kan hämta en direktbetald vara, men ej en delbetald vara.",
  "more_information.paragraph_1.row_9.my_call": " ",
  "more_information.paragraph_2.charge_cost_1": "0 kr",
  "more_information.paragraph_2.charge_cost_2": "0 kr",
  "more_information.paragraph_2.charge_cost_3": "0 kr",
  "more_information.paragraph_2.charge_cost_4": "0 kr",
  "more_information.paragraph_2.charge_cost_5": "49 kr",
  "more_information.paragraph_2.charge_cost_5.my_call": "0 kr",
  "more_information.paragraph_2.charge_cost_6": "0 kr",
  "more_information.paragraph_2.charge_description_1": "Administration fee",
  "more_information.paragraph_2.charge_description_2": "Borrowing rate (credit interest)",
  "more_information.paragraph_2.charge_description_3": "Registration fee ",
  "more_information.paragraph_2.charge_description_4": "E-invoice fee",
  "more_information.paragraph_2.charge_description_5": "Paper invoice fee",
  "more_information.paragraph_2.charge_description_6": " ",
  "more_information.paragraph_2.charge_title": "Fees",
  "more_information.paragraph_2.row_1": "Kund hos Telia:",
  "more_information.paragraph_2.row_2": "Administrationsavgift 0 kr",
  "more_information.paragraph_2.row_3": "Ränta 0 kr",
  "more_information.paragraph_2.row_4": "Uppläggningsavgift 0 kr",
  "more_information.paragraph_2.row_5": "Aviavgift e-faktura 0 kr",
  "more_information.paragraph_2.row_6": "Paper invoice fee 49 kr",
  "more_information.paragraph_2.row_7": " ",
  "more_information.paragraph_3.row_1": "Inte kund hos Telia:",
  "more_information.paragraph_3.row_2": "Administrationsavgift 29 kr",
  "more_information.paragraph_3.row_3": "Ränta 0 kr",
  "more_information.paragraph_3.row_4": "Uppläggningsavgift 0 kr",
  "more_information.paragraph_3.row_5": "Aviavgift e-faktura 0 kr",
  "more_information.paragraph_3.row_6": "Aviavgift pappersfaktura 49 kr",
  "more_information.paragraph_3.row_7": "Årsavgift 0 kr",
  "more_information.paragraph_4": "Telia customer? Having a subscription with Telia means you're a customer with us. This relates to all telephone, broadband and tv subscriptions. The same day you start a subscription with Telia, you become a customer.",
  "more_information.title": "Pay in parts with Telia Finance",
  "more_information.title.my_call": "Partpay with Telia Finance",
  "now": "now",
  "onboarding.terms_and_conditions_secci_checkbox_text": "By clicking on approve purchase, I approve of Telia Finance’s Credit account agreement, including <0>Terms and conditions</0> and <1>SECCI</1>. ",
  "onboarding.terms_and_conditions_secci_checkbox_text_1": "https://www.teliafinance.com/globalassets/telia-finance/documents/kreditavgifter/documents/secci-no-2021-9.pdf",
  "onboarding.terms_and_conditions_secci_checkbox_text_3": "https://www.teliafinance.com/globalassets/telia-finance/documents/kreditavgifter/documents/secci-no-en-2021-9.pdf",
  "part_payment_not_permitted_view.not_enough_amount_for_part_payment_text": "You can't pay in parts for amounts below {{amount}} {{currency}}.",
  "part_payment_not_permitted_view.title": "Unfortunately, pay in parts can't be used for this purchase.",
  "pay_in_parts_summary.betalningsservice_fee_amount": "15",
  "pay_in_parts_summary.credit_interest": "Borrowing rate (credit interest)",
  "pay_in_parts_summary.effective_interest": "Effective interest",
  "pay_in_parts_summary.increase_limit_info": "Your existing credit limit will be increased from <bold>{{previousCredit}}</bold> <bold>{{currency}}</bold> to <bold>{{newCredit}}</bold> <bold>{{currency}}</bold> to cover the purchase.",
  "pay_in_parts_summary.invoice_fee": "Invoice fee",
  "pay_in_parts_summary.invoice_fee_fine_print": "*e-invoice or e-mail invoice is free of charge, paper invoice is {{amount}} kr/pc.",
  "pay_in_parts_summary.invoice_fee_fine_print.my_call": " ",
  "pay_in_parts_summary.number_of_months": "months",
  "pay_in_parts_summary.pay_the_difference_credit_amount": "Pay in parts",
  "pay_in_parts_summary.pay_the_difference_direct_amount": "Direct payment",
  "pay_in_parts_summary.pay_the_difference_total_amount": "Amount to pay",
  "pay_in_parts_summary.payment_term": "Payment term",
  "pay_in_parts_summary.plan": "Payment plan",
  "pay_in_parts_summary.registration_fee": "Registration fee",
  "pay_in_parts_summary.title": "Summary",
  "pay_in_parts_summary.total_amount": "Total amount to pay in parts",
  "pay_in_parts.cancel_button_label": "Cancel",
  "pay_in_parts.continue_button_label": "Continue",
  "pay_in_parts.interest_text_part_1": "Total cost of credit (APR) with a representative example from 2023-04-04:",
  "pay_in_parts.interest_text_part_1.my_call": "Total cost of credit (APR) with a representative example from 02.01.2024:",
  "pay_in_parts.interest_text_part_2": "The APR for using a credit amount of <bold>10,000 SEK</bold> at 0% credit interest (fixed) with a repayment period of 24 months, with 24 instalments of <bold>416.67 SEK/month</bold> is 0% with invoice received via e-mail or e-invoice (<bold>0 SEK</bold>) or 10.94% with invoice received by post (<bold>49 SEK/pc</bold>). The total amount to repay for a credit of <bold>10,000 SEK</bold> is <bold>10,000 SEK</bold> if you have chosen a digital invoice option and <bold>11,127 SEK</bold> with paper invoice. Paying in parts with Telia Finance is totally free of interest and fees when you choose a digital invoicing option.",
  "pay_in_parts.interest_text_part_2.my_call": "The APR for using a credit amount of <bold>15,000 kr</bold> at 0% credit interest (borrowing rate) with a repayment period of 36 months, with 36 instalments of <bold>416.67 kr/month</bold> is 0%. The total amount to repay for a credit of <bold>15,000 kr</bold> is <bold>15,000 kr</bold>. Paying in parts with Telia Finance is therefore always completely free of interest and fees.",
  "pay_in_parts.interest_title": "What does it cost to pay in parts with Telia Finance?",
  "pay_in_parts.interest_title.my_call": "What does Partpay cost with Telia Finance?",
  "pay_in_parts.more_information": "More information",
  "pay_in_parts.more_information_details": "*No fees and 0% in effective interest if you choose e-invoice.",
  "pay_in_parts.more_information_details_in_store": "More information about",
  "pay_in_parts.more_information_details.my_call": "*No fees and 0% in effective interest.",
  "pay_in_parts.more_information_in_store": "pay in parts",
  "pay_in_parts.pay_button_label": "Approve purchase\n",
  "pay_in_parts.pay_strong_sign_button_label": "To signing",
  "pay_in_parts.pay_the_difference_information": "You can pay <bold>{{formattedFinanceableAmount}}</bold> of the amount in your shopping cart in parts and the remaining amount in-store with a card.",
  "pay_in_parts.pay_the_difference_information_increase_limit": "You’ve reached the maximum credit limit, you can pay <bold>{{formattedFinanceableAmount}}</bold> of the amount in your shopping cart in parts and the remaining amount in-store with a card.",
  "pay_in_parts.terms_and_conditions_credit_check_info": "By continuing, I’m aware that a credit assessment will be performed if needed for the purchase, and I have reviewed the <0>SECCI</0>.",
  "pay_in_parts.terms_and_conditions_credit_check_info_link_0": "https://files.teliafinance.com/secci-no-en.pdf",
  "pay_in_parts.terms_and_conditions_credit_check_info.my_call": "By continuing, you confirm that you’re aware that a credit assessment will be performed if needed for the purchase, and you have reviewed the <0>SECCI - Standard European Consumer Credit Information</0>.",
  "pay_in_parts.terms_and_conditions_secci": "Telia Finance’s <0>Terms and Conditions</0> and <2>SECCI</2>",
  "pay_in_parts.terms_and_conditions_secci_link_0": "https://www.teliafinance.com/globalassets/telia-finance/documents/kreditavgifter/documents/setfcc_2019-3_sv-sv.pdf",
  "pay_in_parts.terms_and_conditions_secci_link_2": "https://www.teliafinance.com/globalassets/telia-finance/documents/kreditavgifter/documents/secci-no-en-2021-9.pdf",
  "pay_in_parts.terms_and_conditions_secci_text": "By clicking on approve purchase, I approve of Telia Finance’s Credit account agreement, including <link0>Terms and conditions</link0> and <link1>SECCI</link1>. ",
  "pay_in_parts.terms_and_conditions_secci_text_link_0": "https://files.teliafinance.com/terms-and-conditions-no-en.pdf",
  "pay_in_parts.terms_and_conditions_secci_text_link_1": "https://files.teliafinance.com/secci-no-en.pdf",
  "pay_in_parts.terms_and_conditions_secci_text_link_2": "https://www.teliafinance.com/en/private/about-us/privacy",
  "pay_in_parts.terms_and_conditions_secci_text_link.telia_se_store": "https://www.telia.se/privat/om/villkor",
  "pay_in_parts.terms_and_conditions_secci_text_link.telia_sonera_se_001": "https://www.halebop.se/villkor",
  "pay_in_parts.terms_and_conditions_secci_text_link.telia_sonera_se_002": "https://www.halebop.se/villkor",
  "pay_in_parts.terms_and_conditions_secci_text.my_call": "In the next step, you approve Telia Finance’s Credit account agreement, including <link0>Terms and conditions</link0>, <link1>SECCI</link1> and <link2>Privacy policy</link2>.",
  "pay_with_card.error_message_generic": "Something went wrong",
  "pay_with_card.error_message_label": "Error:",
  "pay_with_card.pay_button_label": "PAY",
  "pay_with_card.powered_by": "Powered by Telia Finance",
  "pay_with_card.supported_cards": "The following cards are supported: Mastercard, Visa, Diners Club, Discover, JCB and V Pay.",
  "payment_method.pay_in_parts": "Pay in parts – 0% interest",
  "payment_method.pay_in_parts.my_call": "Partpay – 0% interest",
  "payment_method.pay_with_card": "Card",
  "payment_method.pay_with_card.not_supported_browser": "The browser is not supported. Please update to the latest version or try another browser.",
  "powered_by": "Powered by",
  "processing_purchase.error_please_contact_staff": "We're unable to process your order. Please contact the sales staff if you have any questions.",
  "processing_purchase.error_please_contact_support": "Oops! Something went wrong, please try again or contact customer service.",
  "processing_purchase.error_please_try_again": "Oops! Something went wrong, please try again later.",
  "processing_purchase.error_start_over": "Session time has ended and we could not proceed with your payment due to safety reasons. Please go back and try again.",
  "processing_purchase.error_support_telephone_number": "06160",
  "processing_purchase.error_support_telephone_number.my_call": "06160",
  "processing_purchase.error_try_another_payment_method": "Try again\nSession time has ended and we could not proceed with your payment. We do this for safety reasons, please go back and try again.\nStart over.",
  "processing_purchase.text": "Processing purchase",
  "processing_purchase.try_again_button_label": "Try again",
  "sign.description_app_at_same_device": "Start the BankID app to sign at Telia Finance.",
  "sign.signing": "Signing...",
  "signing.cancel": "Cancel",
  "signing.general_error": "An error occurred while signing. Please try again.",
  "signing.waiting_message": "Waiting for your signature",
  "split_payment.description_of_payment": "By clicking SIGN & PAY you will pay {{offeredCredit}} {{currency}} with part payment and {{remainingCredit}}  {{currency}} now.",
  "split_payment.pay_button_label": "SIGN & PAY",
  "thank_you_pay_in_parts.download_our_app": "With Telia Pay, you can easily pay your Delbetala invoices, keep track of future payments and reminders before the due date.",
  "thank_you_pay_in_parts.fine_print": "A paper invoice is sent to your registration address if you have not previously entered another address. You can change to email invoice in the Telia Pay app.",
  "thank_you_pay_in_parts.number_of_months": "{{count}} month",
  "thank_you_pay_in_parts.number_of_months_plural": "{{count}} months",
  "thank_you_pay_in_parts.pay_in_parts": "Pay in Parts",
  "thank_you_pay_in_parts.split_payment": "Pay in Parts + Pay now",
  "thank_you_pay_in_parts.split_payment_now": "Paid now",
  "thank_you_pay_in_parts.split_payment_part": "Pay in parts",
  "thank_you_pay_in_parts.summary_monthly_cost": "Monthly cost",
  "thank_you_pay_in_parts.summary_payment_method": "Payment method",
  "thank_you_pay_in_parts.summary_payment_term": "Payment term",
  "thank_you_pay_in_parts.summary_title": "Summary",
  "thank_you_pay_in_parts.summary_total_amount": "Total credit amount",
  "thank_you_pay_in_parts.title": "Thank you!",
  "thank_you_pay_with_card.card": "Card",
  "thank_you_pay_with_card.pay_in_parts_promo": "Visste du att du kan använda vår tjänst Telia Pay för att dela upp din betalning av produkter och tjänster från Telia? Inga räntor, inga dolda avgifter, inga konstigheter.* Läs mer på teliafinance.com.",
  "thank_you_pay_with_card.pay_in_parts_promo_fine_print": "*Uppläggningsavgiften är alltid 0 kr, nominella räntan är alltid 0%. Aviavgiften är 0 kr om du har valt e-mail faktura eller e-faktura, men pappersfaktura kostar 49 kr. Exempel om email faktura har valts: Ett köp på 10 000 kr som betalas över 12 månader har en total betalsumma på 10 000 kronor och effektiv ränta på 0%.",
  "thank_you_pay_with_card.summary_payment_method": "Payment method",
  "thank_you_pay_with_card.summary_title": "Summary",
  "thank_you_pay_with_card.title": "Thank you!",
  "thank_you_pay_with_card.total_amount": "Total credit amount",
  "try_another_payment_method": "Unfortunately, we can't offer you pay in parts. Please try another payment method.",
  "your_details.cancel": "Cancel",
  "your_details.continue": "Continue",
  "your_details.save": "Save",
  "your_details.saving": "Saving",
  "your_details.ssn_helper_text": "Identify yourself with BankID so we can see if you already have an account with Telia Finance.",
  "your_details.ssn_label": "Personal identity number",
  "your_details.ssn_placeholder": "DDMMYYYYXXXX",
  "your_details.title": "Your details"
};