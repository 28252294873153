import paymentApiHelpers from './paymentApiHelpers';

const getPaymentMethods = async (
  purchaseRequestToken,
  correlationId,
  fetch = window.fetch
) => {
  try {
    const response = await fetch(`${paymentApiHelpers.getBaseUrl()}/methods`, {
      method: 'GET',
      headers: paymentApiHelpers.setHeaders(purchaseRequestToken, correlationId)
    });
    return await response.json();
  } catch (error) {
    return null;
  }
};

export default getPaymentMethods;
