const ROUTES = {
  PAYMENT_METHOD: '/method',
  PAYMENT_METHOD_PART_PAYMENT: '/method/account',
  AUTHENTICATED: '/finish/auth',
  CARD_SECURE_COMPLETE: '/finish/3ds',
  THANK_YOU_PART_PAYMENT: '/thank-you/account',
  THANK_YOU_CARD_PAYMENT: '/thank-you/card',
  REDIRECT_AUTH: '/redirect-auth'
};

export default ROUTES;
