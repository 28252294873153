import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { spacing } from '@teliads/components/foundations';
import Loading from '../Loading';
import {
  ContinueButton,
  SecondaryButton
} from '../../styled-components/styledcomponents';
import {
  monthioCancel,
  monthioOpenAgain,
  monthioPollOnce
} from '../../actions/applicationActions';
import PoweredByTeliaFinance from '../PoweredByTeliaFinance';

const MonthioRedirect = () => {
  const {
    redirectUrl,
    monthioPollFinished,
    monthioCompletedError,
    monthioCompletedDone
  } = useSelector((state) => state.app);
  const [windowRef, setWindowRef] = useState(null);
  const [isMonthioWindowOpen, setIsMonthioWindowOpen] = useState(false);
  const [finalPollDispatched, setFinalPollDispatched] = useState(false);
  const [clickedReopen, setClickedReopen] = useState(false);
  const [monthioCompletedLoading, setMonthioCompletedLoading] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onCancelMonthio = () => {
    windowRef?.close();
    dispatch(monthioCancel());
  };

  useEffect(() => {
    if (windowRef && redirectUrl) {
      windowRef.location = redirectUrl;
    }
  }, [redirectUrl, windowRef]);

  // Check with an interval if window has been closed in any way
  useEffect(() => {
    const timer = setInterval(() => {
      if (windowRef?.closed) {
        setIsMonthioWindowOpen(false);
        setFinalPollDispatched(true);
        setMonthioCompletedLoading(true);
        setTimeout(() => {
          dispatch(monthioPollOnce());
        }, 1000);
        clearInterval(timer);
      }
    }, 1000);
  }, [isMonthioWindowOpen]);

  const initWindowReference = () => {
    setWindowRef(window.open());
  };

  useEffect(() => {
    initWindowReference();
  }, []);

  useEffect(() => {
    if (windowRef) {
      setIsMonthioWindowOpen(true);
    }
  }, [windowRef]);

  const openMonthio = useCallback(() => {
    if (!windowRef) {
      dispatch(monthioOpenAgain());
      initWindowReference();
      setClickedReopen(false);
    }
  }, [clickedReopen]);

  useEffect(() => {
    // Close window if Monthio flow ends in some way
    // (DONE_COMPLETED or DONE_ERROR)
    if (windowRef && (monthioPollFinished || monthioCompletedError)) {
      windowRef.close();
    }
  }, [monthioPollFinished, monthioCompletedError]);

  useEffect(() => {
    if (monthioCompletedDone) {
      setMonthioCompletedLoading(true);
    }
  }, [monthioCompletedDone]);

  if (monthioCompletedLoading) {
    return (
      <>
        <LoadingContainer data-cy="MonthioLoading">
          <LoadingWrapper>
            <Loading />
          </LoadingWrapper>
        </LoadingContainer>
        {t('monthio.flow_finished', {
          defaultValue: 'Please wait while we take you back to your purchase.'
        })}
      </>
    );
  }

  return isMonthioWindowOpen ? (
    <>
      <LoadingContainer data-cy="MonthioLoading">
        <LoadingWrapper>
          <Loading />
        </LoadingWrapper>
      </LoadingContainer>
      {t('monthio.window_is_opening', {
        defaultValue: 'Please wait while we take you back to your purchase.'
      })}
      <ContinueButton
        data-cy="MonthioButtonContinue"
        disabled={windowRef}
        onClick={openMonthio}
      >
        {t('monthio.try_again', { defaultValue: 'Try again' })}
      </ContinueButton>
      <SecondaryButton data-cy="MonthioButtonCancel" onClick={onCancelMonthio}>
        {t('authenticating.cancel')}
      </SecondaryButton>
    </>
  ) : (
    <>
      <ContinueButton onClick={openMonthio}>
        {t('monthio.try_again', { defaultValue: 'Try again' })}
      </ContinueButton>
      <SecondaryButton onClick={onCancelMonthio}>
        {t('authenticating.cancel')}
      </SecondaryButton>
    </>
  );
};

const Monthio = () => {
  const { country } = useSelector((state) => state.app);

  if (country === 'DK') {
    return (
      <FlexCenterColumn>
        <MonthioRedirect />
        <PoweredByTeliaFinance />
      </FlexCenterColumn>
    );
  }

  return <></>;
};

const LoadingWrapper = styled.div`
  width: 50px;
  display: flex;
  align-items: center;
`;

const LoadingContainer = styled.div`
  margin-top: ${spacing.spacing16};
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
`;

const FlexCenterColumn = styled.div.attrs({
  className: 'FlexCenterColumn'
})`
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: ${spacing.spacing16};

  button {
    margin-bottom: ${spacing.spacing8};
  }
`;

export default Monthio;
