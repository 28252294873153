import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { cancelPayment } from '../actions/applicationActions';
import { SecondaryButton } from '../styled-components/styledcomponents';

const CancelButton = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const onCancelButtonClick = () => {
    dispatch(cancelPayment());
  };

  return (
    <SecondaryButton
      onClick={onCancelButtonClick}
      data-cy="CancelPaymentButton"
    >
      {t('pay_in_parts.cancel_button_label')}
    </SecondaryButton>
  );
};

export default CancelButton;
