import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { spacing, typography } from '@teliads/components/foundations';
import { useSelector } from 'react-redux';
import PoweredByTeliaFinance from './PoweredByTeliaFinance';
import { decimalFormatAmount, formatCurrency } from '../helpers/amount';
import TeliaIconShopping from './graphics/TeliaIconShopping';
import { orderAmountTooLowRedirectClicked } from '../actions/eventActions';
import FLOWS from '../constants/flows';
import ReturnToPaymentMethodsButton from './ReturnToPaymentMethodsButton';

const PartPaymentNotPermittedView = ({ currency }) => {
  const { t } = useTranslation();
  const { flow } = useSelector((state) => state.app);
  const {
    customerState,
    partPaymentMinimumAmount: partPaymentMinimumAmountFromConfig
  } = useSelector((state) => state.app);
  const { partPaymentMinimumAmount } = customerState ?? {};

  return (
    <>
      <NotPermittedViewWrapper data-cy="PartPaymentNotPermittedView">
        <IconContainer>
          <TeliaIconShopping />
        </IconContainer>
        <HeaderText>{t('part_payment_not_permitted_view.title')}</HeaderText>
        <GuidanceText>
          {t(
            'part_payment_not_permitted_view.not_enough_amount_for_part_payment_text',
            {
              amount: decimalFormatAmount(
                partPaymentMinimumAmount ?? partPaymentMinimumAmountFromConfig
              ),
              currency: formatCurrency(currency)
            }
          )}
        </GuidanceText>
        {flow === FLOWS.WEB && (
          <ReturnToPaymentMethodsButton
            eventToEmit={orderAmountTooLowRedirectClicked}
          />
        )}
      </NotPermittedViewWrapper>
      <PoweredByTeliaFinance />
    </>
  );
};

const IconContainer = styled.div.attrs({
  className: 'NotPermittedViewWrapper'
})`
  margin: ${spacing.spacing48} 0 0 0;
`;

const NotPermittedViewWrapper = styled.div.attrs({
  className: 'NotPermittedViewWrapper'
})`
  width: 100%;
  max-width: ${(p) => p.theme.width.innerMax};
  margin: 0 0 ${spacing.spacing128} 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  /*Center horizontaly ie */
  -ms-flex-pack: center;
`;

const HeaderText = styled.h1.attrs({ className: 'HeaderText' })`
  margin: ${spacing.spacing12} 0 0 0;
  font-size: ${(p) => p.theme.fontSize.mediumSmall};
  line-height: 1.25;
  font-weight: ${typography.weightBold};
  display: block;
  text-align: center;
  white-space: pre-wrap;
  width: 100%;
`;

const GuidanceText = styled.p.attrs({ className: 'GuidanceText' })`
  margin: ${spacing.spacing4} 0 ${spacing.spacing32} 0;
  font-size: ${(p) => p.theme.fontSize.base};
  display: block;
  text-align: center;
  white-space: pre-wrap;
  width: 100%;
`;

PartPaymentNotPermittedView.propTypes = {
  currency: PropTypes.string.isRequired
};

export default PartPaymentNotPermittedView;
