import styled from 'styled-components';
import { colors, spacing, typography } from '@teliads/components/foundations';

export const Title = styled.h1.attrs({
  className: 'Title'
})`
  margin: ${spacing.spacing12} ${spacing.spacing12} ${spacing.spacing16}
    ${spacing.spacing12};
  font-family: ${(p) => p.theme.font.primary};
  font-size: ${(p) => p.theme.fontSize.medium};
  width: 100%;
  font-weight: ${typography.weightBold};
  line-height: 32px;
`;

export const Title2 = styled.h2.attrs({
  className: 'Title2'
})`
  font-size: 20px;
  width: 100%;
  margin: 0;
`;

export const Title3 = styled.h3.attrs({
  className: 'Title3'
})`
  margin: 0;
`;

export const Title4 = styled.h4.attrs({
  className: 'Title4'
})`
  display: flex;
  background-color: transparent;
  padding: 0;
  margin: 0;
  font-family: ${(p) => p.theme.font.primary};
  font-size: ${(p) => p.theme.fontSize.small};
  font-weight: bold;
  align-items: center;
  border: none;
  line-height: ${5 / 3};
`;

export const BodyText = styled.p.attrs({ className: 'BodyText' })`
  margin: ${spacing.spacing8} 0 0 0;
  font-size: ${(p) => p.theme.fontSize.small};
  text-decoration: none;
`;

export const BodyTextGray = styled(BodyText)`
  color: ${colors.gray600};
`;

export const BodyTextBlack = styled(BodyText)`
  color: ${(p) => p.theme.color.core.black};
`;

export const ThankYouPageBodyText = styled.p.attrs({
  className: 'ThankYouPageBodyText'
})`
  margin: ${(p) => p.theme.margin.unit.double} 0
    ${(p) => p.theme.margin.unit.quadruple} 0;
  font-size: ${(p) => p.theme.fontSize.small};
  line-height: 1.5;
  @media (min-width: ${(p) => p.theme.breakpoint.desktop}) {
    font-size: ${(p) => p.theme.fontSize.base};
  }
`;

export const SmallTextCentered = styled.p.attrs({
  className: 'SmallTextCentered'
})`
  margin: 0;
  font-size: ${(p) => p.theme.fontSize.small};
  display: block;
  text-align: center;
  width: 100%;
`;

export const FinePrint = styled.p.attrs({ className: 'FinePrint' })`
  margin: 0;
  padding: 0 ${spacing.spacing8};
  font-size: ${(p) => p.theme.fontSize.xsmall};
  color: ${colors.gray600};
  line-height: ${spacing.spacing16};
`;

export const Button = styled.button.attrs({
  className: 'Button'
})`
  width: 100%;
  height: 48px;
  margin: ${spacing.spacing24} 0 0 0;
  display: block;
  border-radius: ${(p) => p.theme.borderRadius.button};
  border: none;
  color: ${({ theme }) => theme.color.primaryTextColor};
  background-color: ${({ theme }) => theme.color.primary};
  font-family: ${(p) => p.theme.font.primary};
  font-size: ${(p) => p.theme.fontSize.base};
  font-weight: ${typography.weightMedium};
  letter-spacing: 0.4px;
  cursor: ${(p) => (p.disabled ? 'default' : 'pointer')};
  &:hover {
    background-color: ${({ theme }) => theme.color.primaryHover};
  }
  &:disabled {
    background-color: ${({ theme }) => theme.color.primaryDisabled}
    color: ${colors.gray600};
    cursor: default;
  }
`;

export const SecondaryButton = styled(Button).attrs({
  className: 'SecondaryButton'
})`
  background-color: ${({ theme }) => theme.color.secondaryButton};
  margin: 0 0 0 0;
  color: ${({ theme }) => theme.color.secondaryButtonTextColor};
  border: 1px solid ${({ theme }) => theme.color.secondaryButtonBorder};
  &:hover {
    color: ${({ theme }) => theme.color.secondaryButtonTextColorHover};
    background-color: ${({ theme }) => theme.color.secondaryButtonHover};
  }
  &:disabled {
    color: ${colors.gray800};
    background-color: ${({ theme }) => theme.color.secondaryButtonDisabled};
  }
`;

export const ContinueButton = styled(Button).attrs({
  className: 'ContinueButton'
})``;

export const ExpressiveButton = styled(Button).attrs({
  className: 'ExpressiveButton'
})`
  transition: all 0.1s ease-in-out;
  background-color: ${({ theme }) => theme.color.expressiveButton};
  color: ${({ theme }) => theme.color.expressiveButtonTextColor};
  &:hover {
    background-color: ${({ theme }) => theme.color.expressiveButtonHover};
  }
  &:focus:not(:active) {
    background-color: ${({ theme }) => theme.color.expressiveButtonFocus};
  }
  &:disabled {
    background-color: ${({ theme }) => theme.color.expressiveButtonDisabled};
    color: ${colors.gray800};
  }
`;

export const ExpressiveLinkButton = styled.a.attrs({
  className: 'ExpressiveLinkButton'
})`
  width: 100%;
  height: auto;
  margin: ${spacing.spacing24} 0 0 0;
  padding: ${spacing.spacing16} 0 ${spacing.spacing16} 0;
  display: inline-block;
  border-radius: ${(p) => p.theme.borderRadius.button};
  border: none;
  font-family: ${(p) => p.theme.font.primary};
  font-size: ${(p) => p.theme.fontSize.base};
  font-weight: ${typography.weightMedium};
  letter-spacing: 0.4px;
  cursor: ${(p) => (p.disabled ? 'default' : 'pointer')};
  transition: all 0.1s ease-in-out;
  background-color: ${({ theme }) => theme.color.expressiveButton};
  color: ${({ theme }) => theme.color.expressiveButtonTextColor};
  text-decoration: none;
  &:hover {
    background-color: ${({ theme }) => theme.color.expressiveButtonHover};
  }
  &:focus:not(:active) {
    background-color: ${({ theme }) => theme.color.expressiveButtonFocus};
  }
  &:disabled {
    background-color: ${({ theme }) => theme.color.primaryDisabled}
    cursor: default;
    background-color: ${({ theme }) => theme.color.expressiveButtonDisabled};
    color: ${colors.gray800};
  }
`;

export const Column = styled.div`
  width: 100%;
  max-width: ${(p) => p.theme.width.innerMax};
  margin: 0;
`;

export const SummaryRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin: ${spacing.spacing4} 0 ${spacing.spacing2} 0;
  line-height: 1;
  font-size: ${(p) => p.theme.fontSize.small};
  align-items: center;
  color: ${colors.gray600};
`;

export const SummaryRowTotal = styled.div`
  display: flex;
  justify-content: space-between;
  margin: ${spacing.spacing8} 0 0 0;
  line-height: 1;
  font-size: ${(p) => p.theme.fontSize.small};
  align-items: center;
  color: ${colors.gray600};
  color: ${(p) => p.theme.color.core.black};
  font-weight: ${typography.weightBold};
`;

export const Key = styled.p.attrs({ className: 'Key' })`
  margin: 0;
  padding: 0;
  text-align: left;
  flex-grow: 1;
  line-height: 1.5;
`;

export const Value = styled.p.attrs({ className: 'Value' })`
  margin: 0;
  padding: 0;
  text-align: left;

  > span {
    display: inline-block;
    margin: 0 4px 0 4px;
  }

  > span.currency,
  span.percent {
    text-align: right;
  }
`;

export const ThankYouWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: ${(p) => p.theme.borderThickness.paymentMethod} solid ${colors.gray50};
  padding: 16px 20px 40px 20px;
  margin: 0 0 ${(p) => p.theme.margin.unit.base} 0;
  position: relative;
  background: ${({ theme }) => theme.color.mainLayerBackground};
`;

export const TermsAndConditions = styled.p.attrs({
  className: 'TermsAndConditions'
})`
  width: 100%;
  padding: 0 ${spacing.spacing8};
  margin: 0 0 ${spacing.spacing20} 0;
  color: ${colors.gray600};
  font-size: ${(p) => p.theme.fontSize.xsmall};
  line-height: 16px;
`;

export const SecciAndTermsConditionLink = styled.a.attrs({
  className: 'SecciAndTermsConditionLink'
})`
  color: ${({ theme }) => theme.color.primary};
  text-decoration: underline;
  &:hover {
    color: ${({ theme }) => theme.color.primaryHover};
  }
`;

export const AuthAndSignWrapper = styled.div.attrs((props) => ({
  className: props.wrapperClassName
}))`
  width: 100%;
  max-width: ${(p) => p.theme.width.innerMax};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  /*Center horizontaly ie */
  -ms-flex-pack: center;

  min-height: 300px;
  height: 100px;
`;

export const BoldText = styled.p.attrs({ className: 'BoldText' })`
  margin: ${(p) => p.theme.margin.unit.double} 0;
  font-size: ${(p) => p.theme.fontSize.base};
  font-weight: bold;
  display: block;
  text-align: center;
  width: 100%;
`;

export const TeliaFinanceText = styled.span.attrs({
  className: 'TeliaFinanceText'
})`
  font-weight: bold;
  font-family: ${(p) => p.theme.font.primary};
  font-size: ${(p) => p.theme.fontSize.small};
  color: ${(p) => p.theme.color.core.black};
  @media (min-width: ${(p) => p.theme.breakpoint.desktop}) {
    font-size: ${(p) => p.theme.fontSize.base};
  }
`;

export const Line = styled.div`
  border-top: 1px solid ${({ theme }) => theme.color.lineColor};
  width: 100%;
  margin-top: ${({ marginTop }) => marginTop};
  margin-bottom: ${({ marginBottom }) => marginBottom};
  margin-left: ${spacing.spacing8};
  margin-right: ${spacing.spacing8};
`;

export const PaymentMethodTitle = styled.h3.attrs({
  className: 'PaymentMethodTitle'
})`
  margin: 0;
  flex-grow: 1;
  font-size: ${(p) => p.theme.fontSize.base};
  @media (min-width: ${(p) => p.theme.breakpoint.desktop}) {
    font-size: ${(p) => p.theme.fontSize.base};
  }
`;

export const MonthItem = styled.button.attrs({ className: 'MonthItem' })`
  padding: 0;
  margin: 0;
  text-align: center;
  flex-grow: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 24px;
  font-family: ${(p) => p.theme.font.primary};
  font-size: ${(p) => p.theme.fontSize.small};
  font-weight: 400;

  border: ${({ theme }) =>
    `${theme.borderThickness.monthItem} solid ${theme.color.monthItemColor}`};

  background-color: ${({ theme, isSelected }) =>
    // Investingate replacing with purpur primary
    isSelected ? theme.color.monthItemColor : theme.color.white};

  color: ${({ theme, isSelected }) =>
    isSelected ? theme.color.primaryTextColor : theme.color.monthItemColor};

  /* Justify content fix for safari v. >11 */
  display: -webkit-box;
  -webkit-box-pack: center; /* justify-content */
  -webkit-box-align: center; /* align-items */

  cursor: ${(p) => (p.isSelected ? 'default' : 'pointer')};

  border-radius: ${(p) => p.theme.borderRadius.monthItem};

  @media (min-width: ${(p) => p.theme.breakpoint.desktop}) {
    font-size: ${(p) => p.theme.fontSize.base};
  }
`;

export const StrongBold = styled.strong.attrs({
  className: 'CreditLimitInformationSpacing'
})`
  color: ${(p) => p.theme.color.core.black};
`;

export const CancelButtonContainer = styled.div`
  margin-top: ${spacing.spacing16};
  width: 100%;
`;

export const TextButton = styled.button.attrs({
  className: 'TextButton'
})`
  padding: ${spacing.spacing16} 0;
  margin-left: 3px;
  line-height: 1;
  font-size: ${(p) => p.theme.fontSize.base};
  border: none;
  background-color: transparent;
  color: ${({ theme }) => theme.color.primary};
  display: inline-block;
  cursor: pointer;
  text-decoration: underline;
  &:hover {
    color: ${({ theme }) => theme.color.primaryHover};
  }
`;

export const TextLink = styled.a.attrs({
  className: 'TextLink'
})`
  padding: ${spacing.spacing16} 0;
  margin-left: 3px;
  line-height: 1;
  font-size: ${(p) => p.theme.fontSize.base};
  border: none;
  background-color: transparent;
  color: ${({ theme }) => theme.color.primary};
  display: inline-block;
  cursor: pointer;
  text-decoration: underline;
  &:hover {
    color: ${({ theme }) => theme.color.primaryHover};
  }
`;
