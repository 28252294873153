import globals from '../globals';
import UnexpectedServerResponse from '../UnexpectedServerResponse';
import { AUTHENTICATION_ALREADY_IN_PROGRESS } from '../../constants/actions';

const sbidStart = async (
  language,
  ssn,
  correlationId,
  purchaseRequestToken = null, // Only needed for logs
  fetch = window.fetch
) => {
  const response = await fetch(`${globals.authSbidStart}`, {
    method: 'POST',
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Correlation-ID': correlationId,
      'Purchase-Request-Token': purchaseRequestToken
    },
    body: JSON.stringify({
      pinCode: false,
      personalNumber: ssn,
      formatText: false
    })
  });
  switch (response.status) {
    case 200: {
      const { token, autoStartToken } = await response.json();
      if (!token) {
        throw new UnexpectedServerResponse(response, 'Received no token');
      }
      return { token, autoStartToken };
    }
    case 409:
      return { status: AUTHENTICATION_ALREADY_IN_PROGRESS };
    default:
      throw new UnexpectedServerResponse(response);
  }
};

export default sbidStart;
