import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import usePhraseFormattedKeyFromMerchantId from './usePhraseFormattedKeyFromMerchantId';

/**
 * Allow use of merchant specific translation keys in same way as
 * { t } from "useTranslation()" with { merchantTranslation }. Also provide
 * { merchantTranslationKey } that can be used when using more
 * advanced features like <Trans /> component.
 *
 * Currently only MyCall is supported.
 *
 * @returns {{merchantTranslation: (function(*): *),
 * merchantTranslationKey: ((function(*): (string))|*)}}
 */
const useMerchantSpecificTranslationKey = () => {
  const { merchantId } = useSelector((state) => state.app);
  const { t } = useTranslation();

  const merchantSpecificTranslationKey = (key) => {
    if (merchantId === 'MyCall') {
      const phraseFormattedKeyFromMerchantId =
        usePhraseFormattedKeyFromMerchantId(key, merchantId);

      // Check if translation exist for key otherwise fallback to default key.
      if (t(phraseFormattedKeyFromMerchantId)) {
        return usePhraseFormattedKeyFromMerchantId(key, merchantId);
      }
      return key;
    }

    return key;
  };

  return {
    merchantTranslationKey: merchantSpecificTranslationKey,
    merchantTranslation: (key, options = {}) =>
      t(merchantSpecificTranslationKey(key), options)
  };
};

export default useMerchantSpecificTranslationKey;
