import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './en.json';
import sv from './sv-SE.json';
import da from './da-DK.json';
import fi from './fi-FI.json';
import nb from './nb-NO.json';

import { DEFAULT_LANGUAGE } from '../constants';

export const resources = {
  en: {
    translation: en
  },
  sv: {
    translation: sv
  },
  da: {
    translation: da
  },
  fi: {
    translation: fi
  },
  nb: {
    translation: nb
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: DEFAULT_LANGUAGE,
    fallbackLng: DEFAULT_LANGUAGE,
    keySeparator: false, // we do not use keys in form messages.welcome
    parseMissingKeyHandler: () => {
      return '';
    },
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
