import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { colors } from '@teliads/components/foundations';

const RadioButton = ({ isSelected }) => (
  <OutlinedCircle isSelected={isSelected}>
    <SolidCircle isSelected={isSelected} />
  </OutlinedCircle>
);
export default RadioButton;

RadioButton.propTypes = {
  isSelected: PropTypes.bool.isRequired
};

export const OutlinedCircle = styled.div.attrs({
  className: 'OutlinedCircle'
})`
  width: 26px;
  height: 26px;
  border-radius: 100%;
  border: ${({ theme }) =>
    `${theme.borderThickness.radioButton} solid ${colors.purple850}`};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 16px 0 0;
  background-color: ${colors.white};
  border: 1px solid ${colors.purple850};

  &:focus {
    border: 1px solid ${({ theme }) => theme.color.primaryHover};
  }
  &:hover {
    cursor: pointer;
  }
`;

export const SolidCircle = styled.div.attrs({ className: 'SolidCircle' })`
  width: 16px;
  height: 16px;
  background-color: ${colors.purple850};
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  display: ${(p) => (p.isSelected ? 'inline-flex' : 'none')};
`;
