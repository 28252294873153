import { PatternFormat } from 'react-number-format';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import AuthenticationErrorBox, {
  errorBoxTypes
} from '../AuthenticationErrorBox';
import { InputLabel } from './CollectionStyles';

const PhoneField = ({ apiError, phoneValidation, validatePhone }) => {
  const { t } = useTranslation();
  const { mobile: customerMobile } = useSelector((state) => state.app);
  const [mask, setMask] = useState('X');
  const pattern = '+## ## ### ## ##';

  useEffect(() => {
    if (customerMobile?.length > 0) {
      validatePhone(customerMobile);
    }
  }, []);

  return (
    <>
      <InputLabel>
        {t('email_phone_collection.field_phone_header', {
          defaultValue: '* Phone number'
        })}
        <PatternFormat
          onValueChange={(values) => {
            validatePhone(`+${values.floatValue}`);
            setMask(values.value.length > 6 ? '' : 'X');
          }}
          defaultValue={customerMobile ?? '+'}
          format={pattern}
          allowEmptyFormatting
          mask={mask}
          data-cy="InputPhone"
        />
      </InputLabel>
      {(apiError.phoneNotValid || !phoneValidation.isPhoneValid) && (
        <AuthenticationErrorBox
          errorText={t('email_phone_collection.phone_validation_failed', {
            defaultValue: 'Use a valid phone number'
          })}
          type={errorBoxTypes.inputField}
        />
      )}
    </>
  );
};

PhoneField.propTypes = {
  apiError: PropTypes.shape({
    phoneNotValid: PropTypes.bool
  }).isRequired,
  phoneValidation: PropTypes.shape({
    isPhoneValid: PropTypes.bool
  }).isRequired,
  validatePhone: PropTypes.func.isRequired
};

export default PhoneField;
