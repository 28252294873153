import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectPaymentDetailsPartPayment } from '../selectors/selectors';
import { webcheckoutCompleteSuccessEvent } from '../actions/eventActions';

const ThankYouPartPayment = () => {
  const paymentDetails = useSelector(selectPaymentDetailsPartPayment);
  const { customerDetails } = useSelector((state) => state.app);
  const { financeableAmount, offLimitAmountToPay } = useSelector(
    (state) => state.partPayment
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      webcheckoutCompleteSuccessEvent(
        paymentDetails,
        offLimitAmountToPay,
        financeableAmount,
        customerDetails
      )
    );
  }, []);

  return <></>;
};

export default ThankYouPartPayment;
