import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { typography, spacing } from '@teliads/components/foundations';
import TeliaIconError from '../graphics/TeliaIconError';
import { webcheckoutCompleteCardErrorEvent } from '../../actions/eventActions';

const CardPaymentError = ({ transactionResult }) => {
  const [valuesToDisplay, setValuesToDisplay] = useState(transactionResult);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const defaultError = {
    status: 'failed',
    reason: t('processing_purchase.error_please_contact_support', {
      defaultValue:
        'Oops! Something went wrong, please try again or contact customer service.'
    })
  };

  useEffect(() => {
    if (transactionResult === null || transactionResult.reason === 'default') {
      setValuesToDisplay(defaultError);
    } else {
      setValuesToDisplay(transactionResult);
    }
  }, [transactionResult]);

  useEffect(() => {
    if (transactionResult) {
      dispatch(webcheckoutCompleteCardErrorEvent());
    }
  }, [transactionResult]);

  return (
    <>
      {transactionResult && (
        <ErrorView>
          <>
            <TeliaIconError width={64} />
            <Header>{t('card.general_error')}</Header>
            {valuesToDisplay.reason && (
              <>
                <Body>{valuesToDisplay.reason}</Body>
              </>
            )}
          </>
        </ErrorView>
      )}
    </>
  );
};

const ErrorView = styled.div`
  margin: ${spacing.spacing128} 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Header = styled.h2`
  margin: ${spacing.spacing32} 0 ${spacing.spacing8} 0;
  font-size: ${(p) => p.theme.fontSize.base};
  font-weight: ${typography.weightBold};
`;

const Body = styled.p`
  margin: 0;
  text-align: center;
`;

CardPaymentError.propTypes = {
  transactionResult: PropTypes.object
};

CardPaymentError.defaultProps = {
  transactionResult: null
};

export default CardPaymentError;
