import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { spacing, borders } from '@teliads/components/foundations';
import {
  Title4,
  Key,
  Value,
  FinePrint,
  SummaryRow,
  SummaryRowTotal
} from '../styled-components/styledcomponents';
import {
  autoFormatAmount,
  formatCurrency,
  decimalFormatAmount,
  integerFormatAmount,
  currencyAddStkIfDK
} from '../helpers/amount';
import PayTheDifferenceTotalSummary from './PayTheDifferenceTotalSummary';
import useMerchantSpecificTranslationKey from '../hooks/useMerchantSpecificTranslationKey';

const PartPaymentSummary = ({
  creditGrantedForPayTheDifference,
  optionData
}) => {
  const { isLockedMonth, country } = useSelector((state) => state.app);
  const { t } = useTranslation();
  const { merchantTranslation } = useMerchantSpecificTranslationKey();

  return (
    <>
      <PartPaymentSummaryWrapper className="Summary">
        <PartPaymentSummaryTitle data-cy="SummaryTitle">
          {t('pay_in_parts_summary.title')}
        </PartPaymentSummaryTitle>
        <SummaryRow data-cy="SummaryRegistrationFee">
          <Key>{t('pay_in_parts_summary.registration_fee')}</Key>
          <Value>
            {optionData.startupFee}
            <span className="currency">
              {formatCurrency(optionData.currency)}
            </span>
          </Value>
        </SummaryRow>
        <SummaryRow data-cy="SummaryInvoiceFee">
          <Key>{t('pay_in_parts_summary.invoice_fee')}</Key>
          <Value>
            {country === 'DK' &&
              t('pay_in_parts_summary.betalningsservice_fee_amount', {
                defaultValue: '15'
              })}
            {country !== 'DK' && `${optionData.adminFee}`}
            <span className="currency">
              {currencyAddStkIfDK(formatCurrency(optionData.currency))}
            </span>
          </Value>
        </SummaryRow>
        {t('pay_in_parts_summary.invoice_fee_2') && (
          <SummaryRow data-cy="SummaryInvoiceFee">
            <Key>{t('pay_in_parts_summary.invoice_fee_2')}</Key>
            <Value>
              {`${integerFormatAmount(optionData.billingFeePaper)}`}
              <span className="currency">
                {currencyAddStkIfDK(formatCurrency(optionData.currency))}
              </span>
            </Value>
          </SummaryRow>
        )}
        <SummaryRow data-cy="SummaryCreditInterest">
          <Key>{t('pay_in_parts_summary.credit_interest')}</Key>
          <Value>
            {optionData.interest}
            <span className="percent">%</span>
          </Value>
        </SummaryRow>
        {isLockedMonth && (
          <SummaryRow data-cy="SummaryPlan">
            <Key>{t('pay_in_parts_summary.plan')}</Key>
            <Value>
              {optionData.downPayTime}
              <span>{t('pay_in_parts_summary.number_of_months')}</span>
            </Value>
          </SummaryRow>
        )}
        {creditGrantedForPayTheDifference ? (
          <PayTheDifferenceTotalSummary totalCost={optionData.totalCost} />
        ) : (
          <SummaryRowTotal data-cy="SummaryTotalAmount">
            <Key>{t('pay_in_parts_summary.total_amount')}</Key>
            <Value>
              {`${decimalFormatAmount(optionData.totalCost)}`}
              <span className="currency">
                {formatCurrency(optionData.currency)}
              </span>
            </Value>
          </SummaryRowTotal>
        )}
      </PartPaymentSummaryWrapper>
      <PartPaymentInvoiceInformation>
        <FinePrint>
          {merchantTranslation('pay_in_parts_summary.invoice_fee_fine_print', {
            amount: autoFormatAmount(optionData.billingFeePaper)
          })}
        </FinePrint>
      </PartPaymentInvoiceInformation>
    </>
  );
};
export default PartPaymentSummary;

PartPaymentSummary.propTypes = {
  creditGrantedForPayTheDifference: PropTypes.bool.isRequired,
  optionData: PropTypes.shape({
    downPayTime: PropTypes.number.isRequired,
    startupFee: PropTypes.number.isRequired,
    adminFee: PropTypes.number.isRequired,
    billingFeePaper: PropTypes.number.isRequired,
    interest: PropTypes.number.isRequired,
    effectiveInterest: PropTypes.number.isRequired,
    monthlyCost: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
    totalCost: PropTypes.number.isRequired
  }).isRequired
};

const PartPaymentSummaryWrapper = styled.div.attrs({
  className: 'PartPaymentSummaryWrapper'
})`
  margin-top: ${spacing.spacing0};
  margin-bottom: ${spacing.spacing12};
  font-size: ${(p) => p.theme.fontSize.xsmall};
  background-color: ${({ theme }) => theme.color.secondaryLayerBackground};
  padding: ${spacing.spacing16};
  border-radius: ${borders.radiusSm};
`;

const PartPaymentSummaryTitle = styled(Title4).attrs({
  className: 'PartPaymentSummaryTitle'
})`
  margin-bottom: 4px;
`;

const PartPaymentInvoiceInformation = styled.div.attrs({
  className: 'PartPaymentSummaryWrapper'
})`
  margin: 0;
`;
