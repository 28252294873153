import React from 'react';
import { creditDeniedRedirectClicked } from '../actions/eventActions';
import ReturnToPaymentMethodsButton from './ReturnToPaymentMethodsButton';

const CreditDeniedRedirectButton = () => {
  return (
    <ReturnToPaymentMethodsButton eventToEmit={creditDeniedRedirectClicked} />
  );
};

export default CreditDeniedRedirectButton;
