module.exports = {
  "authenticating.cancel": "Avbryt",
  "authenticating.description_app_at_other_device": "Öppna BankID-appen på din mobil eller surfplatta. Tryck på QR-symbolen i BankID-appen. Rikta kameran mot QR-koden nedanför.",
  "authenticating.description_app_at_same_device": "Starta BankID-appen för att identifiera dig hos Telia Finance.",
  "authenticating.identifying": "Identifierar...",
  "authenticating.start_app_button": "Starta BankID",
  "authenticating.start_app_text_button": "Använd BankID på denna enhet",
  "authenticating.use_app_at_other_device_text_button": "Använd BankID på en annan enhet",
  "authenticating.waiting_message": "Laddar...",
  "authentication.error_bank_id_in_use": "BankID är startat för mer än en sida. Försök igen.",
  "authentication.general_error": "Oops, något gick fel. Försök igen.",
  "authentication.identify_button_label": "Identifiera med BankID",
  "cancel_view.guidance_header": "Du har avbrutit din beställning",
  "cancel_view.guidance_text": "Vänligen kontakta kundservice om du har några frågor.",
  "cancel_view.sdk_in_store_guidance_header": "Du har avbrutit köpet",
  "cancel_view.sdk_in_store_guidance_text": "Om du har några frågor kan du kontakta butikspersonalen.",
  "circuit_breaker.body": "Det gick inte att hämta sidan! Ge det ett nytt försök efter nedräkningen nedan:",
  "circuit_breaker.button_try_again": "Försök igen",
  "circuit_breaker.minutes_postfix": "min.",
  "circuit_breaker.seconds_postfix": "sek.",
  "circuit_breaker.title": "Ett fel har inträffat",
  "credit_information.accept_credit_report_to_continue": "Du behöver ge din tillåtelse för kreditupplysning för att kunna gå vidare.",
  "credit_information.inform_about_credit_report": "Jag är medveten om att en ny kreditupplysning kan komma att tas.",
  "credit_information.sign_purchase_text": "Slutför köp",
  "credit_limit_information.could_not_update_credit_limit_title": "Tyvärr kan vi inte erbjuda delbetalning.",
  "credit_limit_information.credit_limit_to_low": "Du kan inte delbetala det här köpet eftersom ditt kreditutrymme på {{amount}} är för låg.",
  "credit_limit_information.declined_credit_redirect_button": "Avbryt",
  "credit_limit_information.increase_limit_info": "Godkänd! Du kan delbetala!",
  "credit_limit_information.kyc_block": "Du har inte fyllt i vårt kundkännedomsformulär. För att vi ska kunna erbjuda dig delbetalning behöver du besvara vårt formulär via teliafinance.com/se.",
  "credit_limit_information.maximum_amount_exceeded": "Du kan inte delbetala det här köpet eftersom beloppet överskrider den högsta kreditgräns på {{amount}} som Telia Finance erbjuder.",
  "credit_limit_information.ready_to_pay_title": "Godkänd! Du kan delbetala!",
  "credit_limit_information.split_payment_continue_button": "Fortsätt",
  "credit_limit_information.title": "Din kreditprövning",
  "credit_limit_information.unpaid_invoices": "Du har obetalda fakturor och kan därför inte delbetala ditt köp.",
  "email_phone_collection.body": "Innan du går vidare vill vi att du fyller i dina kontaktuppgifter så att vi kan nå dig med viktig information om din delbetalning.",
  "email_phone_collection.continue": "Fortsätt",
  "email_phone_collection.email_validation_failed": "Fyll i fältet med en giltig e-postadress.",
  "email_phone_collection.field_email_header": "E-post",
  "email_phone_collection.field_phone_header": "Mobilnummer",
  "email_phone_collection.header": "Kontaktuppgifter",
  "email_phone_collection.phone_validation_failed": "Felaktigt mobilnummer.",
  "high_load.body": "Vi har hög belastning just nu. Häng kvar medan sidan laddar. Om du uppdaterar sidan kommer det orsaka ytterligare fördröjning. Men oroa dig inte, din produkt är reserverad.",
  "high_load.estimated_time": "Tid kvar:",
  "high_load.title_loading": "Laddar…",
  "high_load.title_thank_you": "Tack för att du väntar!",
  "merchant_name.telia_se_store": "Telias",
  "merchant_name.telia_se_web": "Telias",
  "merchant_name.telia_sonera_se_002": "Halebops",
  "month": "månad",
  "month_picker.number_of_months": "{{count}} månader",
  "month_picker.number_of_months_plural": "{{count}} månader",
  "more_information.close_button_label": "Stäng",
  "more_information.paragraph_1.row_1": "Ingen ränta.",
  "more_information.paragraph_1.row_10": "Du kan närsomhelst betala av återstående belopp.",
  "more_information.paragraph_1.row_2": "Betala allt på första fakturan eller delbetala upp till 36 månader.",
  "more_information.paragraph_1.row_3": "Ansök om kredit idag och använd den för flera köp. ",
  "more_information.paragraph_1.row_4": "Separat faktura för dina varor från Telia Finance.",
  "more_information.paragraph_1.row_5": "Ingen fakturaavgift om du väljer e-postfaktura eller e-faktura.",
  "more_information.paragraph_1.row_6": "På Mina sidor via teliafinance.se kan du se och hantera ditt konto.",
  "more_information.paragraph_1.row_8": "Frågor om Mobilt BankID. Klicka här: <1>Mobilt BankID.</1>",
  "more_information.paragraph_1.row_8_link_1": "https://www.bankid.com/",
  "more_information.paragraph_1.row_9": "Bud kan inte hämta en delbetald vara.",
  "more_information.paragraph_2.charge_cost_1": "0 kr",
  "more_information.paragraph_2.charge_cost_2": "0 %",
  "more_information.paragraph_2.charge_cost_3": "0 kr",
  "more_information.paragraph_2.charge_cost_4": "0 kr",
  "more_information.paragraph_2.charge_cost_5": "49 kr",
  "more_information.paragraph_2.charge_cost_6": "0 kr",
  "more_information.paragraph_2.charge_description_1": "Administrationsavgift",
  "more_information.paragraph_2.charge_description_2": "Kreditränta",
  "more_information.paragraph_2.charge_description_3": "Uppläggningsavgift",
  "more_information.paragraph_2.charge_description_4": "E-faktura/e-postfaktura",
  "more_information.paragraph_2.charge_description_5": "Pappersfaktura",
  "more_information.paragraph_2.charge_description_6": "Årsavgift",
  "more_information.paragraph_2.charge_title": "Avgifter",
  "more_information.paragraph_2.row_1": "Avgifter:",
  "more_information.paragraph_2.row_2": "Administrationsavgift 0 kr",
  "more_information.paragraph_2.row_3": "Ränta 0 kr",
  "more_information.paragraph_2.row_4": "Uppläggningsavgift 0 kr",
  "more_information.paragraph_2.row_5": "Aviavgift e-faktura 0 kr",
  "more_information.paragraph_2.row_6": "Pappersfaktura 49 kr",
  "more_information.paragraph_2.row_7": " ",
  "more_information.title": "Delbetala med Telia Finance",
  "onboarding.terms_and_conditions_secci_checkbox_text": "När jag klickar på slutför köp godkänner jag Halebops villkor samt Telia Finances kontokreditavtal, inklusive Allmänna villkor och SEKKI.",
  "onboarding.terms_and_conditions_secci_checkbox_text_1": "https://www.teliafinance.com/globalassets/telia-finance/documents/kreditavgifter/documents/setfcc_2019-3_sv-sv.pdf",
  "onboarding.terms_and_conditions_secci_checkbox_text_3": "https://www.teliafinance.com/globalassets/telia-finance/documents/kreditavgifter/documents/secci_20190129_sv-sv.pdf",
  "part_payment_not_permitted_view.not_enough_amount_for_part_payment_text": "Delbetalning kan inte användas för summor under {{amount}} {{currency}}.",
  "part_payment_not_permitted_view.title": "Tyvärr kan du inte delbetala det här köpet.",
  "pay_in_parts_summary.credit_interest": "Kreditränta",
  "pay_in_parts_summary.effective_interest": "Effektiv ränta",
  "pay_in_parts_summary.increase_limit_info": "Din befintliga kreditgräns höjs från <bold>{{previousCredit}}</bold> <bold>{{currency}}</bold> till <bold>{{newCredit}}</bold> <bold>{{currency}}</bold> för att täcka ditt köp med delbetalning.",
  "pay_in_parts_summary.invoice_fee": "Fakturaavgift*",
  "pay_in_parts_summary.invoice_fee_fine_print": "* E-faktura och e-postfaktura är avgiftsfritt. Pappersfaktura kostar {{amount}} kr/st.",
  "pay_in_parts_summary.number_of_months": "månader",
  "pay_in_parts_summary.pay_the_difference_credit_amount": "Delbetalning",
  "pay_in_parts_summary.pay_the_difference_direct_amount": "Direktbetalning",
  "pay_in_parts_summary.pay_the_difference_total_amount": "Belopp att betala",
  "pay_in_parts_summary.plan": "Betalningsplan ",
  "pay_in_parts_summary.registration_fee": "Uppläggningsavgift",
  "pay_in_parts_summary.title": "Sammanfattning",
  "pay_in_parts_summary.total_amount": "Totalt belopp att delbetala",
  "pay_in_parts.cancel_button_label": "Avbryt",
  "pay_in_parts.continue_button_label": "Fortsätt",
  "pay_in_parts.example_text": "Nyttjad kontokredit på 10 000 kr till 0 % kreditränta (bunden) med en återbetalningstid på 24 mån, med 24 avbetalningar om 416,66 kr/mån ger en effektiv ränta på totalt 0 % med e-faktura/e-postfaktura (0 kr) eller 11,15 % med pappersfaktura (49 kr/st). Totalt att återbetala för kontokredit om 10 000 kr är alltså 10 000 kr om du har digital faktura och 11 127 kr om du har pappersfaktura.",
  "pay_in_parts.example_title": "Effektiv ränta (totala kostnaden för delbetalning) med representativt exempel från 2020-12-01:",
  "pay_in_parts.interest_text_part_1": "Totala kostnaden för delbetalning (effektiv ränta) med representativt exempel från 2024-01-02:",
  "pay_in_parts.interest_text_part_2": "Nyttjad kontokredit på <bold>10 000 kr</bold> till 0 % kreditränta (bunden) med en återbetalningstid på 24 mån, med 24 avbetalningar om <bold>416,67 kr/mån</bold> ger en effektiv ränta på totalt 0 % med e-faktura/e-postfaktura (<bold>0 kr</bold>) eller 10,94 % med pappersfaktura (<bold>49 kr/st</bold>). Totalt att återbetala för kontokredit om <bold>10 000 kr</bold> är alltså <bold>10 000 kr</bold> om du har digital faktura och <bold>11 127 kr</bold> om du har pappersfaktura. Delbetalning med Telia Finance är ränte- och avgiftsfri när du väljer digital faktura.",
  "pay_in_parts.interest_title": "Vad kostar delbetalning med Telia Finance? ",
  "pay_in_parts.more_information": "Mer information",
  "pay_in_parts.more_information_details": "*Inga avgifter och 0 % i effektiv ränta när du väljer e-postfaktura eller e-faktura.",
  "pay_in_parts.more_information_details_in_store": "Mer information om",
  "pay_in_parts.more_information_in_store": "delbetalning",
  "pay_in_parts.pay_button_label": "Slutför köp",
  "pay_in_parts.pay_strong_sign_button_label": "Slutför köp med BankID",
  "pay_in_parts.pay_the_difference_information": "Du kan delbetala <bold>{{formattedFinanceableAmount}}</bold> av beloppet i din varukorg och resterande belopp i butik med kort.",
  "pay_in_parts.pay_the_difference_information_increase_limit": "Du har nått den högsta kreditgränsen, du kan delbetala <bold>{{formattedFinanceableAmount}}</bold> av beloppet i din varukorg och resterande belopp i butik med kort.",
  "pay_in_parts.terms_and_conditions_credit_check_info": "Genom att fortsätta bekräftar jag att jag är medveten om att en kreditprövning utförs om det behövs för köpet och att jag har tagit del av <0>SEKKI</0>.",
  "pay_in_parts.terms_and_conditions_credit_check_info_link_0": "https://files.teliafinance.com/sekki-se.pdf",
  "pay_in_parts.terms_and_conditions_secci": "Om det behövs för att genomföra köpet, kommer vi att inhämta en kreditupplysning i samband med din ansökan.",
  "pay_in_parts.terms_and_conditions_secci_link_2": "https://www.teliafinance.com/globalassets/telia-finance/documents/kreditavgifter/documents/secci_20190129_sv-sv.pdf",
  "pay_in_parts.terms_and_conditions_secci_text": "När jag klickar på slutför köp godkänner jag {{merchantName}} <merchantLink>villkor</merchantLink> samt Telia Finances Kontokreditavtal, inklusive <link0>Allmänna villkor</link0>, <link1>SEKKI</link1> och <link2>Integritetspolicyn</link2>.",
  "pay_in_parts.terms_and_conditions_secci_text_link_0": "https://files.teliafinance.com/allmanna-villkor-se.pdf",
  "pay_in_parts.terms_and_conditions_secci_text_link_1": "https://files.teliafinance.com/sekki-se.pdf",
  "pay_in_parts.terms_and_conditions_secci_text_link_2": "https://www.teliafinance.com/se/privat/om-oss/integritet",
  "pay_in_parts.terms_and_conditions_secci_text_link.telia_se_store": "https://www.telia.se/privat/om/villkor",
  "pay_in_parts.terms_and_conditions_secci_text_link.telia_se_web": "https://www.telia.se/privat/om/villkor",
  "pay_in_parts.terms_and_conditions_secci_text_link.test_merchant_se_store_ptd": "https://www.telia.se/privat/om/villkor",
  "pay_in_parts.terms_and_conditions_secci_text_link.test_merchant_se_store_ptd_sign": "https://www.telia.se/privat/om/villkor",
  "pay_in_parts.terms_and_conditions_secci_text_link.test_merchant_se_web_ptd": "https://www.telia.se/privat/om/villkor",
  "pay_in_parts.terms_and_conditions_secci_text_link.test_merchant_se_web_ptd_sign": "https://www.telia.se/privat/om/villkor",
  "pay_with_card.error_message_label": "Fel:",
  "pay_with_card.pay_button_label": "PAY",
  "pay_with_card.powered_by": "Powered by Telia Sverige AB",
  "pay_with_card.supported_cards": "Följande kort stöds: Mastercard, Visa, Diners Club, Discover, JCB och V Pay.",
  "payment_method.pay_in_parts": "Delbetalning",
  "payment_method.pay_with_card": "Kort",
  "payment_method.pay_with_card.not_supported_browser": "Webbläsaren stöttas ej. Vänligen uppdatera till den senaste versionen eller prova en annan webbläsare.",
  "processing_purchase.error_please_contact_staff": "Oops! Något blev fel. \nVi kunde inte behandla din order på grund av tekniska problem. Vi hoppas att du försöker igen senare. Du är välkommen att kontakta butikspersonalen.",
  "processing_purchase.error_please_contact_support": "Oops! Något blev fel, försök igen eller kontakta kundservice.",
  "processing_purchase.error_please_try_again": "Oops! Något gick fel, prova igen lite senare.",
  "processing_purchase.error_support_telephone_number": "(+46) 0771 583 000",
  "processing_purchase.text": "Processar köpet",
  "processing_purchase.try_again_button_label": "Försök igen",
  "sign.description_app_at_same_device": "Starta BankID-appen för att signera hos Telia Finance.",
  "sign.signing": "Signerar...",
  "signing.cancel": "Avbryt",
  "signing.general_error": "Ett fel uppstod vid signering. Vänligen försök igen.",
  "signing.waiting_message": "Inväntar signering",
  "thank_you_pay_in_parts.app_store_link": "https://itunes.apple.com/se/app/telia-pay/id1234198528",
  "thank_you_pay_in_parts.download_our_app": "Med Telia Pay betalar du enkelt dina delbetala fakturor, får koll på framtida betalningar och påminnelser inför förfallodag.",
  "thank_you_pay_in_parts.fine_print": "En pappersfaktura skickas till din folkbokföringsadress om du inte har angett en annan adress. Du kan enkelt ändra till e-mail eller e-faktura i Telia Pay appen.",
  "thank_you_pay_in_parts.number_of_months": "{{count}} månad",
  "thank_you_pay_in_parts.number_of_months_plural": "{{count}} månader",
  "thank_you_pay_in_parts.pay_in_parts": "Delbetalning",
  "thank_you_pay_in_parts.play_store_link": "https://play.google.com/store/apps/details?id=se.teliacompany.dragon",
  "thank_you_pay_in_parts.summary_monthly_cost": "Månadskostnad",
  "thank_you_pay_in_parts.summary_payment_method": "Betalmetod",
  "thank_you_pay_in_parts.summary_payment_term": "Betalningsperiod",
  "thank_you_pay_in_parts.summary_title": "Sammanfattning",
  "thank_you_pay_in_parts.summary_total_amount": "Totalbelopp",
  "thank_you_pay_in_parts.title": "Nästan klar! Glöm inte signera ditt köp med BankID.",
  "thank_you_pay_with_card.card": "Kort",
  "thank_you_pay_with_card.pay_in_parts_promo": "Visste du att du kan använda vår tjänst Telia Pay för att dela upp din betalning av produkter och tjänster från Telia? Inga räntor, inga dolda avgifter, inga konstigheter.* Läs mer på teliafinance.com.",
  "thank_you_pay_with_card.pay_in_parts_promo_fine_print": "*Uppläggningsavgiften är alltid 0 kr, nominella räntan är alltid 0%. Aviavgiften är 0 kr om du har valt e-mail faktura eller e-faktura, men pappersfaktura kostar 49 kr. Exempel om email faktura har valts: Ett köp på 10 000 kr som betalas över 12 månader har en total betalsumma på 10 000 kronor och effektiv ränta på 0%.",
  "thank_you_pay_with_card.summary_payment_method": "Betalmetod",
  "thank_you_pay_with_card.summary_title": "Sammanfattning",
  "thank_you_pay_with_card.title": "Tack!",
  "thank_you_pay_with_card.total_amount": "Totalt kreditbelopp",
  "try_another_payment_method": "Tyvärr kan vi inte erbjuda dig delbetalning, vänligen testa en annan betalningsmetod.",
  "your_details.cancel": "Avbryt",
  "your_details.continue": "Fortsätt",
  "your_details.save": "Spara",
  "your_details.saving": "Sparar",
  "your_details.ssn_helper_text": "Identifiera dig med BankID så vi kan se om du redan har ett konto med Telia Finance.",
  "your_details.ssn_label": "Personnummer",
  "your_details.ssn_placeholder": "YYYYMMDDXXXX",
  "your_details.title": "Dina uppgifter"
};