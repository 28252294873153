import { getCssVariable } from '../customThemeHelper';

const fontTheme = {
  primary: 'var(--telia-sans)',
  teliaIconFont: 'Telia Icon Font'
};

const fontHandler = {
  get: (target, prop) => {
    if (prop === 'primary') {
      return getCssVariable('--font-family') || target[prop];
    }

    return target[prop];
  }
};

const font = new Proxy(fontTheme, fontHandler);

export default font;
