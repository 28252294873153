import React from 'react';

const TeliaIconShopping = () => {
  return (
    <svg
      width="32"
      viewBox="0 0 40 54"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
    >
      <path
        d="M 36,12 H 32 C 32,8.8174 30.7357,5.76515 28.4853,3.51472 26.2348,1.26428 23.1826,0 20,0 16.8174,0 13.7652,1.26428 11.5147,3.51472 9.2643,5.76515 8,8.8174 8,12 H 4 C 2.9395,12.0011 1.9227,12.4228 1.1727,13.1727 0.4228,13.9227 0.0011,14.9395 0,16 v 34 c 0.0011,1.0605 0.4228,2.0773 1.1727,2.8273 C 1.9227,53.5772 2.9395,53.9989 4,54 h 32 c 1.0605,-0.0011 2.0773,-0.4228 2.8273,-1.1727 C 39.5772,52.0773 39.9989,51.0605 40,50 V 16 C 39.9989,14.9395 39.5772,13.9227 38.8273,13.1727 38.0773,12.4228 37.0605,12.0011 36,12 Z M 20,4 c 2.121,0.00243 4.1544,0.84606 5.6542,2.3458 C 27.1539,7.8456 27.9976,9.879 28,12 H 12 C 12.0024,9.879 12.8461,7.8456 14.3458,6.3458 15.8456,4.84606 17.879,4.00243 20,4 Z M 36,50 H 4 V 16 l 4,-2e-4 V 20 c 0,0.5304 0.2107,1.0391 0.5858,1.4142 C 8.9609,21.7893 9.4696,22 10,22 10.5304,22 11.0391,21.7893 11.4142,21.4142 11.7893,21.0391 12,20.5304 12,20 v -4.0005 l 16,-10e-4 V 20 c 0,0.5304 0.2107,1.0391 0.5858,1.4142 C 28.9609,21.7893 29.4696,22 30,22 30.5304,22 31.0391,21.7893 31.4142,21.4142 31.7893,21.0391 32,20.5304 32,20 v -4.0017 l 4,-2e-4 z"
        id="path2"
      />
    </svg>
  );
};

export default TeliaIconShopping;
