import { spacing } from '@teliads/components/foundations';

import color from './color';
import font from './font';

const theme = {
  color,
  font,
  fontSize: {
    base: '16px',
    finePrint: '10px',
    xsmall: '12px',
    small: '14px',
    mediumSmall: '20px',
    medium: '24px',
    large: '40px',
    xLarge: '48px'
  },
  margin: {
    standardTop: '40px',
    standardVertical: '40px 0',
    halfStandardVertical: '20px 0',
    unit: {
      base: '10px',
      double: '20px',
      quadruple: '40px'
    },
    logoWrapper: '3px'
  },
  width: {
    innerMax: '360px'
  },
  borderRadius: {
    monthItem: '8px',
    button: '50px',
    bankIdLogoWrapper: '50px'
  },
  borderThickness: {
    monthItem: '1px',
    paymentMethod: '2px',
    checkbox: '0',
    radioButton: '1px',
    bankIdSignButton: '0',
    logoWrapper: '0'
  },
  breakpoint: {
    desktop: '475px',
    mobile: '474px'
  },
  spacing
};

export default theme;
