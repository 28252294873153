import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { colors, spacing } from '@teliads/components/foundations';
import { useSelector } from 'react-redux';
import RadioButton from './RadioButton';
import { PaymentMethodTitle } from '../styled-components/styledcomponents';
import {
  selectAllowPaymentMethodChange,
  selectUiConfig
} from '../selectors/selectors';

const PaymentMethod = ({
  name,
  isSelected,
  onClick,
  canChangePaymentMethod,
  overlayComponent,
  icon,
  dataCy,
  children
}) => {
  const allowPaymentMethodChange = useSelector(selectAllowPaymentMethodChange);
  const { headerEnabled } = useSelector(selectUiConfig);

  const hideHeader = !headerEnabled && !canChangePaymentMethod;
  const hide = !isSelected && !allowPaymentMethodChange;

  return (
    <PaymentMethodWrapper
      isSelected={isSelected}
      canChangePaymentMethod={canChangePaymentMethod}
      isVisible={!hide}
      allowPaymentMethodChange={allowPaymentMethodChange}
      onClick={onClick}
      data-cy={dataCy}
    >
      {!hideHeader && (
        <PaymentMethodWrapperTitle>
          <TitleRow
            canChangePaymentMethod={canChangePaymentMethod}
            isSelected={isSelected}
            data-cy="PaymentMethodTitleRow"
          >
            {canChangePaymentMethod && <RadioButton isSelected={isSelected} />}
            <PaymentMethodTitle>{name}</PaymentMethodTitle>
            {icon}
          </TitleRow>
        </PaymentMethodWrapperTitle>
      )}
      <OverlayContainer isVisible={overlayComponent && isSelected}>
        {overlayComponent}
      </OverlayContainer>
      <ChildContainer isVisible={isSelected && !overlayComponent}>
        {children}
      </ChildContainer>
    </PaymentMethodWrapper>
  );
};

PaymentMethod.propTypes = {
  name: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  canChangePaymentMethod: PropTypes.bool.isRequired,
  overlayComponent: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  icon: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  dataCy: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

PaymentMethod.defaultProps = {
  overlayComponent: null,
  icon: null,
  children: null
};

export default PaymentMethod;

const PaymentMethodWrapperTitle = styled.div.attrs({
  className: 'PaymentMethodWrapperTitle'
})`
  width: calc(100% + 32px);
  display: block;
  flex-direction: column;
  align-items: center;
  ${({ theme, children }) =>
    children.props.canChangePaymentMethod &&
    `border-bottom: ${theme.borderThickness.paymentMethod} solid ${colors.gray50};`};
  margin-left: 0;
  margin-top: 16px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
  :hover {
    &:not(:first-child) {
      ${(p) =>
        p.canChangePaymentMethod &&
        !p.isSelected &&
        `border: ${p.theme.borderThickness.paymentMethod} solid
      ${colors.purple850};`};
    }

    &:first-child {
      ${(p) =>
        p.canChangePaymentMethod &&
        !p.isSelected &&
        `border: ${p.theme.borderThickness.paymentMethod} solid
      ${colors.purple850};`};
      ${(p) =>
        p.canChangePaymentMethod &&
        !p.isSelected &&
        `border-bottom: 0 solid
      ${colors.purple850};`};
    }

    ${(p) =>
      p.canChangePaymentMethod &&
      `cursor: ${p.isSelected ? 'default' : 'pointer'};`}
  }
  position: relative;
  background: ${({ theme }) => theme.color.mainLayerBackground};
`;

const PaymentMethodWrapper = styled.div.attrs({
  className: 'PaymentMethodWrapper'
})`
  width: 100%;
  display: ${(p) => (p.isVisible ? 'flex' : 'none')};
  flex-direction: column;
  align-items: center;
  padding: ${spacing.spacing0} ${spacing.spacing16} ${spacing.spacing0}
    ${spacing.spacing16};
  border: ${(p) => p.theme.borderThickness.paymentMethod} solid
    ${({ theme }) => theme.color.outerBorderColor};

  ${(p) =>
    p.canChangePaymentMethod &&
    p.isSelected &&
    `padding: ${spacing.spacing0} ${spacing.spacing16} ${spacing.spacing16}
  ${spacing.spacing16};`};

  &:not(:first-child) {
    border-top: 0 solid ${colors.purple850};
    ${(p) =>
      !p.allowPaymentMethodChange &&
      `border: ${p.theme.borderThickness.paymentMethod} solid ${colors.gray50};`};
  }

  &:first-child {
    ${(p) =>
      p.canChangePaymentMethod &&
      !p.isSelected &&
      `border: ${p.theme.borderThickness.paymentMethod} solid
      ${colors.gray50};`};
    ${(p) =>
      p.canChangePaymentMethod &&
      !p.isSelected &&
      `border-bottom: 0 solid
      ${colors.gray50};`};
  }

  ${(p) =>
    p.canChangePaymentMethod &&
    !p.isSelected &&
    `border-bottom: 0 solid
      ${colors.purple850};`};

  position: relative;
  background: ${({ theme }) => theme.color.mainLayerBackground};
`;

const OverlayContainer = styled.div`
  display: ${(p) => (p.isVisible ? 'flex' : 'none')};
  width: 100%;
  flex-direction: column;
  align-items: center;
`;

const TitleRow = styled.div`
  :hover {
    ${(p) =>
      p.canChangePaymentMethod &&
      !p.isSelected &&
      `cursor: pointer;
  `};
  }

  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const ChildContainer = styled.div`
  width: 100%;
  flex-direction: column;
  align-items: center;
  display: ${(p) => (p.isVisible ? 'flex' : 'none')};
  position: relative;
`;
