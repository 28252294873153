import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as PropTypes from 'prop-types';
import { SecondaryButton } from '../styled-components/styledcomponents';

const ReturnToPaymentMethodsButton = ({ eventToEmit }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const onRedirectButtonButtonClick = () => {
    dispatch(eventToEmit());
  };
  return (
    <SecondaryButton
      onClick={onRedirectButtonButtonClick}
      data-cy="limitToLowRedirectButton"
    >
      {t('credit_limit_information.declined_credit_redirect_button')}
    </SecondaryButton>
  );
};

ReturnToPaymentMethodsButton.propTypes = {
  eventToEmit: PropTypes.func.isRequired
};

export default ReturnToPaymentMethodsButton;
