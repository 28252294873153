import styled from 'styled-components';
import { spacing, typography } from '@teliads/components/foundations';

export const InputLabel = styled.div`
  margin: ${spacing.spacing12} ${spacing.spacing0} ${spacing.spacing0}
    ${spacing.spacing0};
  font-weight: ${typography.weightMedium};

  & input {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin-top: ${spacing.spacing4};
    padding: ${spacing.spacing8} ${spacing.spacing12};
    font-size: ${(p) => p.theme.fontSize.base};
    width: 100%;
  }
`;

const Input = styled.input.attrs({
  className: 'Input'
})`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin-top: ${spacing.spacing4};
  padding: ${spacing.spacing8} ${spacing.spacing12};
  font-size: ${(p) => p.theme.fontSize.base};
`;

export const InputEmail = styled(Input).attrs({
  type: 'email',
  placeholder: 'example@e-mail.com',
  className: 'InputEmail'
})``;
