import globals from '../globals';
import UnexpectedServerResponse from '../UnexpectedServerResponse';
import { SIGNING_ALREADY_IN_PROGRESS } from '../../constants/actions';

const sbidSignStart = async (
  purchaseRequestToken = null,
  correlationId,
  fetch = window.fetch
) => {
  const response = await fetch(`${globals.signSbidStart}`, {
    method: 'GET',
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Correlation-ID': correlationId,
      'Purchase-Request-Token': purchaseRequestToken
    }
  });
  switch (response.status) {
    case 200: {
      const { token, autoStartToken } = await response.json();
      if (!token) {
        throw new UnexpectedServerResponse(response, 'Received no token');
      }
      return { token, autoStartToken };
    }
    case 409:
      return { status: SIGNING_ALREADY_IN_PROGRESS };
    default:
      throw new UnexpectedServerResponse(response);
  }
};

export default sbidSignStart;
