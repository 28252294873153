import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  ExpressiveLinkButton,
  TextLink
} from '../../styled-components/styledcomponents';

const isIpad = () =>
  /iPad/i.test(navigator.userAgent) ||
  (/Macintosh/i.test(navigator.userAgent) &&
    navigator.maxTouchPoints &&
    navigator.maxTouchPoints > 1);

const isIphone = () => /iPhone/i.test(navigator.userAgent);

const commonAutoStartLink = (autoStartToken) => {
  return `bankid:///?autostarttoken=${autoStartToken}&redirect=null`;
};
const safariAutoStartLink = (autoStartToken) => {
  return `https://app.bankid.com/?autostarttoken=${autoStartToken}&redirect=null`;
};

const autoStartLink = (autoStartToken) => {
  return isIphone() || isIpad()
    ? safariAutoStartLink(autoStartToken)
    : commonAutoStartLink(autoStartToken);
};

export const AuthenticatingAutoStartType = {
  button: 'button',
  textButton: 'textButton'
};
const AuthenticatingAutoStartButton = ({ type }) => {
  const { autoStartToken } = useSelector((state) => state.app);
  const { t } = useTranslation();

  return (
    <>
      {type === AuthenticatingAutoStartType.button && (
        <ExpressiveLinkButton
          target={isIphone() || isIpad() ? '_blank' : '_self'}
          href={autoStartLink(autoStartToken)}
        >
          {t('authenticating.start_app_button', {
            defaultValue: 'Start BankID'
          })}
        </ExpressiveLinkButton>
      )}
      {type === AuthenticatingAutoStartType.textButton && (
        <TextLink
          target={isIphone() || isIpad() ? '_blank' : '_self'}
          href={autoStartLink(autoStartToken)}
        >
          {t('authenticating.start_app_text_button', {
            defaultValue: 'Use BankID on this device'
          })}
        </TextLink>
      )}
    </>
  );
};

AuthenticatingAutoStartButton.propTypes = {
  type: PropTypes.string.isRequired
};

export default AuthenticatingAutoStartButton;
