import { START } from '../../constants/actions';
import { WEBCHECKOUT_SDK } from '../../constants/index';

/**
 * Returns an event listener for the dispatched event:
 * https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage#The_dispatched_event
 * @param dispatch the dispatch function from react-redux (so actions
 * can be dispatched)
 * @returns {Function}
 */
const receiveMessage = (dispatch) => {
  return (event) => {
    if (event.data.source === WEBCHECKOUT_SDK) {
      if (event.data.type === START) {
        const action = event.data;
        action.parentWindowOrigin = event.origin;
        dispatch(action);
      }
    }
  };
};
export default receiveMessage;
