import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { userInfoCollectionPhone } from '../../actions/applicationActions';

const useValidatePhone = () => {
  const { mobile: customerMobile } = useSelector((state) => state.app);
  const [phoneValidation, setPhoneValidation] = useState({
    isPhoneValid: true,
    hasPhoneBeenValidated: false
  });
  const dispatch = useDispatch();

  const phone = (value = customerMobile) => {
    const phoneRegEx = /^\+[1-9]\d{6,14}$/;
    setPhoneValidation((prevState) => ({ ...prevState, isPhoneValid: false }));
    if (phoneRegEx.test(value)) {
      setPhoneValidation((prevState) => ({
        ...prevState,
        isPhoneValid: true
      }));
      dispatch(userInfoCollectionPhone(value));
    }
    setPhoneValidation((prevState) => ({
      ...prevState,
      hasPhoneBeenValidated: true
    }));
  };

  return {
    phoneValidation,
    validatePhone: (e) => {
      phone(e);
    }
  };
};

export default useValidatePhone;
