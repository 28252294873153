export default class UnexpectedStatusCode extends Error {
  constructor(statusCode, ...params) {
    super(...params);

    this.name = this.constructor.name;
    this.statusCode = statusCode;
    if (!this.message) {
      this.message = this.statusCode;
    }
  }
}
