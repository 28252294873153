export default class UnexpectedServerResponse extends Error {
  constructor(response, ...params) {
    super(...params);

    this.name = this.constructor.name;
    this.response = response;
    if (!this.message) {
      this.message = this.response.status;
    }
  }
}
