import { createSelector } from 'reselect';
import { PAYMENT_METHOD_PART_PAYMENT } from '../constants/index';
import accountLookupStatusType from '../constants/accountLookupStatusType';
import riskAssessmentStatusType from '../constants/riskAssessmentStatusType';

const selectApp = (state) => state.app;
const selectPartPayment = (state) => state.partPayment;

export const selectCanChangePaymentMethod = createSelector(selectApp, (app) => {
  const multiplePaymentMethods =
    Object.keys(app.paymentMethods || {}).length > 1;
  return (
    !app.processPurchaseRequestLoading &&
    !app.processPurchaseRequestSuccess &&
    !app.hasClickedButtonToNextStep &&
    app.processPurchaseRequestErrorContactSupport !== true &&
    multiplePaymentMethods
  );
});

export const selectUiConfig = createSelector(selectApp, (app) => {
  return app.uiConfig;
});

export const selectPaymentDetailsPartPayment = createSelector(
  selectApp,
  (app) => {
    return app.paymentMethods[PAYMENT_METHOD_PART_PAYMENT][
      app.selectedPaymentMethodOption
    ];
  }
);

export const selectPaymentDetailsCardPaymentOption = createSelector(
  selectApp,
  (app) => {
    return app.paymentMethods.cardPayment;
  }
);

export const selectAllowPaymentMethodChange = createSelector(
  selectApp,
  (app) => {
    return !app.hasClickedButtonToNextStep;
  }
);

export const selectCreditGranted = createSelector(
  selectPartPayment,
  (partPayment) => {
    if (
      partPayment.accountLookupStatus ===
      accountLookupStatusType.NO_ACTION_NEEDED
    ) {
      return [accountLookupStatusType.NO_ACTION_NEEDED].includes(
        partPayment.accountLookupStatus
      );
    }
    return [
      riskAssessmentStatusType.CREATE_ACCOUNT_NEEDED,
      riskAssessmentStatusType.UPDATE_ACCOUNT_NEEDED
    ].includes(partPayment.riskAssessmentStatus);
  }
);

export const selectCreditGrantedForPayTheDifference = createSelector(
  selectPartPayment,
  (partPayment) =>
    [
      riskAssessmentStatusType.PAY_THE_DIFFERENCE_CREATE_ACCOUNT_NEEDED,
      riskAssessmentStatusType.PAY_THE_DIFFERENCE_UPDATE_ACCOUNT_NEEDED
    ].includes(partPayment.riskAssessmentStatus)
);
