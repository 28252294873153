import { select, call } from 'redux-saga/effects';

function* trackEvent(name = '', customAttributes = {}) {
  const { purchaseRequestToken, storeName, isLockedMonth, merchantId } =
    yield select((state) => state.app);

  if (window.dataLayer) {
    window.dataLayer.push({
      event: name,
      ...customAttributes,
      purchaseRequestToken,
      merchant: merchantId,
      storeName,
      isTeleSales: isLockedMonth && !storeName
    });
  }
}

export function* trackStart(customAttributes = {}) {
  yield call(trackEvent, 'start', customAttributes);
}

export function* trackError(customAttributes = {}) {
  yield call(trackEvent, 'error', customAttributes);
}

export function* trackCancel(customAttributes = {}) {
  yield call(trackEvent, 'cancel', customAttributes);
}

export function* trackContinue(customAttributes = {}) {
  yield call(trackEvent, 'continue', customAttributes);
}

export function* trackSign(customAttributes = {}) {
  yield call(trackEvent, 'sign', customAttributes);
}

export function* trackClickedConfirmPurchase(isSuccesful) {
  yield call(trackEvent, 'clicked-confirm-purchase', { success: isSuccesful });
}

export function* trackCreditCheckResult(creditCheckResultType) {
  yield call(trackEvent, 'credit-check', { creditCheckResultType });
}

export function* trackAccountLookup() {
  yield call(trackEvent, 'account-lookup');
}
