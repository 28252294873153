import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';
import { spacing } from '@teliads/components/foundations';
import * as propTypes from 'prop-types';
import {
  BodyTextBlack,
  BodyTextGray,
  Line,
  StrongBold,
  Title4
} from '../styled-components/styledcomponents';
import {
  formatCurrency,
  integerFormatAmount,
  decimalFormatAmountWithCurrency
} from '../helpers/amount';
import TeliaIconDashCircleFill from './graphics/TeliaIconDashCircleFill';
import TeliaIconCheckCircleFill from './graphics/TeliaIconCheckCircleFill';
import CreditDeniedRedirectButton from './CreditDeniedRedirectButton';
import { creditCheckDenied } from '../actions/eventActions';
import FLOWS from '../constants/flows';
import useMerchantSpecificTranslationKey from '../hooks/useMerchantSpecificTranslationKey';
import accountLookupStatusType from '../constants/accountLookupStatusType';
import riskAssessmentStatusType from '../constants/riskAssessmentStatusType';

const CreditLimitInformation = () => {
  const {
    accountLookupStatus,
    riskAssessmentStatus,
    creditSpace,
    maximumCreditLimit,
    currency,
    hasCreditLimitChanged
  } = useSelector((state) => state.partPayment);

  const { errorContactSupport } = useSelector((state) => state.app);

  if (
    errorContactSupport ||
    accountLookupStatus === accountLookupStatusType.NO_ACTION_NEEDED ||
    accountLookupStatus === accountLookupStatusType.ORDER_AMOUNT_TOO_LOW
  ) {
    return <></>;
  }

  switch (riskAssessmentStatus) {
    case riskAssessmentStatusType.PAY_THE_DIFFERENCE_UPDATE_ACCOUNT_NEEDED:
    case riskAssessmentStatusType.PAY_THE_DIFFERENCE_CREATE_ACCOUNT_NEEDED:
      return <></>;
    case riskAssessmentStatusType.UPDATE_ACCOUNT_NEEDED:
    case riskAssessmentStatusType.CREATE_ACCOUNT_NEEDED:
      if (hasCreditLimitChanged) {
        return <CreditCheckChangedSuccess />;
      }
      return <></>;
    default:
      return (
        <CreditCheckFailed
          {...{
            type: riskAssessmentStatus,
            currency,
            creditSpace,
            maximumCreditLimit
          }}
        />
      );
  }
};

export default CreditLimitInformation;

const CreditCheckChangedSuccess = () => {
  const { creditLimit, approvedLimit, currency } = useSelector(
    (state) => state.partPayment
  );
  const { t } = useTranslation();

  return (
    <CreditLimitInformationWrapper data-cy="CreditLimitInformationUpdateSuccess">
      <Line marginTop={spacing.spacing24} marginBottom={spacing.spacing24} />

      <CreditLimitInformationTitle>
        <TeliaIconCheckCircleFill />
        <CreditLimitInformationSpacing>
          <Title4>{t('credit_limit_information.title')}</Title4>
        </CreditLimitInformationSpacing>
      </CreditLimitInformationTitle>

      <BodyTextGray>
        <Trans
          i18nKey="pay_in_parts_summary.increase_limit_info"
          components={{
            bold: <StrongBold />,
            currency: formatCurrency(currency),
            previousCredit: integerFormatAmount(creditLimit),
            newCredit: integerFormatAmount(approvedLimit)
          }}
        />
      </BodyTextGray>
    </CreditLimitInformationWrapper>
  );
};

const getCreditCheckFailedTranslation = (
  t,
  merchantTranslation,
  type,
  translationVariables
) => {
  const { formattedCurrency } = translationVariables;
  const translationMap = {
    [riskAssessmentStatusType.CREDIT_LIMIT_TOO_LOW]: t(
      'credit_limit_information.credit_limit_to_low',
      {
        defaultValue:
          'You cannot part pay this purchase because your potential credit space of {{amount}} is too low.',
        amount: formattedCurrency
      }
    ),
    [riskAssessmentStatusType.UNPAID_INVOICES]: t(
      'credit_limit_information.unpaid_invoices'
    ),
    [riskAssessmentStatusType.MAXIMUM_AMOUNT_EXCEEDED]: t(
      'credit_limit_information.maximum_amount_exceeded',
      {
        defaultValue:
          'You can’t pay this purchase in parts because the amount exceeds the maximum credit limit {{amount}} offered by Telia Finance.',
        amount: formattedCurrency
      }
    )
  };
  return (
    translationMap[type] ||
    merchantTranslation(
      'credit_limit_information.could_not_update_credit_limit_title'
    )
  );
};

const CreditCheckFailed = ({
  type,
  currency,
  creditSpace,
  maximumCreditLimit
}) => {
  const { flow } = useSelector((state) => state.app);
  const { t } = useTranslation();
  const { merchantTranslation } = useMerchantSpecificTranslationKey();
  const formattedCurrency =
    type === riskAssessmentStatusType.CREDIT_LIMIT_TOO_LOW
      ? decimalFormatAmountWithCurrency(creditSpace, currency)
      : decimalFormatAmountWithCurrency(maximumCreditLimit, currency);
  const errorMessage = getCreditCheckFailedTranslation(
    t,
    merchantTranslation,
    type,
    {
      formattedCurrency
    }
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(creditCheckDenied());
  }, []);

  return (
    <CreditLimitInformationWrapper data-cy="CreditLimitInformationFailed">
      <Line marginTop={spacing.spacing24} marginBottom={spacing.spacing24} />
      <CreditLimitInformationTitle>
        <TeliaIconDashCircleFill />
        <CreditLimitInformationSpacing>
          <Title4>{t('credit_limit_information.title')}</Title4>
        </CreditLimitInformationSpacing>
      </CreditLimitInformationTitle>

      <BodyTextBlack>{errorMessage}</BodyTextBlack>

      {flow === FLOWS.WEB && (
        <>
          <Line
            marginTop={spacing.spacing24}
            marginBottom={spacing.spacing24}
          />
          <CreditDeniedRedirectButton />
        </>
      )}
    </CreditLimitInformationWrapper>
  );
};

CreditCheckFailed.propTypes = {
  type: propTypes.string.isRequired,
  currency: propTypes.string.isRequired,
  maximumCreditLimit: propTypes.number,
  creditSpace: propTypes.number
};

CreditCheckFailed.defaultProps = {
  maximumCreditLimit: null,
  creditSpace: null
};

const CreditLimitInformationWrapper = styled.div.attrs({
  className: 'CreditLimitInformationWrapper'
})`
  width: 100%;
`;

const CreditLimitInformationTitle = styled.div.attrs({
  className: 'CreditLimitInformationTitle'
})`
  width: 100%;
  display: flex;
  align-items: center;
`;

const CreditLimitInformationSpacing = styled.div.attrs({
  className: 'CreditLimitInformationSpacing'
})`
  margin-left: ${spacing.spacing8};
`;
