import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Heading, Spinner } from '@purpurds/purpur';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import PoweredByTeliaFinance from '../PoweredByTeliaFinance';
import DecimalFormatAmountWithPostFix from './DecimalFormatAmountWithPostFix';

const HighLoadView = () => {
  const { configHighLoad, trafficFlattenerWaitTimeInSeconds } = useSelector(
    (state) => state.app
  );
  const { t } = useTranslation();
  const [secondsToDisplay, setSecondsToDisplay] = useState(0);

  useEffect(() => {
    const additionalPollSeconds = 2;
    if (trafficFlattenerWaitTimeInSeconds > 0) {
      setSecondsToDisplay(
        trafficFlattenerWaitTimeInSeconds + additionalPollSeconds
      );
    }
  }, [trafficFlattenerWaitTimeInSeconds]);

  useEffect(() => {
    let interval;
    if (secondsToDisplay >= 0) {
      const decreaseAmount = 1;
      interval = setInterval(() => {
        setSecondsToDisplay(secondsToDisplay - decreaseAmount);
      }, decreaseAmount * 1000);
    } else if (secondsToDisplay) {
      setSecondsToDisplay(0);
    }
    return () => clearInterval(interval);
  }, [secondsToDisplay]);

  if (configHighLoad) {
    return (
      <>
        <Container data-cy="HighLoadView">
          <SpinnerContainer data-cy="HighLoadViewSpinner">
            <Spinner negative="true" size="sm" />
          </SpinnerContainer>
          <Heading tag="h2" variant="title-300">
            {t('high_load.title_loading', { defaultValue: 'Loading...' })}
          </Heading>
          {secondsToDisplay > 0 && (
            <EstimateContainer>
              <Heading tag="h2" variant="title-200">
                {t('high_load.estimated_time', {
                  defaultValue: 'Estimated time:'
                })}{' '}
                <DecimalFormatAmountWithPostFix
                  amount={secondsToDisplay * 1000}
                />
              </Heading>
            </EstimateContainer>
          )}
          <BodyText>
            {t('high_load.body', {
              defaultValue:
                'We’re experiencing high traffic. Hang on while we load the page. If you refresh the page, it will cause further delay.'
            })}
          </BodyText>
          <Heading tag="h3" variant="title-200">
            {t('high_load.title_thank_you', {
              defaultValue: 'Thank you for your patience!'
            })}
          </Heading>
        </Container>
        <PoweredByContainer>
          <PoweredByTeliaFinance />
        </PoweredByContainer>
      </>
    );
  }
  return <></>;
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  max-width: ${(p) => p.theme.width.innerMax};
  margin: var(--purpur-spacing-1600) var(--purpur-spacing-200)
    var(--purpur-spacing-1600) var(--purpur-spacing-200);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
`;

const SpinnerContainer = styled.div.attrs({
  className: 'SpinnerContainer'
})`
  display: flex;
  justify-content: center;
  padding: var(--purpur-spacing-250) 0 var(--purpur-spacing-400) 0;
`;

const EstimateContainer = styled.div.attrs({
  className: 'EstimateContainer'
})`
  padding: var(--purpur-spacing-400) 0 0 0;
`;

const BodyText = styled.p.attrs({
  className: 'BodyText'
})`
  padding: var(--purpur-spacing-250) 0 var(--purpur-spacing-250) 0;
  font-size: ${(p) => p.theme.fontSize.base};
  line-height: var(--purpur-spacing-250);
  text-align: center;
  font-weight: 400;
  color: var(--purpur-color-gray-600);
`;

const PoweredByContainer = styled.div`
  padding: 0 0 var(--purpur-spacing-200) 0;
`;

export default HighLoadView;
