import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCanChangePaymentMethod,
  selectUiConfig
} from '../selectors/selectors';
import { setSelectedPaymentMethod } from '../actions/applicationActions';
import ROUTES from '../constants/routes';
import RENDER_PAYMENT_OPTIONS from '../constants/renderPaymentOptions';
import PartPayment from './PartPayment';
import CardPayment from './CardPayment/CardPayment';
import HighLoadView from './Config/HighLoadView';
import PaymentMethod from './PaymentMethod';
import PaymentMethodIcon from './PaymentMethodIcon';
import { PAYMENT_METHOD_PART_PAYMENT } from '../constants';
import useMerchantSpecificTranslationKey from '../hooks/useMerchantSpecificTranslationKey';
import CircuitBreakerView from './Config/CircuitBreakerView';

const Accordion = ({ match, history }) => {
  const { merchantTranslation } = useMerchantSpecificTranslationKey();
  const dispatch = useDispatch();
  const { paymentMethods, selectedPaymentMethod, configCircuitBreaker } =
    useSelector((state) => state.app);
  const { renderPaymentOption } = useSelector(selectUiConfig);
  const renderPartPayment = renderPaymentOption !== RENDER_PAYMENT_OPTIONS.CARD;
  const renderCardPayment =
    renderPaymentOption !== RENDER_PAYMENT_OPTIONS.PART_PAYMENT;

  // Ensures that the currently selected payment method is displayed in the URL
  useEffect(() => {
    if (
      selectedPaymentMethod !== match.params.paymentMethod &&
      selectedPaymentMethod
    ) {
      history.push(`${ROUTES.PAYMENT_METHOD}/${selectedPaymentMethod}`);
    }
  }, [match, history, selectedPaymentMethod]);

  const canChangePaymentMethod = useSelector(selectCanChangePaymentMethod);
  const clickCallback = useCallback(
    (paymentMethod) => {
      if (canChangePaymentMethod && paymentMethod !== selectedPaymentMethod) {
        dispatch(setSelectedPaymentMethod(paymentMethod));
      }
    },
    [selectedPaymentMethod, canChangePaymentMethod]
  );

  if (paymentMethods && !configCircuitBreaker) {
    return (
      <AccordionWrapper>
        {Object.keys(paymentMethods).map((paymentMethod) => {
          if (paymentMethod === 'account' && renderPartPayment) {
            return (
              <PartPayment
                key={paymentMethod}
                onClick={clickCallback}
                className={paymentMethod}
              />
            );
          }
          if (paymentMethod === 'cardPayment' && renderCardPayment) {
            return (
              <CardPayment
                key={paymentMethod}
                onClick={clickCallback}
                history={history}
              />
            );
          }
          return <></>;
        })}
      </AccordionWrapper>
    );
  }
  return (
    <PaymentMethod
      canChangePaymentMethod={false}
      isSelected
      icon={<PaymentMethodIcon paymentMethod={PAYMENT_METHOD_PART_PAYMENT} />}
      name={merchantTranslation('thank_you_pay_in_parts.pay_in_parts')}
      dataCy="HighLoadContainer"
      onClick={() => {}}
    >
      <HighLoadView />
      <CircuitBreakerView />
    </PaymentMethod>
  );
};

Accordion.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default Accordion;

const AccordionWrapper = styled.div.attrs({ className: 'AccordionWrapper' })`
  width: 100%;
`;
