import monthioApiHelpers from './monthioApiHelpers';

export const monthioPollStatus = {
  DONE_COMPLETED: 'DONE_COMPLETED',
  DONE_ERROR: 'DONE_ERROR',
  ERR: 'ERR',
  ONGOING: 'ONGOING',
  EXPIRED: 'EXPIRED'
};

const monthioPoll = async (
  purchaseRequestToken,
  correlationId,
  fetch = window.fetch
) => {
  try {
    const response = await fetch(`${monthioApiHelpers.getBaseUrl()}/poll`, {
      method: 'GET',
      headers: monthioApiHelpers.setHeaders(purchaseRequestToken, correlationId)
    });

    switch (response.status) {
      case 200: {
        const json = await response.json();
        if (json.status === 'COMPLETED') {
          return { status: monthioPollStatus.DONE_COMPLETED, response: json };
        }
        if (json.status === 'ERROR') {
          return { status: monthioPollStatus.DONE_ERROR, response: json };
        }
        return { status: monthioPollStatus.ERR };
      }
      case 202:
        return { status: monthioPollStatus.ONGOING };
      case 400:
      case 404:
      case 410:
        return { status: monthioPollStatus.EXPIRED };
      default:
        return { status: monthioPollStatus.ERR };
    }
  } catch (error) {
    return null;
  }
};

export default monthioPoll;
