import globals from '../globals';
import UnexpectedServerResponse from '../UnexpectedServerResponse';

class UnexpectedStatusCode extends Error {
  constructor(statusCode, ...params) {
    super(...params);

    this.name = this.constructor.name;
    this.statusCode = statusCode;
    if (!this.message) {
      this.message = this.statusCode;
    }
  }
}

const riskAssessment = async (
  correlationId,
  purchaseRequestToken = null, // Only needed for logs
  fetch = window.fetch
) => {
  const response = await fetch(globals.riskAssessment, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Purchase-Request-Token': purchaseRequestToken,
      'X-Correlation-ID': correlationId
    }
  });
  try {
    switch (response.status) {
      case 200: {
        const {
          riskAssessmentStatus: {
            status,
            customer,
            customerType,
            maximumLimit,
            creditScoringRef,
            approvedLimit,
            requiredLimit,
            financeableAmount,
            offLimitAmountToPay,
            rejectedMessage,
            hasCreditLimitChanged
          }
        } = await response.json();
        return {
          apiStatus: 'OK',
          status,
          customer,
          customerType,
          maximumLimit,
          creditScoringRef,
          approvedLimit,
          requiredLimit,
          financeableAmount,
          offLimitAmountToPay,
          rejectedMessage,
          hasCreditLimitChanged
        };
      }
      case 400:
      case 401:
      case 404:
      case 435:
        return { apiStatus: 'ERR', value: response };
      default:
        throw new UnexpectedStatusCode(response.status);
    }
  } catch (e) {
    throw new UnexpectedServerResponse(response, e);
  }
};

export default riskAssessment;
