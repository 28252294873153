import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { spacing } from '@teliads/components/foundations';
import { useSelector } from 'react-redux';
import { decimalFormatAmountWithCurrency } from '../helpers/amount';
import {
  CancelButtonContainer,
  Line,
  Title4
} from '../styled-components/styledcomponents';
import CancelButton from './CancelButton';
import FLOWS from '../constants/flows';
import TeliaIconCheckCircleFill from './graphics/TeliaIconCheckCircleFill';
import PayTheDifferenceTextInformation from './PayTheDifferenceTextInformation';
import StrongSign from './StrongSign';
import PurchaseButton from './PurchaseButton';
import TextSummaryOfAgreements from './TextSummaryOfAgreements';

const PayTheDifference = () => {
  const { flow, strongSignRequired } = useSelector((state) => state.app);
  const { riskAssessmentStatus, financeableAmount } = useSelector(
    (state) => state.partPayment
  );
  const { currency } = useSelector((state) => state.partPayment);
  const { t } = useTranslation();

  const type = riskAssessmentStatus;

  const formattedFinanceableAmount = decimalFormatAmountWithCurrency(
    financeableAmount,
    currency
  );

  return (
    <>
      <CreditLimitInformationWrapper data-cy="CreditLimitInformationFailed">
        <Line marginTop={spacing.spacing12} marginBottom={spacing.spacing20} />

        <CreditLimitInformationTitle>
          <TeliaIconCheckCircleFill />
          <CreditLimitInformationSpacing>
            <Title4>
              {t('credit_limit_information.title', {
                defaultValue: 'Your credit check'
              })}
            </Title4>
          </CreditLimitInformationSpacing>
        </CreditLimitInformationTitle>

        <PayTheDifferenceTextInformation
          formattedFinanceableAmount={formattedFinanceableAmount}
          type={type}
        />

        <Line marginTop={spacing.spacing20} marginBottom={spacing.spacing20} />

        <TextSummaryOfAgreements />

        {strongSignRequired ? <StrongSign /> : <PurchaseButton />}

        {flow !== FLOWS.WEB && (
          <CancelButtonContainer>
            <CancelButton />
          </CancelButtonContainer>
        )}
      </CreditLimitInformationWrapper>
    </>
  );
};

export default PayTheDifference;

const CreditLimitInformationWrapper = styled.div.attrs({
  className: 'CreditLimitInformationWrapper'
})`
  width: 100%;
`;

const CreditLimitInformationTitle = styled.div.attrs({
  className: 'CreditLimitInformationTitle'
})`
  width: 100%;
  display: flex;
  align-items: center;
`;

const CreditLimitInformationSpacing = styled.div.attrs({
  className: 'CreditLimitInformationSpacing'
})`
  margin-left: ${spacing.spacing8};
`;
