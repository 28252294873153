module.exports = {
  "authenticating.cancel": "Annuller",
  "authenticating.waiting_message": "Venter på godkendelse",
  "authentication.general_error": "Der skete en fejl i godkendelses processen. Prøv venligst igen.",
  "authentication.identify_button_label": "Identificere",
  "cancel_view.guidance_header": "Du har annulleret din ordre",
  "cancel_view.guidance_text": "Kontakt kundeservice, hvis du har spørgsmål.",
  "cancel_view.sdk_in_store_guidance_header": "Du har annulleret købet",
  "cancel_view.sdk_in_store_guidance_text": "Kontakt butiksrepræsentanten, hvis du har nogen spørgsmål.",
  "card.complete_payment": "Færdiggør betaling ",
  "card.expiry_invalid_error": "Kortet er udløbet. Kontakt venligst din bank.",
  "card.general_error": "Noget gik galt. Prøv igen.",
  "card.invalid_info_error": "Ugyldigt kort. Venligst udfyld igen.",
  "card.is_processing_body": "Vent mens vi behandler din betaling.",
  "card.is_processing_title": "Behandler betaling",
  "card.not_supported_error": "Korttype er ikke supporteret. Prøv venligst med et andet kort.",
  "circuit_breaker.body": "Det lykkedes ikke at loade siden! Prøv igen efter nedtællingen nedenfor:",
  "circuit_breaker.button_try_again": "Prøv igen",
  "circuit_breaker.minutes_postfix": "min.",
  "circuit_breaker.title": "Der er opstået en fejl",
  "credit_information.inform_about_credit_report": "En kreditvurdering kan blive foretaget for, at vi kan gennemføre dit køb.",
  "credit_information.sign_purchase_text": "Gennemfør købet med MitID",
  "credit_limit_information.could_not_update_credit_limit_title": "Vi kan desværre ikke tilbyde dig køb på delbetaling.",
  "credit_limit_information.credit_limit_has_been_increased_to": "Din kredit er hævet til {{amount}} {{currency}}",
  "credit_limit_information.credit_limit_to_low": "Du kan ikke bruge delbetaling til dette køb fordi din tilgjengelige kreditt på {{amount}} er for lav.",
  "credit_limit_information.declined_credit_redirect_button": "Næste",
  "credit_limit_information.increase_limit_info": "Godkendt! Du kan købe på delbetaling!",
  "credit_limit_information.kyc_block": "Du har ikke udfyldt vores \"kend din kunde\" formular. For at vi kan tilbyde dig delbetaling, beder vi dig besvare formularen som du finder på teliafinance.com/dk.",
  "credit_limit_information.maximum_amount_exceeded": "Du kan ikke bruge delbetaling til dette køb fordi beløbet overstiger den højeste kreditgrænse på {{amount}}, som Telia Finance tilbyder. ",
  "credit_limit_information.please_try_another_payment_method": "Ønsker du at gennemføre din ordre, kan du  betale det fulde beløb nu.",
  "credit_limit_information.ready_to_pay_title": "Godkendt! Du kan købe på delbetaling!",
  "credit_limit_information.split_payment_continue_button": "Næste",
  "credit_limit_information.title": "Kreditvurdering",
  "credit_limit_information.unpaid_invoices": "Du har ubetalte fakturaer og kan derfor ikke bruge delbetaling til dette køb.",
  "email_phone_collection.body": "Inden du fortsætter, vil vi gerne bede dig om at udfylde dine kontaktoplysninger, så vi kan kontakte dig med vigtig information om din delbetaling. ",
  "email_phone_collection.continue": "Næste",
  "email_phone_collection.email_validation_failed": "Udfyld feltet med en gyldig e-mailadresse.",
  "email_phone_collection.field_email_header": "E-mail",
  "email_phone_collection.field_phone_header": "Mobilnummer",
  "email_phone_collection.header": "Kontaktoplysninger",
  "email_phone_collection.phone_validation_failed": "Forkert mobilnummer.",
  "high_load.body": "Vi oplever i øjeblikket ekstra trafik. Bliv hængende, mens vi indlæser siden. Hvis du opdaterer siden, vil det medføre yderligere forsinkelse.",
  "high_load.title_loading": "Indlæser…",
  "high_load.title_thank_you": "Tak fordi du venter!",
  "month": "måned",
  "month_picker.number_of_months": "{{count}} md.",
  "month_picker.number_of_months_plural": "{{count}} mdr.",
  "monthio.flow_finished": "Vent venligst, vi sender dig tilbage til dit køb.",
  "monthio.try_again": "Prøv igen",
  "monthio.window_is_opening": "Vent venligst, mens vi åbner kreditformularen i et nyt vindue.",
  "more_information.close_button_label": "Luk",
  "more_information.paragraph_1.row_1": "0% i debitorrente.",
  "more_information.paragraph_1.row_10": "Du kan altid finde mere information om delbetaling på teliafinance.com/dk",
  "more_information.paragraph_1.row_2": "Betal hele beløbet på første regning eller del betalingen op fx i 24 mdr.",
  "more_information.paragraph_1.row_3": "Ansøg om kredit i dag og brug den på flere køb.",
  "more_information.paragraph_1.row_4": "Du vil modtage en særskilt regning fra Telia Finance for dine køb med delbetaling.",
  "more_information.paragraph_1.row_5": "Regning med Betalingsservice koster 15 kr./stk. Och regning via e-Boks eller med post koster 49 kr./stk.",
  "more_information.paragraph_1.row_6": "Se alle dine køb på din Telia Finance konto. Linket kan du finde inde under ”Regninger” på din selvbetjening.",
  "more_information.paragraph_1.row_7": " ",
  "more_information.paragraph_1.row_8": "Er du i tvivl om noget vedr. MitID. Klik her: <1>MitID.</1>",
  "more_information.paragraph_1.row_8_link_1": "https://www.mitid.dk",
  "more_information.paragraph_1.row_9": "Du kan til enhver tid lave en ekstra indbetaling uden meromkostninger for at forkorte tilbagebetalingstiden. ",
  "more_information.paragraph_2.charge_cost_1": "  ",
  "more_information.paragraph_2.charge_cost_2": "0 %",
  "more_information.paragraph_2.charge_cost_3": "0 kr.",
  "more_information.paragraph_2.charge_cost_4": "15 kr*./stk.",
  "more_information.paragraph_2.charge_cost_5": " 49 kr./stk.",
  "more_information.paragraph_2.charge_cost_6": "0 kr.",
  "more_information.paragraph_2.charge_description_1": " ",
  "more_information.paragraph_2.charge_description_2": "Debitorrente",
  "more_information.paragraph_2.charge_description_3": "Opstartsgebyr",
  "more_information.paragraph_2.charge_description_4": "Regning med Betalingsservice",
  "more_information.paragraph_2.charge_description_5": "Regning med e-Boks eller post",
  "more_information.paragraph_2.charge_title": "Gebyrer",
  "more_information.paragraph_2.fine_text": "* Telia Finance får 15 kr. med fradrag af omkostningen til Betalingsservice, der kan ses på betalingsservice.dk/pris.",
  "more_information.paragraph_2.row_1": "Gebyrer:",
  "more_information.paragraph_2.row_2": "Debitorrente: 0%",
  "more_information.paragraph_2.row_3": "Opstartsgebyr: 0 kr.",
  "more_information.paragraph_2.row_4": "Regning med Betalingsservice koster 15 kr*./stk.",
  "more_information.paragraph_2.row_5": "Regning med e-Boks eller post koster 49 kr./stk.",
  "more_information.paragraph_2.row_6": "Årlige gebyrer: 0 kr.",
  "more_information.paragraph_2.row_7": "*Telia Finance får 15 kr. med fradrag af omkostningen til Betalingsservice, der kan ses på betalingsservice.dk/pris.",
  "more_information.title": "Delbetaling med Telia Finance",
  "onboarding.terms_and_conditions_secci_checkbox_text": "Jeg accepterer, at der straks ved afkrydsning af boksen foretages et kredittjek, og at jeg har læst og forstået <1>Terms and Conditions</1>  og <3>SECCI</3>.\n",
  "onboarding.terms_and_conditions_secci_checkbox_text_1": "https://www.teliafinance.com/globalassets/telia-finance/dk/documents/consumer/generelle-vilkar.pdf",
  "onboarding.terms_and_conditions_secci_checkbox_text_3": "https://www.teliafinance.com/globalassets/telia-finance/dk/documents/consumer/secci-dk.pdf",
  "part_payment_not_permitted_view.not_enough_amount_for_part_payment_text": "Delbetaling kan ikke bruges til beløb under {{amount}} {{currency}}",
  "part_payment_not_permitted_view.title": "Du kan desværre ikke bruge delbetaling til dette køb.",
  "pay_in_parts_summary.credit_interest": "Debitorrente",
  "pay_in_parts_summary.effective_interest": "Regning med e-Boks/post pr./md.",
  "pay_in_parts_summary.increase_limit_info": "Du har en godkendt kreditgrænse på <bold>{{newCredit}}</bold> <bold>{{currency}}</bold> til dækning af dette køb.",
  "pay_in_parts_summary.invoice_fee": "Regning med Betalingsservice",
  "pay_in_parts_summary.invoice_fee_2": "Regning med e-Boks eller post",
  "pay_in_parts_summary.invoice_fee_fine_print": "* plus betalingsgebyr afhængigt af dit valg af faktureringsmetode.",
  "pay_in_parts_summary.number_of_months": "måneder",
  "pay_in_parts_summary.plan": "Delbetaling",
  "pay_in_parts_summary.registration_fee": "Opstartsgebyr",
  "pay_in_parts_summary.title": "Overblik",
  "pay_in_parts_summary.total_amount": "Dit samlede kreditbeløb*",
  "pay_in_parts.cancel_button_label": "Annuller",
  "pay_in_parts.continue_button_label": "Næste",
  "pay_in_parts.interest_text_part_1": "Årlige omkostninger i procent (ÅOP) ved delbetaling med et repræsentativt eksempel fra 2024-01-02:",
  "pay_in_parts.interest_text_part_2": "Brugt kontokredit på <bold>15.000 kr.</bold> til 0% debitorrente med en tilbagebetalingsperiode på 24 mdr., med 24 rater på <bold>625 kr./md.</bold> giver en ÅOP på i alt 2,21% med Betalingsservice (<bold>15 kr./md.</bold>). Hvis du har valgt at få din regning med e-Boks eller post (<bold>49 kr./md.</bold>) vil ÅOP være 7,26%. Den samlede tilbagebetaling for kontokredit på <bold>15.000 kr.</bold> er derfor <bold>15.345 kr.</bold> hvis du har Betalingsservice og <bold>16.127 kr.</bold> hvis du får din regning med e-Boks eller post.",
  "pay_in_parts.interest_title": "Hvor meget koster delbetaling med Telia Finance?",
  "pay_in_parts.more_information": "Mere information om delbetaling",
  "pay_in_parts.more_information_details": " ",
  "pay_in_parts.more_information_details_in_store": " ",
  "pay_in_parts.more_information_in_store": "Mere information om delbetaling",
  "pay_in_parts.pay_button_label": "Godkend køb",
  "pay_in_parts.pay_strong_sign_button_label": "Næste",
  "pay_in_parts.terms_and_conditions_credit_check_info": "For at betale med delbetaling, fortsæt venligst til næste skridt af vores kreditvurdering.",
  "pay_in_parts.terms_and_conditions_credit_check_info_link_0": "https://files.teliafinance.com/sef-dk.pdf",
  "pay_in_parts.terms_and_conditions_secci": "Telia Finances <0>Generelle Vilkår</0> og <2>SECCI</2> (Standardiserede europæiske forbrugerkreditoplysninger)",
  "pay_in_parts.terms_and_conditions_secci_link_0": "https://www.teliafinance.com/globalassets/telia-finance/dk/documents/consumer/generelle-vilkar.pdf",
  "pay_in_parts.terms_and_conditions_secci_link_2": "https://www.teliafinance.com/globalassets/telia-finance/dk/documents/consumer/secci-dk.pdf",
  "pay_in_parts.terms_and_conditions_secci_text": "I næste trin accepterer du Telia Finances Kontokreditaftale, herunder <link0>Generelle vilkår</link0>, <link1>SEF - Standardiserede europæiske forbrugerkreditoplysninger</link1> og <link2>Fortrolighedspolitik</link2>.",
  "pay_in_parts.terms_and_conditions_secci_text_link_0": "https://files.teliafinance.com/generelle-vilkar-dk.pdf",
  "pay_in_parts.terms_and_conditions_secci_text_link_1": "https://files.teliafinance.com/sef-dk.pdf",
  "pay_in_parts.terms_and_conditions_secci_text_link_2": "https://www.teliafinance.com/dk/privat/om-os/fortrolighedspolitik",
  "pay_with_card.error_message_generic": "Hov! Det er sket en teknisk fejl.",
  "pay_with_card.error_message_label": "Fejl:",
  "pay_with_card.pay_button_label": "GODKEND KØB",
  "pay_with_card.powered_by": "Powered by Telia Danmark A/S",
  "pay_with_card.supported_cards": "Du kan bruge følgende kort: Visa og Mastercard.",
  "payment_method.pay_in_parts": "Delbetaling",
  "payment_method.pay_with_card": "Betal med kort\n",
  "payment_method.pay_with_card.not_supported_browser": "Browser ikke understøttet. Opdater til nyeste version eller forsøg i anden browser.",
  "powered_by": "Powered by",
  "processing_purchase.error_please_contact_staff": "Hov! Noget gik galt. \nVi var ikke i stand til at behandle din ordre på grund af tekniske årsager. Vi håber, du vil prøve igen senere. Du er velkommen til at kontakte salgsmedarbejderne.",
  "processing_purchase.error_please_contact_support": "Hov! Noget gik galt. Prøv igen eller kontakt kundeservice.",
  "processing_purchase.error_please_try_again": "Hov! Noget gik galt. Prøv igen lidt senere.",
  "processing_purchase.error_start_over": "Sessionen er udløbet og betalingen er derfor ikke gennemført. Prøv igen!",
  "processing_purchase.error_support_telephone_number": "8233 6666",
  "processing_purchase.error_try_another_payment_method": "Vælg en anden betalingsmetode",
  "processing_purchase.text": "Gennemfører køb",
  "processing_purchase.try_again_button_label": "Prøv igen",
  "signing.cancel": "Annuller",
  "signing.general_error": "Der opstod en fejl under signering. Prøv igen.",
  "signing.waiting_message": "Venter på underskrift",
  "thank_you_pay_in_parts.fine_print": "Girokort bliver sendt til din folkeregisteradresse.",
  "thank_you_pay_in_parts.number_of_months": "måned",
  "thank_you_pay_in_parts.number_of_months_plural": "{{count}} måneder",
  "thank_you_pay_in_parts.pay_in_parts": "Delbetaling",
  "thank_you_pay_in_parts.split_payment_part": "Delbetaling",
  "thank_you_pay_in_parts.summary_monthly_cost": "Månedligt beløb",
  "thank_you_pay_in_parts.summary_payment_method": "Betalingsmetode",
  "thank_you_pay_in_parts.summary_payment_term": "Betalingsperiode",
  "thank_you_pay_in_parts.summary_title": "Ordrebekræftelse",
  "thank_you_pay_in_parts.summary_total_amount": "Samlede kreditomkostninger ",
  "thank_you_pay_in_parts.title": "Tak!",
  "thank_you_pay_with_card.card": "Kort",
  "thank_you_pay_with_card.pay_in_parts_promo": "Vidste du at Telia Finance tilbyder delbetaling på mange af vores produkter? Delbetaling er med gratis oprettelse og har ingen renter eller gebyrer. Læs mere på teliafinance.com",
  "thank_you_pay_with_card.pay_in_parts_promo_fine_print": "*Oprettelse er altid gratis, og du betaler ingen renter og gebyrer. Betalingsservice koster 0 kr., og vælger du at få din reging med Girokort via e-Boks eller post, koster det 49 kr./md. Køber du fx for 10.000 kr., som du betaler af over 12 måneder, så betaler du 10.000 kr. og har altså en effektiv rente på 0%.",
  "thank_you_pay_with_card.summary_payment_method": "Betalingsmetode",
  "thank_you_pay_with_card.summary_title": "Ordrebekræftelse",
  "thank_you_pay_with_card.title": "Tak!",
  "thank_you_pay_with_card.total_amount": "Samlede kreditomkostninger ",
  "try_another_payment_method": "Ønsker du at gennemføre din ordre, kan du  betale det fulde beløb nu.\n",
  "your_details.cancel": "Fortryd",
  "your_details.continue": "Næste",
  "your_details.save": "Gem",
  "your_details.saving": "Gem",
  "your_details.ssn_helper_text": "Login med MitID, så vi kan bekræfte, at du allerede er kunde hos Telia Finance",
  "your_details.ssn_label": "CPR-nr",
  "your_details.ssn_placeholder": "DDMMYYXXXX",
  "your_details.title": "Dine oplysninger"
};