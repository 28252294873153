import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { typography, spacing } from '@teliads/components/foundations';
import PoweredByTeliaFinance from './PoweredByTeliaFinance';
import FLOWS from '../constants/flows';
import TeliaIconShopping from './graphics/TeliaIconShopping';
import { Title3 } from '../styled-components/styledcomponents';

const CancelView = () => {
  const { t } = useTranslation();
  const flow = useSelector((state) => state.app?.flow);
  return (
    <CancelViewWrapper data-cy="CancelView">
      <TeliaIconShoppingWrapper>
        <TeliaIconShopping />
      </TeliaIconShoppingWrapper>
      <CancelViewTitle>
        {flow === FLOWS.WEB
          ? t('cancel_view.guidance_header', {
              defaultValue: 'You have canceled your order'
            })
          : t('cancel_view.sdk_in_store_guidance_header', {
              defaultValue: 'You have canceled the purchase'
            })}
      </CancelViewTitle>
      <Text data-cy="CancelViewText">
        {flow === FLOWS.WEB
          ? t('cancel_view.guidance_text', {
              defaultValue:
                'Please contact customer service if you have any questions.'
            })
          : t('cancel_view.sdk_in_store_guidance_text', {
              defaultValue:
                'Contact the store representative if you have any questions.'
            })}
      </Text>
      <PoweredByTeliaFinance />
    </CancelViewWrapper>
  );
};

const CancelViewTitle = styled(Title3)`
  margin: ${spacing.spacing12} 0 ${spacing.spacing0} 0;
  line-height: 1.25;
`;

const TeliaIconShoppingWrapper = styled.div.attrs({
  className: 'TeliaIconShoppingWrapper'
})`
  margin: ${spacing.spacing32} 0 ${spacing.spacing0} 0;
`;

const CancelViewWrapper = styled.div.attrs({
  className: 'CancelViewWrapper'
})`
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  /*Center horizontaly ie */
  -ms-flex-pack: center;
  margin: 0 0 ${spacing.spacing16} 0;
`;

const Text = styled.p.attrs({ className: 'BoldText' })`
  margin: ${spacing.spacing4} 0 ${spacing.spacing64} 0;
  font-size: ${(p) => p.theme.fontSize.small};
  line-height: 1.5;
  font-weight: ${typography.weightNormal};
  display: block;
  text-align: center;
  white-space: pre-wrap;
  width: 100%;
`;

export default CancelView;
