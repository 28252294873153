import { string } from 'prop-types';
import styled from 'styled-components';
import { spacing, colors } from '@teliads/components/foundations';
import React from 'react';
import TeliaIconErrorFilled from './graphics/TeliaIconErrorFilled';

export const errorBoxTypes = { button: 'button', inputField: 'inputField' };

const AuthenticationErrorBox = ({ errorText, type }) => (
  <IconTextContainer data-cy="AuthenticationErrorBox">
    <SmallErrorText type={type}>
      <ErrorIcon>
        <TeliaIconErrorFilled />
      </ErrorIcon>
      {errorText}
    </SmallErrorText>
  </IconTextContainer>
);

AuthenticationErrorBox.propTypes = {
  errorText: string.isRequired,
  type: string.isRequired
};

export default AuthenticationErrorBox;

const IconTextContainer = styled.div`
  margin: ${spacing.spacing12} ${spacing.spacing0};
  color: ${({ theme }) => theme.color.destructiveDark};
`;

const ErrorIcon = styled.span`
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: ${spacing.spacing8};
`;

const SmallErrorText = styled.p`
  display: flex;
  border: ${(p) =>
    p.type === errorBoxTypes.button
      ? 'none'
      : p.theme.borderThickness.paymentMethod};
    solid ${colors.gray50};
  align-items: ${(p) => (p.type === errorBoxTypes.button ? 'center' : 'left')};
  justify-content: flex-start;
  margin: ${spacing.spacing0};
  font-size: ${(p) => p.theme.fontSize.xsmall};
  line-height: 1.5;
`;
