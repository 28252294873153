import paymentApiHelpers from './paymentApiHelpers';

const poll = async (
  purchaseRequestToken,
  correlationId,
  fetch = window.fetch
) => {
  try {
    const response = await fetch(`${paymentApiHelpers.getBaseUrl()}/poll`, {
      method: 'GET',
      headers: paymentApiHelpers.setHeaders(purchaseRequestToken, correlationId)
    });
    return { status: response.status, response };
  } catch (error) {
    return null;
  }
};

export default poll;
