export const CONFIG_COMPLETE = 'CONFIG_COMPLETE';
export const CONFIG_HIGH_LOAD = 'CONFIG_HIGH_LOAD';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const CONFIG_CIRCUIT_BREAKER = 'CONFIG_CIRCUIT_BREAKER';
export const AUTHORIZE = 'AUTHORIZE';
export const AUTHORIZE_STARTED = 'AUTHORIZE_STARTED';
export const AUTHORIZE_UNAUTHORIZED = 'AUTHORIZE_UNAUTHORIZED';
export const AUTHORIZE_FAILED = 'AUTHORIZE_FAILED';
export const AUTHORIZE_COMPLETE = 'AUTHORIZE_COMPLETE';
export const CARD_AUTHORIZE_AND_CAPTURE = 'CARD_AUTHORIZE_AND_CAPTURE';
export const MONTHIO_CREATE_DONE = 'MONTHIO_CREATE_DONE';
export const IS_MONTHIO_FLOW = 'IS_MONTHIO_FLOW';
export const MONTHIO_CREATE_ERROR = 'MONTHIO_CREATE_ERROR';
export const MONTHIO_POLL_ERROR = 'MONTHIO_POLL_ERROR';
export const MONTHIO_FLOW_COMPLETED_DONE = 'MONTHIO_FLOW_COMPLETED_DONE';
export const MONTHIO_FLOW_COMPLETED_ERROR = 'MONTHIO_FLOW_COMPLETED_ERROR';
export const MONTHIO_CANCEL = 'MONTHIO_CANCEL';
export const MONTHIO_POLL_ONCE = 'MONTHIO_POLL_ONCE';
export const MONTHIO_POLL_ONCE_DONE = 'MONTHIO_POLL_ONCE_DONE';
export const MONTHIO_OPEN_AGAIN = 'MONTHIO_OPEN_AGAIN';
export const PREPARE = 'PREPARE';
export const PREPARE_STARTED = 'PREPARE_STARTED';
export const PREPARE_COMPLETE = 'PREPARE_COMPLETE';
export const PREPARE_FAILED = 'PREPARE_FAILED';

export const ACCOUNT_LOOKUP_STARTED = 'ACCOUNT_LOOKUP_STARTED';
export const ACCOUNT_LOOKUP_COMPLETE = 'ACCOUNT_LOOKUP_COMPLETE';

export const RISK_ASSESSMENT_STARTED = 'RISK_ASSESSMENT_STARTED';
export const RISK_ASSESSMENT_COMPLETE = 'RISK_ASSESSMENT_COMPLETE';
export const RISK_ASSESSMENT_FAILED = 'RISK_ASSESSMENT_FAILED';

export const SET_PURCHASE_REQUEST_TOKEN = 'SET_PURCHASE_REQUEST_TOKEN';
export const SET_PAYMENT_METHODS = 'SET_PAYMENT_METHODS';
export const SET_SELECTED_PAYMENT_METHOD = 'SET_SELECTED_PAYMENT_METHOD';
export const SET_SELECTED_PAYMENT_METHOD_OPTION =
  'SET_SELECTED_PAYMENT_METHOD_OPTION';
export const SHOW_CANCEL_VIEW = 'SHOW_CANCEL_VIEW';
export const NEW_CREDIT_LIMIT = 'NEW_CREDIT_LIMIT';
export const NEW_CREDIT_LIMIT_CURRENCY = 'NEW_CREDIT_LIMIT_CURRENCY';
export const SET_SHOW_MORE_INFORMATION_PART_PAYMENT =
  'SET_SHOW_MORE_INFORMATION_PART_PAYMENT';
export const SET_PARENT_WINDOW_ORIGIN = 'SET_PARENT_WINDOW_ORIGIN';
export const START = 'START';
export const CONFIG_RETRY = 'CONFIG_RETRY';
export const WEBCHECKOUT_EVENT = 'WEBCHECKOUT_EVENT';
export const START_AUTH = 'START_AUTH';
export const START_SIGN = 'START_SIGN';
export const SET_AUTH_REQUIRED = 'SET_AUTH_REQUIRED';
export const AUTHENTICATION_STARTED = 'AUTHENTICATION_STARTED';
export const SBID_QR = 'SBID_QR';
export const SBID_AUTO_START_TOKEN = 'SBID_AUTO_START_TOKEN';
export const AUTHENTICATION_CANCELED = 'AUTHENTICATION_CANCELED';
export const AUTHENTICATION_ALREADY_IN_PROGRESS =
  'AUTHENTICATION_ALREADY_IN_PROGRESS';
export const AUTHENTICATION_FAILED = 'AUTHENTICATION_FAILED';
export const AUTHENTICATION_DONE = 'AUTHENTICATION_DONE';
export const CANCEL_AUTHENTICATION = 'CANCEL_AUTHENTICATION';

export const SIGNING_STARTED = 'SIGNING_STARTED';
export const SIGNING_CANCELED = 'SIGNING_CANCELED';
export const SIGNING_ALREADY_IN_PROGRESS = 'SIGNING_ALREADY_IN_PROGRESS';
export const SIGNING_FAILED = 'SIGNING_FAILED';
export const SIGNING_TECHNICAL_ERROR = 'SIGNING_TECHNICAL_ERROR';
export const SIGNING_DONE = 'SIGNING_DONE';
export const CANCEL_SIGNING = 'CANCEL_SIGNING';
export const CANCEL_SBID_AUTH_OR_SIGN = 'CANCEL_SBID_AUTH_OR_SIGN';

export const OPEN_WINDOW = 'OPEN_WINDOW';
export const CANCEL_PAYMENT = 'CANCEL_PAYMENT';
export const SET_REDIRECT_URL = 'SET_REDIRECT_URL';

export const USER_INFO_COLLECTION_STARTED = 'USER_INFO_COLLECTION_STARTED';
export const USER_INFO_COLLECTION_CONTINUE_CLICKED =
  'USER_INFO_COLLECTION_CONTINUE_CLICKED';
export const USER_INFO_COLLECTION_EMAIL = 'USER_INFO_COLLECTION_EMAIL';
export const USER_INFO_COLLECTION_PHONE = 'USER_INFO_COLLECTION_PHONE';
export const USER_INFO_COLLECTION_COMPLETED = 'USER_INFO_COLLECTION_COMPLETED';
export const USER_INFO_COLLECTION_CANCELLED_CLICKED =
  'USER_INFO_COLLECTION_CANCELLED_CLICKED';
export const USER_INFO_COLLECTION_API_ERROR = 'USER_INFO_COLLECTION_API_ERROR';

export const KALP_QUESTIONS_CHECK = 'KALP_QUESTIONS_CHECK';
export const KALP_QUESTIONS_TOGGLE = 'KALP_QUESTIONS_TOGGLE';
export const KALP_QUESTIONS_POST = 'KALP_QUESTIONS_POST';
export const KALP_FLOW_DONE = 'KALP_FLOW_DONE';
export const SET_KALP_VERSION = 'SET_KALP_VERSION';
export const SET_KALP_QUESTIONS = 'SET_KALP_QUESTIONS';

export const QUESTIONS_CHECK = 'QUESTIONS_CHECK';
export const QUESTIONS_VIEW_TOGGLE = 'USER_INFORMATION_VIEW_TOGGLE';
export const QUESTIONS_POST = 'QUESTIONS_POST';
export const IS_LOADING = 'IS_LOADING';
export const KYC_FLOW_DONE = 'KYC_FLOW_DONE';
export const SET_KYC_QUESTIONS = 'SET_KYC_QUESTIONS';
export const SET_KYC_VERSION = 'SET_KYC_VERSION';
export const SET_QUESTIONS_TO_DISPLAY = 'SET_QUESTIONS_TO_DISPLAY';
export const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN';
export const TECHNICAL_ERROR = 'TECHNICAL_ERROR';
